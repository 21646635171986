import ProdutoModel from 'models/Produto';
import ProdutoCategoria from 'models/ProdutoCategoria';
import { useEffect, useState } from 'react';
import { FaBuilding, FaEdit, FaTimesCircle, FaCamera } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Row,
  Modal,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardHeader,
  Table,
} from 'reactstrap';
import api from 'services/api';
import ModalUploadImagem from 'components/ModalUploadImagem';
import Compress from 'compress.js';
import { Tooltip } from '@material-ui/core';

interface Props {
  produtos: ProdutoModel[];
  produtoCategorias: ProdutoCategoria[];
  carregaProdutos: () => void;
}

export default function ListaProdutos({
  produtos,
  produtoCategorias,
  carregaProdutos,
}: Props): JSX.Element {
  const [notificationModalState, setNotificationModalState] = useState(false);
  const [formModalState, setFormModalState] = useState(false);
  const [partesProduto, setPartesProduto] = useState([] as ProdutoModel[]);
  const [produtoEditing, setProdutoEditing] = useState({} as ProdutoModel);
  const [produtoSelecionado, setProdutoSelecionado] = useState<ProdutoModel>();
  const [exibirModalImagem, setExibirModalImagem] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    carregaProdutos();
  }, []);

  async function handleDelete(produtoId: number) {
    async function checkExistePartesProduto(): Promise<boolean> {
      if (!produtoId) {
        return false;
      }
      const response = await api.get(`/produto/${produtoId}/partes`);
      const existePartes = !!response.data.length;
      if (existePartes) {
        setNotificationModalState(true);
      }
      setPartesProduto(response.data);
      return existePartes;
    }

    const existePartes = await checkExistePartesProduto();

    if (!existePartes) {
      await api.delete(`/produto/${produtoId}`);
      carregaProdutos();
    }
  }

  async function comprimeImagem(imagem: string | null): Promise<string> {
    if (!imagem) {
      return '';
    }
    const compress = new Compress();
    const file = Compress.convertBase64ToFile(imagem?.split('base64,')[1]);
    const fileCompressed = await compress.compress([file], {
      quality: 0.7,
      maxWidth: 600,
      maxHeight: 640,
      resize: true,
    });
    return fileCompressed[0].data;
  }

  function handleOpenModalEdicaoProduto(produto: ProdutoModel) {
    setProdutoEditing(produto);
    setFormModalState(!formModalState);
  }

  async function handleUpdateProduto() {
    const {
      nome,
      codigoEmpresa,
      produtoCategoriaId,
      nomeProposta,
      bloquearProdutosDiferentes,
    } = produtoEditing;
    if (!nome || !codigoEmpresa || !produtoCategoriaId) return;
    await api.put(`/produto/${produtoEditing.id}`, {
      nome,
      codigoEmpresa,
      produtoCategoriaId,
      nomeProposta,
      bloquearProdutosDiferentes,
    });
    setFormModalState(false);
    setProdutoEditing({} as ProdutoModel);
    carregaProdutos();
  }

  function InputSelectProdutoCategoria(): JSX.Element {
    return (
      <Input
        placeholder="Categoria"
        type="select"
        value={produtoEditing.produtoCategoriaId || ''}
        onChange={text =>
          setProdutoEditing({
            ...produtoEditing,
            produtoCategoriaId: Number(text.target.value),
          })
        }
      >
        <option value="">Categoria...</option>
        {produtoCategorias.map(produtoCategoria => (
          <option value={produtoCategoria.id}>
            {produtoCategoria.descricao}
          </option>
        ))}
      </Input>
    );
  }
  useEffect(() => {
    if (produtoSelecionado) {
      setExibirModalImagem(true);
    }
  }, [produtoSelecionado]);

  function closeModalImagem() {
    setExibirModalImagem(!exibirModalImagem);
    setProdutoSelecionado(undefined);
  }

  async function salvarImagem(imagem: string | null) {
    // if (!produtoSelecionado?.id) return;
    try {
      setLoading(true);
      const imagemComprimida = await comprimeImagem(imagem);
      await api.put(`/produto/imagem`, {
        id: produtoSelecionado?.id,
        imagem: imagemComprimida || null,
        nome: produtoSelecionado?.nome,
      });
    } finally {
      setLoading(false);
      closeModalImagem();
    }
    carregaProdutos();
  }

  return (
    <Row>
      <div className="col">
        {/* Modal alerta */}
        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={notificationModalState}
          toggle={() => setNotificationModalState(!notificationModalState)}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              {' '}
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setNotificationModalState(!notificationModalState)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">
                Não é possível remover o produto!
              </h4>
              <p>
                Esse produto possui {partesProduto.length} parte(s)
                cadastrada(s). Remova-a(s) para prosseguir com a exclusão.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={() => setNotificationModalState(!notificationModalState)}
            >
              Fechar
            </Button>
          </div>
        </Modal>
        <ModalUploadImagem
          isOpen={exibirModalImagem}
          toggle={closeModalImagem}
          onSave={salvarImagem}
          imagem={produtoEditing?.imagem || ''}
          descricao={produtoSelecionado?.nome || ''}
        />

        {/* Modal edição */}
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={formModalState}
          toggle={() => setFormModalState(!formModalState)}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Produto {produtoEditing.nome}</small>
                </div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-tag" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Nome do produto"
                        value={produtoEditing.nome}
                        type="text"
                        onChange={text =>
                          setProdutoEditing({
                            ...produtoEditing,
                            nome: text.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-tag" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Tooltip title="Descrição do produto na proposta">
                        <Input
                          placeholder="Descrição do produto na proposta"
                          value={
                            produtoEditing.nomeProposta ?? produtoEditing.nome
                          }
                          type="text"
                          onChange={text =>
                            setProdutoEditing({
                              ...produtoEditing,
                              nomeProposta: text.target.value,
                            })
                          }
                        />
                      </Tooltip>
                    </InputGroup>
                  </FormGroup>

                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaBuilding />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      placeholder="Código da empresa"
                      min="0"
                      value={produtoEditing.codigoEmpresa ?? ''}
                      type="number"
                      onChange={text =>
                        setProdutoEditing({
                          ...produtoEditing,
                          codigoEmpresa: String(text.target.value),
                        })
                      }
                    />
                  </InputGroup>

                  <InputGroupAddon className="mt-3" addonType="append">
                    <InputSelectProdutoCategoria />
                  </InputGroupAddon>

                  <FormGroup className="mt-3">
                    <InputGroup className="input-group-alternative justify-content-center  pb-2 pt-2 pl-2 d-flex align-items-center">
                      <Tooltip title="Bloqueia o uso de produtos diferentes">
                        <strong className="h5 mb-0 mr-2">
                          Bloqueia produtos diferentes
                        </strong>
                      </Tooltip>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          onChange={() =>
                            setProdutoEditing({
                              ...produtoEditing,
                              bloquearProdutosDiferentes:
                                !produtoEditing.bloquearProdutosDiferentes,
                            })
                          }
                          checked={produtoEditing.bloquearProdutosDiferentes}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          placeholder="teste"
                        />
                      </label>
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-4"
                      disabled={
                        !produtoEditing.nome ||
                        !produtoEditing.codigoEmpresa ||
                        !produtoEditing.produtoCategoriaId
                      }
                      color="primary"
                      type="button"
                      onClick={handleUpdateProduto}
                    >
                      Salvar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Lista de produtos</h3>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <tbody>
              {produtos.map(produto => (
                <tr key={produto.id}>
                  <td className="col">
                    <strong>{produto.nome}</strong>
                  </td>
                  <td>
                    <strong className="text-muted">
                      {produto?.ProdutoCategoria?.descricao}
                    </strong>
                  </td>
                  <td className="col">
                    <strong>
                      {produto.codigoEmpresa
                        ? `Empresa ${produto.codigoEmpresa}`
                        : ''}
                    </strong>
                  </td>
                  <td>
                    <Link
                      style={{ marginRight: '0.8rem' }}
                      to={{
                        pathname: `/admin/variaveis-regras`,
                        state: { produto },
                      }}
                    >
                      <Button size="sm" color="info">
                        Variáveis
                      </Button>
                    </Link>
                  </td>
                  <td>
                    <Link
                      style={{ marginRight: '0.8rem' }}
                      to={{
                        pathname: `/admin/grupo-regras`,
                        state: { produto },
                      }}
                    >
                      <Button size="sm" color="default">
                        Regras
                      </Button>
                    </Link>
                  </td>
                  <td style={{ margin: 'none' }}>
                    <Link
                      to={{
                        pathname: `/admin/produto-partes`,
                        state: { produto },
                      }}
                    >
                      <Button
                        size="sm"
                        className="btn-icon"
                        color="primary"
                        type="button"
                      >
                        <span>Partes</span>
                      </Button>
                    </Link>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn-icon"
                      color="warning"
                      type="button"
                      onClick={() => {
                        handleOpenModalEdicaoProduto(produto);
                      }}
                    >
                      <FaEdit />
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn-icon"
                      color="default"
                      type="button"
                      onClick={() => {
                        setExibirModalImagem(true);
                        setProdutoSelecionado(produto);
                        setProdutoEditing({
                          ...produtoEditing,
                          imagem: produto?.imagem,
                        });
                      }}
                    >
                      <FaCamera />
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn-icon"
                      color="danger"
                      type="button"
                      onClick={() => {
                        handleDelete(produto.id);
                      }}
                    >
                      <FaTimesCircle />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </div>
    </Row>
  );
}

import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Modal,
  Row,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import api from 'services/api';
import { FaInfoCircle, FaUndo } from 'react-icons/fa';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

export default function PropostaManutencao(): JSX.Element {
  const [proposta, setProposta] = useState<any>({});
  const [propostaValidaManutencao, setPropostaValidaManutencao] =
    useState<boolean>(false);
  const [propostaManutencao, setPropostaManutencao] = useState<string>('');
  const [propostaVersaoManutencao, setPropostaVersaoManutencao] =
    useState<string>('');
  const [formModalState, setFormModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const STATUS_ID_APROVADO = 3;

  useEffect(() => {
    if (!propostaManutencao || !propostaVersaoManutencao) {
      setPropostaValidaManutencao(false);
      setProposta({});
      setFormModalState(!formModalState);
    }
  }, []);

  async function validarPropostaVersao() {
    const response = await api.get(
      `/proposta/${propostaManutencao}/${propostaVersaoManutencao}`,
    );

    return response.data;
  }

  async function handleValidarProposta() {
    setIsLoading(true);
    try {
      if (propostaVersaoManutencao && propostaManutencao) {
        const registro = await validarPropostaVersao();

        // valida status
        const statusId = registro.status.id;

        if (statusId !== STATUS_ID_APROVADO) {
          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            text: `O status atual dessa proposta [${registro.status.descricao}] não permite modificação nessa tela.`,
          });
          return;
        }

        setProposta(registro);
        setPropostaValidaManutencao(true);
        setFormModalState(false);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function openModalProposta(clearAll: boolean) {
    if (clearAll) {
      setPropostaManutencao('');
      setPropostaVersaoManutencao('');
      setProposta({});
    }
    setFormModalState(!formModalState);
  }

  return (
    <>
      <UserSmallHeader />
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader>
              <h2 className="text-center">Proposta para Manutenção</h2>
              <small>Informe a proposta e versão para manutenção.</small>
              <br />
              <small>
                OBS: Apenas propostas <u>APROVADAS</u> poderão ser modificadas
              </small>
            </CardHeader>
            <CardBody>
              {propostaValidaManutencao && (
                <Alert color="info">
                  <FaInfoCircle /> Alterações realizadas serão perdidas se
                  clicar em <strong>Iniciar Edição</strong>
                </Alert>
              )}
              <Row>
                <Col sm="6">
                  <Input
                    placeholder="Proposta"
                    type="text"
                    value={propostaManutencao}
                    onChange={text => setPropostaManutencao(text.target.value)}
                    className="form-control-sm"
                  />
                </Col>
                <Col sm="6">
                  <Input
                    placeholder="Versão da Proposta"
                    type="text"
                    value={propostaVersaoManutencao}
                    onChange={text =>
                      setPropostaVersaoManutencao(text.target.value)
                    }
                    className="form-control-sm"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="default"
                      type="button"
                      disabled={isLoading}
                      onClick={() => {
                        setFormModalState(!formModalState);
                      }}
                    >
                      Fechar
                    </Button>

                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      disabled={isLoading}
                      onClick={handleValidarProposta}
                    >
                      Iniciar Edição
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Modal>

      <Card>
        <CardHeader>
          <Row>
            <Col sm="6">
              <h5 className="h3 mb-0">Propostas - Manutenção</h5>
              <small>Alteração de informações da proposta aprovada</small>
            </Col>
            <Col sm="6">
              <div className="float-right">
                <Button
                  className="my-4"
                  color="primary"
                  size="sm"
                  type="button"
                  title="Trocar Proposta"
                  onClick={() => openModalProposta(false)}
                >
                  <FaUndo /> Trocar Proposta
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="p-0 pr-1 pl-1" style={{ background: '#ebf3fa' }}>
          {!propostaValidaManutencao && (
            <>
              <div className="ml-2">
                Para abrir a modal e informar o registro que deseja dar
                manutenção{' '}
                <Button
                  className="my-4"
                  color="primary"
                  size="sm"
                  type="button"
                  onClick={() => openModalProposta(true)}
                >
                  Clique aqui
                </Button>
              </div>
            </>
          )}
          {propostaValidaManutencao && (
            <>
              <CardHeader className="mt-2">
                <Row>
                  <Col md="4" sm="4" xs="12" lg="4">
                    <h4 className="mb-0">Proposta {proposta.id}</h4>{' '}
                    <small className="mb-0">
                      Versão {proposta.versao} | Configurações{' '}
                      {proposta.PropostaConfiguracao?.map(
                        (config: any) => config.orcamentoId,
                      ).join(', ')}
                    </small>
                  </Col>
                  <Col md="3" sm="3" xs="12" lg="4">
                    <small className="mb-0">
                      <strong>Gnf: </strong>
                      {proposta.proposta?.codigoOportunidadeDomsge ?? ''}
                      <br />
                      <span hidden={!proposta.proposta?.pessoaGnf}>
                        {`${proposta.proposta?.pessoaGnf?.codigoDomsge} - ${proposta.proposta?.pessoaGnf?.razaoSocial}`}
                      </span>
                      <br />
                      <small
                        hidden={
                          (proposta.proposta?.pessoaGnf?.id ?? 0) ===
                          (proposta.proposta?.pessoa?.id ?? 0)
                        }
                      >
                        {`${proposta.proposta?.pessoa?.codigoDomsge} - ${proposta.proposta?.pessoa?.razaoSocial}`}
                      </small>
                    </small>
                  </Col>
                  <Col md="3" sm="3" xs="12" lg="2">
                    <div className="d-flex mt-2">
                      {proposta.status?.descricao ?? ''}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="mt-2">
                <Row>wad</Row>

                <ul>
                  <li>
                    Modificar dados de cliente do CRM (forçar busca de dados
                    atualizados)
                  </li>
                  <li>Dados de Obra</li>
                  <li>
                    ajuste das parcelas
                    <ul>
                      <li>Percentuais</li>
                      <li>Eventos</li>
                      <li>Datas</li>
                    </ul>
                  </li>
                  <li>Meio de pagamento</li>
                  <li>Número do pivô</li>
                  <li>Variáveis comerciais</li>
                  <li>
                    Campo de desconto é bloqueado após sua aprovação, prever
                    liberação do mesmo para certos perfis de usuário.
                  </li>
                  <li>
                    Permitir deixar proposta sem data dos eventos de pagamento
                  </li>
                  <li>Gravar Logs</li>
                </ul>
              </CardBody>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}

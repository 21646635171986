import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { createContext, useEffect, useState } from 'react';
import { FaArrowCircleRight, FaInfo } from 'react-icons/fa';
import Swal from 'sweetalert2';
import FichaTecnicaNecessidadeReprocessamento from 'models/FichaTecnicaNecessidadeReprocessamento';
import triggerValidaRegras from 'services/triggerValidaRegras';
import Orcamento from 'models/Orcamento';
import { DesnivelTubos } from 'models/DesnivelTubos';
import OrcamentoInfoBalsa from 'models/OrcamentoInfoBalsa';
import toast from 'react-hot-toast';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import api from '../../../../../services/api';
import FichaTecnica from '../../../../../models/FichaTecnica';
import { FichaTecnicaBombeamento } from '../../../../../models/FichaTecnicaBombeamento';
import { FichaTecnicaTubulacao } from '../../../../../models/FichaTecnicaTubulacao';
import Parametros from '../../../../../models/Parametros';
import Adutora from './components/Adutora';
import Bombeamento from './components/Bombeamento';
import Succao from './components/Succao';
import Eletrica from './components/Eletrica';
import Elevacao from './components/Elevacao';
import Tubulacao from './components/Tubulacao';
import EletricaBalsa from './components/EletricaBalsa';

interface iDadosElevacao {
  quantidadeVentosa: number;
  curvasToSave: string;
  totalCurvasEncontradas: number;
}

interface AdutoraBombeamentoNewContext {
  orcamentoId: number;
  fichaTecnicaId: number;
  sessaoId: string;
  necessidadeReprocessamento?: FichaTecnicaNecessidadeReprocessamento;
  setNecessidadeReprocessamento: (
    value: FichaTecnicaNecessidadeReprocessamento,
  ) => void;
  dadosElevacao?: iDadosElevacao;
  setDadosElevacao: (a: iDadosElevacao) => void;
  orcamentoInfoBalsa?: OrcamentoInfoBalsa;
  atualizarFuncoes: (a: string[], b: boolean) => void;
  fichaTecnicaAdutora: any;
  fichaTecnicaBombeamento: FichaTecnicaBombeamento[];
  respostaFuncaoEntrada: any;
  acessoProgramas: iValidarAcessoPrograma[];
  fichaTecnica: FichaTecnica;
  parametros?: Parametros;
  fichaTecnicaTubulacao?: FichaTecnicaTubulacao;
  atualizarBombeamento: boolean;
  atualizarBombeamentoBalsa: boolean;
  reloadData: boolean;
}

export const AdutoraBombeamentoNewContext = createContext(
  {} as AdutoraBombeamentoNewContext,
);

interface Props {
  onSave?: any;
  acessoLimitado: boolean | false;
  triggerStart: boolean | false;
  sessaoId?: string | null;
  fichaTecnicaId?: number | null;
  configuracaoId: number;
  orcamentoParteId: number;
  setSessaoId?: any;
  setFichaTecnicaId?: any;
  handleBloquearAbaResultado: () => void;
}

interface PerfilAdutora {
  sessao: string;
  cotaInicial: number;
  cotaFinal: number;
  pressaoInicio: number;
  pressaoFinal: number;
  classe: string;
  ordem: number;
  diametroNominal: number;
  tubulacaoSuccao: boolean | false;
}

interface iValidarAcessoPrograma extends ValidarAcessoPrograma {
  programa: number;
}

export default function AdutoraBombeamento(props: Props): JSX.Element {
  const {
    onSave,
    acessoLimitado,
    triggerStart,
    orcamentoParteId,
    configuracaoId,
    sessaoId,
    fichaTecnicaId,
    setFichaTecnicaId,
    setSessaoId,
    handleBloquearAbaResultado,
  } = props;

  const orcamentoId = configuracaoId;

  const [dadosElevacao, setDadosElevacao] = useState<iDadosElevacao>();

  // ovidio
  const [vazaoPrevista, setVazaoPrevista] = useState(0);
  const [pressaoPrevista, setPressaoPrevista] = useState(0);
  const [pressaoPrevistaBalsa, setPressaoPrevistaBalsa] = useState(0);

  const [bomba, setBomba] = useState('');
  const [quantidadeBomba, setQuantidadeBomba] = useState(0);
  const [quantidadeVentosa, setQuantidadeVentosa] = useState(0);
  const [fichaTecnicaBombeamento, setFichaTecnicaBombeamento] = useState<
    FichaTecnicaBombeamento[]
  >([]);

  const [acessoProgramas, setAcessoProgramas] = useState<
    iValidarAcessoPrograma[]
  >([]);

  const [curvasToSave, setCurvasToSave] = useState('');
  const [totalCurvasEncontradas, setTotalCurvasEncontradas] = useState(0);
  const [quantidadeModulos, setQuantidadeModulos] = useState(1);
  const [criarPrimeiroTrecho, setCriarPrimeiroTrecho] = useState(false);
  const [podeCalcularAdutora, setPodeCalcularAdutora] = useState(false);
  const [atualizarDadosAdutora, setAtualizarDadosAdutora] = useState(false);
  const [habilitarBlocoTrechos, setHabilitarBlocoTrechos] = useState(false);
  const [loadingInterface, setLoadingInterface] = useState(false);
  const [updatingInterface, setUpdatingInterface] = useState(false);
  const [parametros, setParametros] = useState<Parametros>();
  const [orcamentoInfoBalsa, setOrcamentoInfoBalsa] =
    useState<OrcamentoInfoBalsa>();
  const [fichaTecnicaTubulacao, setFichaTecnicaTubulacao] =
    useState<FichaTecnicaTubulacao>();
  const [fichaTecnica, setFichaTecnica] = useState<FichaTecnica>(
    {} as FichaTecnica,
  );
  const [necessidadeReprocessamento, setNecessidadeReprocessamento] =
    useState<FichaTecnicaNecessidadeReprocessamento>();

  const [pageLoaded, setPageLoaded] = useState(true);
  const [carregarAdutora, setCarregarAdutora] = useState(false);
  const [carregarTrechos, setCarregarTrechos] = useState(false);
  const [carregarBombeamento, setCarregarBombeamento] = useState(false);
  const [carregarEletrica, setCarregarEletrica] = useState(false);
  const [carregarSuccao, setCarregarSuccao] = useState(false);
  const [atualizarBombeamento, setAtualizarBombeamento] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [atualizarBombeamentoBalsa, setAtualizarBombeamentoBalsa] =
    useState(false);

  const [fichaTecnicaAdutora, setFichaTecnicaAdutora] = useState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);

  const [
    bloquearConclusaoPorReprocessamento,
    setBloquearConclusaoPorReprocessamento,
  ] = useState(false);

  const [desnivelTubos, setDesnivelTubos] = useState<DesnivelTubos[]>([]);

  const [respostaFuncaoEntrada, setRespostaFuncaoEntrada] = useState<any>(
    {} as any,
  );
  const [variacaoNivelAgua, setVariacaoNivelAgua] = useState(0);

  const [
    bloquearConclusaoPorReprocessamentoCfg,
    setBloquearConclusaoPorReprocessamentoCfg,
  ] = useState(false);
  const [orcamentos, setOrcamentos] = useState({} as Orcamento);

  async function carregarParametros() {
    const response = await api.get('/parametros');
    if (response.data) setParametros(response.data as Parametros);
  }

  async function carregaOrcamento() {
    const response = await api.get(`/orcamento/${orcamentoId}`);
    if (response.data) setOrcamentos(response.data as Orcamento);
  }

  async function carregarInformacaoBalsa() {
    const response = await api.get(
      `/orcamento/versao/${orcamentoId}/informacao-succao`,
    );
    if (response.data)
      setOrcamentoInfoBalsa(response.data as OrcamentoInfoBalsa);
  }

  async function carregaDesniveisTubos() {
    const response = await api.get('/integracao/adutora/tubos/desnivel');

    setDesnivelTubos(response.data);
  }

  async function getAcessos() {
    const response = await api.get('/acesso-programa/acesso-programa-lote', {
      params: {
        programas: [77, 67, 78],
      },
    });
    setAcessoProgramas(response.data);
  }

  useEffect(() => {
    // Comando para fazer um scroll to top ao acessar a aba de Adutora.
    window.scrollTo(0, 0);
    // document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (updatingInterface) {
      toast.loading('Aguarde. Estamos atualizando os dados da tela...', {
        position: 'top-right',
      });
      return;
    }

    toast.dismiss();
  }, [updatingInterface]);

  async function carregarRespostas() {
    const response = await api.get(
      `/orcamento/resposta-funcao/?orcamentoVersaoId=${orcamentoId}`,
    );

    if (response.data) {
      setRespostaFuncaoEntrada(response.data);

      const { variacaoNivelAgua: vNivelAgua } = response.data;

      setVariacaoNivelAgua(vNivelAgua ?? 0);
    }
  }

  async function atualizarNecessidadeReprocessamento(
    item: FichaTecnicaNecessidadeReprocessamento,
  ) {
    if (fichaTecnicaId) {
      const retorno = await api.put(
        `/ficha-tecnica/${fichaTecnicaId}/necessidade-reprocessamento/dinamicaTela`,
        {
          reprocessarAdutora: item.reprocessarAdutora,
          reprocessarBombeamento: item.reprocessarBombeamento,
          reprocessarTrechos: item.reprocessarTrechos,
          reprocessarSuccao: item.reprocessarSuccao,
          reprocessarSuccaoBalsa: item.reprocessarSuccaoBalsa,
          reprocessarTrechosBalsa: item.reprocessarTrechosBalsa,
          reprocessarBombeamentoBalsa: item.reprocessarBombeamentoBalsa,
        },
      );
      setNecessidadeReprocessamento(
        retorno.data as FichaTecnicaNecessidadeReprocessamento,
      );
    }
  }

  async function necessidadeReprocessamentoData(
    item: FichaTecnicaNecessidadeReprocessamento,
  ) {
    await atualizarNecessidadeReprocessamento(item);
  }

  async function atualizarMateriais() {
    const isOk = await triggerValidaRegras({
      fichaTecnicaId: Number(fichaTecnicaId ?? 0),
      atualizarMaterial: true,
      validarAgrupador: true,
      orcamentoVersaoId: orcamentoId,
      origem: `Configuração: ${orcamentoId}`,
      orcamento: orcamentos,
      origemAtualizacao: 'AdutoraBombeamento',
    });
    return isOk;
  }

  function handleSalvarRespostas() {
    Swal.fire({
      title: `Deseja marcar a etapa de Adutora e Bombeamento como concluída?`,
      text: `Será executado o processo para atualizar a lista de materiais. As alterações não salvas serão perdidas.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        const isOk = await atualizarMateriais();
        if (isOk) if (onSave) props.onSave();
      }
    });
  }

  async function carregarFichaTecnica() {
    const response = await api.get(`/ficha-tecnica/${fichaTecnicaId}`);
    const ftTubulacao = response.data?.FichaTecnicaTubulacao ?? {};
    setFichaTecnicaTubulacao(ftTubulacao as FichaTecnicaTubulacao);
    setFichaTecnica(response.data);
  }

  async function listaNecessidadeReprocessamento() {
    const response = await api.get(
      `/ficha-tecnica/${fichaTecnicaId}/necessidade-reprocessamento`,
    );

    const data = response.data as FichaTecnicaNecessidadeReprocessamento;
    setNecessidadeReprocessamento(data);
  }

  async function carregaFichaTecnicaAdutora() {
    if (fichaTecnicaId) {
      const response = await api.get(
        `/ficha-tecnica-adutora/ficha-tecnica/${fichaTecnicaId}`,
      );

      setFichaTecnicaAdutora(response.data);
    }
  }

  async function carregaFichaTecnicaBombeamento() {
    const response = await api.get(
      `/integracao/bombeamento/calculo/${fichaTecnicaId}`,
    );

    setFichaTecnicaBombeamento(response.data);
  }

  async function atualizarFuncoes(atualizar: string[], rel = false) {
    setUpdatingInterface(true);
    setReloadData(false);

    const utilizaBalsa =
      (orcamentoInfoBalsa?.isBalsa ?? false) &&
      (orcamentoInfoBalsa?.isBalsaAuxiliar ?? false);

    let reprocessarAdutora =
      necessidadeReprocessamento?.reprocessarAdutora ?? false;
    let reprocessarTrechos =
      necessidadeReprocessamento?.reprocessarTrechos ?? false;
    let reprocessarBombeamento =
      necessidadeReprocessamento?.reprocessarBombeamento ?? false;
    let reprocessarSuccao =
      necessidadeReprocessamento?.reprocessarSuccao ?? false;
    let reprocessarTrechosBalsa = utilizaBalsa
      ? necessidadeReprocessamento?.reprocessarTrechosBalsa ?? false
      : false;
    let reprocessarBombeamentoBalsa = utilizaBalsa
      ? necessidadeReprocessamento?.reprocessarBombeamentoBalsa ?? false
      : false;
    const reprocessarSuccaoBalsa = false; // Não usado

    // Todos atualizam a Ficha Tecnica
    await carregarFichaTecnica();

    if (atualizar.includes('ADUTORA')) {
      await carregaFichaTecnicaBombeamento();
      await carregaFichaTecnicaAdutora();
      reprocessarAdutora = false;
      reprocessarTrechos = true;

      if (utilizaBalsa) {
        reprocessarTrechosBalsa = true;
        reprocessarBombeamentoBalsa = true;
      }
      reprocessarBombeamento = true;
      reprocessarSuccao = true;
    }

    if (atualizar.includes('BOMBEAMENTO')) {
      await carregaFichaTecnicaBombeamento();
      reprocessarBombeamento = false;
      reprocessarSuccao = true;
    }

    if (atualizar.includes('ELETRICA')) {
      await carregaFichaTecnicaAdutora();
      await carregaFichaTecnicaBombeamento();
    }

    if (atualizar.includes('TRECHOS')) {
      await carregaFichaTecnicaAdutora();
      await carregaFichaTecnicaBombeamento();
      if (!atualizar.includes('DEL')) {
        reprocessarTrechos = false;
        reprocessarBombeamento = true;
        reprocessarSuccao = true;
      }
    }

    if (atualizar.includes('TRECHOS_BALSA')) {
      await carregaFichaTecnicaAdutora();
      await carregaFichaTecnicaBombeamento();
      reprocessarTrechosBalsa = false;
      reprocessarBombeamentoBalsa = true;
    }

    if (atualizar.includes('BOMBEAMENTO_BALSA')) {
      await carregaFichaTecnicaBombeamento();
      await carregaFichaTecnicaAdutora();
      reprocessarBombeamentoBalsa = false;
    }

    if (atualizar.includes('SUCCAO')) {
      if (atualizar.includes('ATUALIZAR')) {
        await carregaFichaTecnicaAdutora();
        await carregaFichaTecnicaBombeamento();

        // Caso tenha retornado para atualizar, então precisa reprocessar tudo.
        reprocessarAdutora = true;
        reprocessarTrechos = true;
        reprocessarBombeamento = true;
        reprocessarSuccao = true;
      } else {
        reprocessarSuccao = false;
      }
    }

    if (rel) {
      setReloadData(true);
    }
    setAtualizarBombeamento(reprocessarBombeamento);
    setAtualizarBombeamentoBalsa(reprocessarBombeamentoBalsa);

    await necessidadeReprocessamentoData({
      reprocessarAdutora,
      reprocessarTrechos,
      reprocessarBombeamento,
      reprocessarSuccao,
      reprocessarTrechosBalsa,
      reprocessarBombeamentoBalsa,
      reprocessarSuccaoBalsa,
    });

    handleBloquearAbaResultado();
    setUpdatingInterface(false);
  }

  async function carregarDados() {
    setLoadingInterface(true);
    setLoadingPage(true);

    await getAcessos();

    // Geral
    await listaNecessidadeReprocessamento();
    await carregarRespostas();
    await carregarParametros();

    // Adutora
    await carregaFichaTecnicaAdutora();

    // Bombeamento
    await carregaFichaTecnicaBombeamento();

    // Succao

    // Trechos

    await carregarFichaTecnica();
    await carregaOrcamento();
    await carregarInformacaoBalsa();
    await carregaDesniveisTubos();

    setLoadingInterface(false);
    setLoadingPage(false);
  }

  useEffect(() => {
    if (loadingInterface) {
      Swal.fire({
        icon: 'info',
        text: 'Buscando informações da Adutora e Bombeamento...',
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    } else {
      Swal.close();
    }
  }, [loadingInterface]);

  useEffect(() => {
    if (configuracaoId && fichaTecnicaId) {
      carregarDados();
    }
  }, [configuracaoId, fichaTecnicaId]);

  useEffect(() => {
    if (necessidadeReprocessamento) {
      let bloquear = false;

      if (
        necessidadeReprocessamento.reprocessarAdutora === true ||
        necessidadeReprocessamento.reprocessarBombeamento === true ||
        necessidadeReprocessamento.reprocessarSuccao === true ||
        necessidadeReprocessamento.reprocessarTrechos === true ||
        necessidadeReprocessamento.reprocessarSuccaoBalsa === true ||
        necessidadeReprocessamento.reprocessarTrechosBalsa === true ||
        necessidadeReprocessamento.reprocessarBombeamentoBalsa === true
      )
        bloquear = true;

      setBloquearConclusaoPorReprocessamento(
        bloquear || bloquearConclusaoPorReprocessamentoCfg,
      );
    }
  }, [necessidadeReprocessamento, bloquearConclusaoPorReprocessamentoCfg]);

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col sm="12">
          <AdutoraBombeamentoNewContext.Provider
            value={{
              fichaTecnicaId: Number(fichaTecnicaId ?? 0),
              orcamentoId,
              necessidadeReprocessamento,
              setNecessidadeReprocessamento:
                atualizarNecessidadeReprocessamento,
              dadosElevacao,
              setDadosElevacao,
              orcamentoInfoBalsa,
              atualizarFuncoes,
              fichaTecnicaAdutora,
              fichaTecnicaBombeamento,
              respostaFuncaoEntrada,
              acessoProgramas,
              fichaTecnica,
              sessaoId: String(sessaoId),
              parametros,
              fichaTecnicaTubulacao,
              atualizarBombeamento,
              atualizarBombeamentoBalsa,
              reloadData,
            }}
          >
            {!loadingPage && (
              <Card className="shadow mt-5">
                <CardBody className="p-1">
                  <Elevacao />
                </CardBody>
              </Card>
            )}

            {!loadingPage && (
              <Card className="shadow mt-3">
                <CardBody className="p-1">
                  <Adutora />
                </CardBody>
              </Card>
            )}

            {!loadingPage && (
              <Card className="shadow mt-3">
                <CardBody className="p-1">
                  <Tubulacao isBalsa={false} />
                </CardBody>
              </Card>
            )}

            {!loadingPage && (
              <Card className="shadow mt-5">
                <CardBody className="p-1">
                  <Bombeamento isBalsa={false} />
                </CardBody>
              </Card>
            )}

            {!loadingPage && (
              <Card className="shadow mt-5">
                <CardBody className="p-1">
                  <Eletrica />
                </CardBody>
              </Card>
            )}

            {(orcamentoInfoBalsa?.isBalsa ?? false) &&
              (orcamentoInfoBalsa?.isBalsaAuxiliar ?? false) && (
                <>
                  <Card className="shadow mt-8">
                    <CardHeader className="mt-3">
                      <h2>Definições até a Balsa</h2>
                    </CardHeader>

                    {!loadingPage && (
                      <Card className="shadow mt-3">
                        <CardBody className="p-1">
                          <Tubulacao
                            isBalsa
                            key={`key_component_tubulacao_balsa_${reloadData}`}
                          />
                        </CardBody>
                      </Card>
                    )}

                    {!loadingPage && (
                      <Card className="shadow mt-5">
                        <CardBody className="p-1">
                          <Bombeamento isBalsa />
                        </CardBody>
                      </Card>
                    )}

                    {!loadingPage && (
                      <Card className="shadow mt-5">
                        <CardBody className="p-1">
                          <EletricaBalsa />
                        </CardBody>
                      </Card>
                    )}
                  </Card>
                </>
              )}

            {!loadingPage && (
              <Card className="shadow mt-5">
                <CardBody className="p-1">
                  <Succao />
                </CardBody>
              </Card>
            )}
          </AdutoraBombeamentoNewContext.Provider>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={bloquearConclusaoPorReprocessamento ? '4' : '12'}>
          <Button
            size="sm"
            className="btn-icon btn-2"
            color="primary"
            type="button"
            disabled={bloquearConclusaoPorReprocessamento}
            onClick={handleSalvarRespostas}
          >
            <FaArrowCircleRight />
            <span className="btn-inner--text">
              Concluir Etapa e Gerar lista de materiais
            </span>
          </Button>
        </Col>
        <Col sm="6">
          <div
            hidden={
              !bloquearConclusaoPorReprocessamento ||
              !necessidadeReprocessamento
            }
          >
            <strong>ATENÇÃO:</strong> Uma ou mais etapas precisam ser
            processadas novamente para garantir uma consistência nos cálculos.
            Itens com essa necessidade estão identificados com
            <Button
              className="btn-icon ml-1"
              color="warning"
              type="button"
              size="sm"
            >
              <FaInfo />
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

import React, {
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useState,
} from 'react';

import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

interface SalvarEmailsProps {
  setEmails: Dispatch<SetStateAction<Option[]>>;
  emails: Option[];
  itemId: number;
}

const SalvarEmails: React.FC<SalvarEmailsProps> = ({
  setEmails,
  itemId,
  emails,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [erro, setErro] = useState<string | null>(null);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyDown: KeyboardEventHandler = event => {
    if (!inputValue) return;
    const isDuplicado = emails.some(item => item.value === inputValue);
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (isDuplicado) {
          setErro('Email já existe');
          break;
        } else if (isValidEmail(inputValue)) {
          const newEmail = { value: inputValue, label: inputValue };
          setEmails(prevOptions => {
            const updatedOptions = [...prevOptions, newEmail];
            return updatedOptions;
          });
          setErro(null);
        } else {
          setErro('Email inválido');
        }
        setInputValue('');
        event.preventDefault();
    }
  };

  const handleChange = (newValue: any) => {
    setEmails(newValue);
    setErro(null);
  };

  return (
    <>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={newValue => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Digite o email"
        value={emails}
      />
      {erro && <div className="text-red mt-2">{erro}</div>}
    </>
  );
};

export default SalvarEmails;

import { ListItem } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  PropostaNegocioAtividadeMaterialOracle,
  PropostaNegocioOracle,
  PropostaNegocioVersaoAtividadeOracle,
} from 'models/PropostaGNFOracle';
import { memo, useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Col, Collapse, Row } from 'reactstrap';
import api from 'services/api';

interface IAtividade {
  numeroNegocio: number;
  negocioGerado: number;
  propostaVersaoAtividade: PropostaNegocioVersaoAtividadeOracle;
}

function Material({
  propostaVersaoAtividade,
  numeroNegocio,
  negocioGerado,
}: IAtividade): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);

  const [
    propostaNegocioVersaoAtividadeMaterial,
    setPropostaNegocioVersaoAtividadeMaterial,
  ] = useState<PropostaNegocioAtividadeMaterialOracle[]>([]);

  async function getDadosGNFMaterial() {
    setIsLoading(true);
    const response = await api.get(
      `/proposta-negocio/${numeroNegocio}?tipo=material&sequencia=${propostaVersaoAtividade.ATIV_CODIGO}&param1=${negocioGerado}`,
      {},
    );

    setPropostaNegocioVersaoAtividadeMaterial(response.data);
    setIsLoading(false);
  }

  useEffect(() => {
    if (numeroNegocio && propostaVersaoAtividade.ATIV_CODIGO) {
      getDadosGNFMaterial();
    }
  }, [numeroNegocio]);

  function renderLista(
    item: PropostaNegocioAtividadeMaterialOracle,
    itemPai = true,
  ) {
    const isOpen = propostaNegocioVersaoAtividadeMaterial.find(
      x => x === item,
    )?.IS_OPEN;

    function handleToggleAccordion() {
      setPropostaNegocioVersaoAtividadeMaterial(
        propostaNegocioVersaoAtividadeMaterial.map(x =>
          x.CODIGO_FILHO === item.CODIGO_FILHO
            ? { ...x, IS_OPEN: !x.IS_OPEN }
            : x,
        ),
      );
    }

    const itemSemSequencia =
      (item.NEGA_SEQ === null || item.NEGA_SEQ === 0) ?? false;

    const listaFilhos = propostaNegocioVersaoAtividadeMaterial.filter(
      it => it.CODIGO_PAI === item.CODIGO_FILHO,
    );

    function renderExpand() {
      return isOpen ? (
        <ExpandLess onClick={handleToggleAccordion} />
      ) : (
        <ExpandMore onClick={handleToggleAccordion} />
      );
    }

    return (
      <div key={`proposta_mat_${Math.random()}_${item.CODIGO_FILHO}`}>
        <ListItem
          style={{ backgroundColor: itemSemSequencia ? `#ffd5de` : 'unset' }}
        >
          <Col sm="2">
            <small className="mb-0">{item.DITB_DITE_CODIGO}</small>
          </Col>
          <Col sm="4">
            <small className="mb-0">{item.DESCRICAO}</small>
          </Col>
          <Col sm="2">
            <small className="mb-0">{item.QTDE_ORCADA}</small>
          </Col>
          <Col sm="2">
            <small className="mb-0">{item.NEGA_SEQ}</small>
          </Col>
          <Col sm="2">
            <div className="float-right" hidden={listaFilhos.length === 0}>
              Composição {renderExpand()}
            </div>
          </Col>
        </ListItem>
        <Collapse isOpen>
          {isOpen === true && listaFilhos.length > 0 ? (
            <>
              <div className="ml-6">
                {listaFilhos.map(filhos => {
                  const subItemSemSequencia =
                    (filhos.NEGA_SEQ === null || filhos.NEGA_SEQ === 0) ??
                    false;

                  return (
                    <ListItem
                      style={{
                        backgroundColor: subItemSemSequencia
                          ? `#ffd5de`
                          : '#faf5f5',
                      }}
                    >
                      <Col sm="2">
                        <small className="mb-0">
                          {filhos.DITB_DITE_CODIGO}
                        </small>
                      </Col>
                      <Col sm="6">
                        <small className="mb-0">{filhos.DESCRICAO}</small>
                      </Col>
                      <Col sm="2">
                        <small className="mb-0">{filhos.QTDE_ORCADA}</small>
                      </Col>
                    </ListItem>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </Collapse>
      </div>
    );
  }

  return (
    <>
      <div className="ml-5" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        <div key="proposta_mat_0_title">
          <ListItem>
            <Col sm="2">
              <strong className="mb-0">#</strong>
            </Col>
            <Col sm="4">
              <strong className="mb-0">Descrição</strong>
            </Col>
            <Col sm="2">
              <strong className="mb-0">Quantidade</strong>
            </Col>
            <Col sm="2">
              <strong className="mb-0">Sequência</strong>
            </Col>
            <Col sm="2" />
          </ListItem>
        </div>

        {isLoading ? (
          <>
            <FaSpinner className="spinner" /> Carregando...
          </>
        ) : (
          propostaNegocioVersaoAtividadeMaterial
            .filter(item => item.CODIGO_PAI === 0)
            .map(item => renderLista(item))
        )}
      </div>
    </>
  );
}

export default memo(Material);

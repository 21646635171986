import { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import formataCnpjCpf from 'utils/formataCnpjCpf';
import IndicadoresNegocio from 'models/IndicadoresNegocio';
import { PropostaImpressaoContext } from '../..';
import { useAuth } from '../../../../../../../hooks/auth';
import { AceiteTexto, AssinaturaCol, Container, Label } from './styles';
import LogoImg from '../../../assets/img/logo_fck.png';

export default function AceiteClausulas(): JSX.Element {
  const { usuario } = useAuth();
  const {
    proposta,
    propostaVersao,
    orcamento,
    propostaConfiguracao,
    tipoIndicador,
    papeisAta,
  } = useContext(PropostaImpressaoContext);
  const { pessoa } = proposta;

  const filteredPapeisAta = papeisAta.filter(
    campo => campo.codigo === propostaVersao?.proposta.usuario.pessoaDomSGE,
  );

  return (
    <Container>
      <AceiteTexto>
        O Cliente Declara ter lido e estar acordado com as Cláusulas e Condições
        de Venda e de Fornecimento, que são partes anexas integrantes desta
        Proposta Comercial.
      </AceiteTexto>

      <Row className="my-5">
        <Col style={{ marginBottom: '50px' }}>
          <Label>Atenciosamente,</Label>
        </Col>
      </Row>
      {/* Ajuste assinatura */}

      {filteredPapeisAta.length > 0 ? (
        filteredPapeisAta.map(campo => (
          <Row className="mt-8" key={campo.id}>
            {/* Ensure to use a unique key */}
            <Col sm="6">
              <AssinaturaCol>
                <div className="text-center">
                  <Label>{campo.pessoa}</Label>
                </div>
                <div className="text-center">
                  <Label>{campo.papel}: &nbsp;</Label>
                </div>
              </AssinaturaCol>
            </Col>
            <Col sm="6">
              <AssinaturaCol>
                <div className="text-center">
                  <Label>{pessoa?.razaoSocial}</Label>
                </div>
                <div className="text-center">
                  <Label>{formataCnpjCpf(pessoa?.documento)}</Label>
                </div>
              </AssinaturaCol>
            </Col>
          </Row>
        ))
      ) : (
        // Render second signature if the specific item is not found
        <Row className="mt-8">
          <Col sm="6">
            <AssinaturaCol>
              <div className="text-center">
                <Label>{proposta?.usuario?.nome}</Label>
              </div>
              <div className="text-center">
                <Label>{tipoIndicador}</Label>
              </div>
            </AssinaturaCol>
          </Col>
          <Col sm="6">
            <AssinaturaCol>
              <div className="text-center">
                <Label>{pessoa?.razaoSocial}</Label>
              </div>
              <div className="text-center">
                <Label>{formataCnpjCpf(pessoa?.documento)}</Label>
              </div>
            </AssinaturaCol>
          </Col>
        </Row>
      )}
    </Container>
  );
}

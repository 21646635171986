import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
  FaArrowCircleUp,
} from 'react-icons/fa';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Swal from 'sweetalert2';

import api from 'services/api';

import { Link } from 'react-router-dom';

import AutocompleteItemDomsge from 'components/AutocompleteItemDomsge';
import ItemDomsge from 'models/ItemDomsge';
import BombaModel from '../../../models/ConfiguradorBomba';
import FabricanteModel from '../../../models/ConfiguradorFabricante';
import FlangeModel from '../../../models/ConfiguradorFlange';

const ConfiguradorBombas: React.FC = () => {
  const [bombas, setBombas] = useState([] as BombaModel[]);
  // eslint-disable-next-line prettier/prettier
  const [bombaEditing, setBombaEditing] = useState({} as BombaModel);
  const [fabricantes, setFabricantes] = useState([] as FabricanteModel[]);
  const [FabricanteEditing, setFabricanteEditing] = useState(
    {} as FabricanteModel,
  );
  const [fabricanteAtivo, setFabricanteAtivo] = useState<FabricanteModel[]>([]);
  const [flanges, setFlanges] = useState([] as FlangeModel[]);
  const [flangeEditing, setFlangeEditing] = useState({} as FlangeModel);

  const [formModalState, setFormModalState] = useState(false);

  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [fabricanteSelecionado, setfabricanteSelecionado] = useState('');
  const [itemDomsgeSelecionado, setItemDomsgeSelecionado] =
    useState<ItemDomsge | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  async function listaBombas() {
    const response = await api.get('/integracao/bombas');

    setBombas(response.data);
  }

  async function listaFabricantes() {
    const response = await api.get('/integracao/bombas/fabricantes');
    setFabricantes(response.data);
    setFabricanteAtivo(
      response.data.filter(
        (item: { indicAtivo: string }) => item.indicAtivo === 'S',
      ),
    );
  }

  async function listaFlanges() {
    const response = await api.get('/integracao/flanges');
    setFlanges(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaBombas();
    listaFabricantes();
    listaFlanges();
  }, []);

  async function handleAtiva(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja reativar a Bomba?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.put(`/integracao/bombas/desativa-bomba/${row.id}`, {
            indicAtivo: 'S',
            tipoBombeamento: row.tipoBombeamento,
          });
          Swal.close();
          toast.success('Bomba Reativada');
          listaBombas();
        }
      });
    } else {
      listaBombas();
    }
  }

  async function handleDesativa(row: any) {
    if (!row.isNew) {
      Swal.fire({
        title: `Deseja desativar a Bomba?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        if (result.isConfirmed) {
          await api.put(`/integracao/bombas/desativa-bomba/${row.id}`, {
            indicAtivo: 'N',
            tipoBombeamento: row.tipoBombeamento,
          });
          Swal.close();
          toast.success('Bomba desativada');
          listaBombas();
        }
      });
    } else {
      listaBombas();
    }
  }

  async function handleInsert() {
    setBombaEditing({} as BombaModel);
    setFlangeEditing({} as FlangeModel);
    setFabricanteEditing({} as FabricanteModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(bomba: BombaModel) {
    setBombaEditing(bomba);

    if (bomba.idBomba) {
      const fabricante = fabricanteAtivo.filter(
        item => item.id === bomba.idBomba,
      );

      if (fabricante.length)
        setFabricanteEditing(fabricante[0] as FabricanteModel);
      else setFabricanteEditing({} as FabricanteModel);
    }

    if (bomba.idFlange) {
      const flange = flanges.filter(item => item.id === bomba.idFlange);

      if (flange.length) setFlangeEditing(flange[0] as FlangeModel);
      else setFlangeEditing({} as FlangeModel);
    }

    setFormModalState(!formModalState);
  }

  async function handleSaveBomba() {
    // eslint-disable-next-line prettier/prettier
    const { nome, nroPolos, tipoBombeamento, potencia } = bombaEditing;
    setIsSaving(true);
    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (
      tipoBombeamento?.toUpperCase() === 'B' ||
      tipoBombeamento?.toUpperCase() === 'S'
    ) {
      if (!potencia) {
        toast.error(
          'O campo de potência é obrigatório para os modelos de Balsa e Submersa!',
        );
        setIsSaving(false);
        return;
      }
    }

    if (!FabricanteEditing.id) {
      toast.error(
        'O Fabricante da bomba está desativado. Não é possível editar os valores!',
      );
      setIsSaving(false);
      return;
    }

    try {
      if (bombaEditing.id) {
        await api.put(`/integracao/bombas/${bombaEditing.id}`, {
          idBomba: FabricanteEditing.id,
          idFlange: flangeEditing.id,
          // eslint-disable-next-line prettier/prettier
        nome: bombaEditing.nome,
          diametroSuccaoEntrada: bombaEditing.diametroSuccaoEntrada,
          diametroRecalqueSaida: bombaEditing.diametroRecalqueSaida,
          descricao: bombaEditing.descricao || '',
          indicAtivo: 'S',
          ditbEmprCodigo:
            itemDomsgeSelecionado?.FABR_EMPR_CODIGO ??
            bombaEditing.ditbEmprCodigo,
          ditbFabrCodigo:
            itemDomsgeSelecionado?.FABR_CODIGO ?? bombaEditing.ditbFabrCodigo,
          ditbDgpeCodigo:
            itemDomsgeSelecionado?.DITE_DGPE_CODIGO ??
            bombaEditing.ditbDgpeCodigo,
          ditbDiteCodigo:
            itemDomsgeSelecionado?.DITE_CODIGO ?? bombaEditing.ditbDiteCodigo,
          potencia: bombaEditing.potencia,
          nroPolos: bombaEditing.nroPolos ?? 4,
          tipoBombeamento: bombaEditing.tipoBombeamento,
        });
      } else {
        await api.post(`/integracao/bombas/`, {
          idBomba: FabricanteEditing.id,
          idFlange: flangeEditing.id,
          // eslint-disable-next-line prettier/prettier
        nome: bombaEditing.nome,
          diametroSuccaoEntrada: bombaEditing.diametroSuccaoEntrada,
          diametroRecalqueSaida: bombaEditing.diametroRecalqueSaida,
          descricao: bombaEditing.descricao,
          indicAtivo: 'S',
          ditbEmprCodigo: itemDomsgeSelecionado?.FABR_EMPR_CODIGO ?? null,
          ditbFabrCodigo: itemDomsgeSelecionado?.FABR_CODIGO ?? null,
          ditbDgpeCodigo: itemDomsgeSelecionado?.DITE_DGPE_CODIGO ?? null,
          ditbDiteCodigo: itemDomsgeSelecionado?.DITE_CODIGO ?? null,
          potencia: bombaEditing.potencia,
          nroPolos: bombaEditing.nroPolos ?? 4,
          tipoBombeamento: bombaEditing.tipoBombeamento ?? 'P',
        });
      }

      toast.success('Registro concluído');
    } finally {
      setFormModalState(false);
      setBombaEditing({} as BombaModel);
      setIsSaving(false);
      listaBombas();
    }
  }

  function handleSelecionarItemDomsge(itemDomsge: ItemDomsge | null) {
    setItemDomsgeSelecionado(itemDomsge);
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{bombaEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Modelo *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Modelo"
                            value={bombaEditing.nome}
                            type="text"
                            onChange={text =>
                              setBombaEditing({
                                ...bombaEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Potência </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Potência"
                            value={bombaEditing.potencia}
                            type="number"
                            onChange={text =>
                              setBombaEditing({
                                ...bombaEditing,
                                potencia: Number(text.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <small>Nº de pólos*</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nº de pólos"
                            type="select"
                            value={bombaEditing.nroPolos}
                            onChange={text =>
                              setBombaEditing({
                                ...bombaEditing,
                                nroPolos: Number(text.target.value),
                              })
                            }
                          >
                            <option value={2}>2</option>
                            <option value={4} selected>
                              4
                            </option>
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Fabricante </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Fabricante"
                            type="select"
                            value={FabricanteEditing.id || ''}
                            onChange={text =>
                              setFabricanteEditing({
                                ...FabricanteEditing,
                                id: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Fabricante...</option>
                            {fabricanteAtivo.map(fabricante => (
                              <option value={fabricante.id}>
                                {fabricante.nome}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Descrição </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Descrição"
                            value={bombaEditing.descricao}
                            type="text"
                            onChange={text =>
                              setBombaEditing({
                                ...bombaEditing,
                                descricao: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Tipo de Flange </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Fabricante"
                            type="select"
                            value={flangeEditing.id}
                            onChange={text =>
                              setFlangeEditing({
                                ...flangeEditing,
                                id: Number(text.target.value),
                              })
                            }
                          >
                            <option value="">Tipo de flange...</option>
                            {flanges.map(flange => (
                              <option value={flange.id}>{flange.nome}</option>
                            ))}
                          </Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Diâmetro de entrada </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Diâmetro de entrada"
                            value={bombaEditing.diametroSuccaoEntrada}
                            type="number"
                            onChange={text =>
                              setBombaEditing({
                                ...bombaEditing,
                                diametroSuccaoEntrada: Number(
                                  text.target.value,
                                ),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Diâmetro de saída </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Diâmetro de saída"
                            value={bombaEditing.diametroRecalqueSaida}
                            type="number"
                            onChange={text =>
                              setBombaEditing({
                                ...bombaEditing,
                                diametroRecalqueSaida: Number(
                                  text.target.value,
                                ),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Item Domsge </small>
                        <AutocompleteItemDomsge
                          hideTitle
                          handleSelecionarItemDomsge={
                            handleSelecionarItemDomsge
                          }
                          maxWidth="60vw"
                          noAutoFocus
                          emprCodigo="710"
                        />
                      </FormGroup>
                      <FormGroup>
                        <small>Tipo bombeamento</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Tipo bombeamento"
                            type="select"
                            value={bombaEditing.tipoBombeamento}
                            onChange={text =>
                              setBombaEditing({
                                ...bombaEditing,
                                tipoBombeamento: text.target.value,
                              })
                            }
                          >
                            <option value="P">Padrão</option>
                            <option value="B">Balsa</option>
                            <option value="S">Submersa</option>
                          </Input>
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSaveBomba}
                        >
                          {isSaving ? 'Salvando...' : 'Salvar'}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Bombas</h3>
              </CardHeader>
              <Row>
                <Col sm="9" />
                <Col sm="3">
                  <FormControlLabel
                    className="float-right"
                    control={
                      <Checkbox
                        checked={mostrarInativos}
                        onChange={event =>
                          setMostrarInativos(event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Mostrar Inativos"
                  />
                </Col>
              </Row>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Modelo</th>
                    <th>Potência</th>
                    <th>Nº de pólos</th>
                    <th>Cod. Sge</th>
                    <th>Fabricante</th>
                    <th>Descrição</th>
                    <th>Tipo de flange</th>
                    <th>Diâmetro de entrada</th>
                    <th>Diâmetro de saída</th>
                    <th>Tipo bombeamento</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {bombas
                    .filter(item =>
                      !mostrarInativos ? item.indicAtivo === 'S' : item,
                    )
                    .map(bomba => {
                      let tipoBomba = '';
                      if (bomba.tipoBombeamento === 'P') {
                        tipoBomba = 'Padrão';
                      } else if (bomba.tipoBombeamento === 'S') {
                        tipoBomba = 'Submersa';
                      } else if (bomba.tipoBombeamento === 'B') {
                        tipoBomba = 'Balsa';
                      }
                      return (
                        <tr key={bomba.id}>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.nome}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.potencia}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.nroPolos}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.ditbDiteCodigo}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.fabricante}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.descricao === null ||
                            bomba.descricao.toUpperCase() === 'NULL'
                              ? ''
                              : bomba.descricao}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.tipoFlange}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.diametroSuccaoEntrada}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {bomba.diametroRecalqueSaida}
                          </td>
                          <td
                            className={
                              bomba.indicAtivo === 'S'
                                ? 'py-2'
                                : 'col text-muted'
                            }
                          >
                            {tipoBomba}
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: `/admin/curvas-bombas`,
                                state: { stateBomba: bomba },
                              }}
                            >
                              <Button
                                size="sm"
                                className="btn-icon"
                                color="primary"
                                type="button"
                              >
                                <span>Curvas</span>
                              </Button>
                            </Link>
                            {bomba.indicAtivo === 'S' ? (
                              <Button
                                size="sm"
                                className="btn-icon"
                                color="warning"
                                type="button"
                                onClick={() => {
                                  handleUpdate(bomba);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                className="btn-icon"
                                color="default"
                                type="button"
                                onClick={() => {
                                  handleAtiva(bomba);
                                }}
                              >
                                <FaArrowCircleUp />
                              </Button>
                            )}
                            {bomba.indicAtivo === 'S' ? (
                              <Button
                                size="sm"
                                className="btn-icon"
                                color="danger"
                                type="button"
                                onClick={() => {
                                  handleDesativa(bomba);
                                }}
                              >
                                <FaTimesCircle />
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorBombas;

import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
} from 'reactstrap';
import withReactContent from 'sweetalert2-react-content';
import { PropostaVersao } from 'models/PropostaVersao';
import { useContext, useEffect, useState } from 'react';
import api from 'services/api';
import {
  FaCheckCircle,
  FaPlusCircle,
  FaTimesCircle,
  FaTrash,
} from 'react-icons/fa';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { PropostaVersaoParcelas } from 'models/PropostaVersaoParcelas';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import formatCurrency from 'utils/formatCurrency';
// eslint-disable-next-line import/extensions
import jsPDF from 'jspdf';
import { CircularProgress } from '@material-ui/core';
import { Th, Td, ContainerButtons } from './styles';
import { PropostaContext } from '../..';

const MySwal = withReactContent(Swal);

interface Params {
  propostaVersao: PropostaVersao;
}

interface iTipoAprovacao {
  id: number;
  descricao: string;
  permiteInserirMultiplos: boolean;
  usaCampoCondicoes: boolean;
  maximoRegistros: number;
}

interface iLogsAprovacao {
  id: number;
  PropostaTipoAprovacao: iTipoAprovacao;
  Usuario: {
    id: number;
    nome: string;
  };
  createdAt: Date;
  descricao: string;
}

interface IPropostaVersaoParcelas extends PropostaVersaoParcelas {
  novo?: boolean;
}

export default function Aprovacao({ propostaVersao }: Params): JSX.Element {
  const { meioPagamentos, meioPagamentoIdSelecionado, atualizarTelaProposta } =
    useContext(PropostaContext);

  const meioSelecionado = meioPagamentos.filter(
    meioPagamento => meioPagamento.id === meioPagamentoIdSelecionado,
  );

  const isTipoFinanciado = meioSelecionado?.[0]?.financiado ?? false;
  const isParalelo = meioSelecionado?.[0]?.descricao === 'Paralelo' ?? false;

  const meioPagamentoSelecionado = meioPagamentos.filter(
    meioPagamento =>
      (isTipoFinanciado &&
        isParalelo &&
        meioPagamento.descricao !== 'Paralelo') ||
      (isTipoFinanciado &&
        !isParalelo &&
        meioPagamento.financiado === true &&
        meioPagamento.descricao !== 'Paralelo') ||
      (!isTipoFinanciado &&
        meioPagamento.financiado === false &&
        meioPagamento.descricao !== 'Paralelo'),
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [percentualAprovar, setPercentualAprovar] = useState<number>(
    propostaVersao?.desconto ?? 0,
  );
  const [valorMaoObra, setValorMaoObra] = useState<number>(
    propostaVersao?.valorMaoObraSupervisor ?? 0,
  );
  const [percentualSolicitado, setPercentualSolicitado] = useState<number>(
    propostaVersao?.desconto ?? 0,
  );

  const [aprovacaoComplemento, setAprovacaoComplemento] = useState<string>(
    propostaVersao?.aprovacaoComplemento ?? '',
  );

  const [valorMaximoUsuairo, setValorMaximoUsuario] = useState<number>(0);

  const [tipoAprovacao, setTipoAprovacao] = useState<iTipoAprovacao>(
    {} as iTipoAprovacao,
  );
  const [propostaVersaoParcelas, setPropostaVersaoParcelas] = useState<
    IPropostaVersaoParcelas[]
  >([]);
  const [valorTotalProposta, setValorTotalProposta] = useState(0);

  const [logsAprovacao, setLogsAprovacao] = useState<iLogsAprovacao[]>([]);

  async function checkAprovacaoDescontoNecessaria(aprovar: boolean) {
    if (tipoAprovacao.id !== 1) return { aprovacao: false, salvar: true };
    if (!aprovar) return { aprovacao: false, salvar: true };

    const descontoSalvar = Number(percentualAprovar);

    if (descontoSalvar > Number(valorMaximoUsuairo ?? 0)) {
      const valor = await Swal.fire({
        title: `Nova aprovação necessária`,
        html: `<div style='text-align: justify!important; text-justify: inter-word;'>
            O percentual informado (${descontoSalvar}%) excede o máximo permitido (<u>menor que</u> ${valorMaximoUsuairo}%) para o perfil.
            <br /><br />O próximo grupo de aprovadores será informado, para uma nova avaliação de aprovação. Os logs da sua aprovação ficarão salvos para o próximo usuário. Deseja prosseguir?</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, prosseguir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      });
      return { aprovacao: true, salvar: valor.isConfirmed };
    }

    return { aprovacao: false, salvar: true };
  }

  async function aprovarReprovar(aprovar: boolean, motivo: string) {
    try {
      if (tipoAprovacao.id === 3) {
        Swal.fire({
          icon: 'info',
          title: `Salvando as informações e enviando ata para lista de usuários...`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        if (aprovar)
          await api.get(
            `/ata-briefing/gerar-pdf?propostaVersaoId=${propostaVersao.id}&enviarPdf=N&retornarHtml=N`,
            {},
          );
      }

      setIsLoading(true);
      await api.put(`/proposta/versao/${propostaVersao.id}/aprovacao`, {
        aprovado: aprovar,
        motivo,
        tipoAprovacao: tipoAprovacao.id,
        percentualAprovado:
          tipoAprovacao.id === 4 ? valorMaoObra : percentualAprovar,
        parcelas: [...propostaVersaoParcelas],
      });
    } finally {
      toast.success('Feito!', {});
      MySwal.close();
      setIsLoading(false);
      atualizarTelaProposta(false);
    }
  }

  async function openModalReprovar() {
    let motivo = '';

    async function openModal() {
      MySwal.fire({
        title: `Deseja Reprovar a solicitação de ${tipoAprovacao.descricao} na proposta?`,
        html: `
          <strong>Informe um motivo dessa reprovação:</strong>
          <input id="swal-input1" value='${motivo}' class="swal2-input">
          ${
            tipoAprovacao.id === 2
              ? `<br />As parcelas serão excluídas. O usuário deverá preencher novamente.`
              : ''
          }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, reprovar`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(async result => {
        const input = Swal?.getHtmlContainer()?.querySelector(
          '#swal-input1',
        ) as any;

        motivo = input.value;
        if (result.isConfirmed) {
          if (motivo.length < 5) openModal();
          else aprovarReprovar(false, motivo);
        }
      });
    }

    openModal();
  }

  async function openModalAprovar() {
    MySwal.fire({
      title: `Deseja Aprovar a solicitação de ${tipoAprovacao.descricao} na proposta?`,
      text: `Quaisquer mudanças efetuadas nos campos serão salvas e aplicadas na proposta.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, aprovar`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        const salvar = await checkAprovacaoDescontoNecessaria(true);

        if (salvar.salvar) {
          aprovarReprovar(true, '');
        }
      }
    });
  }

  function handleAprovarReprovar(aprovar: boolean) {
    // Validações para Desconto
    if (tipoAprovacao.id === 1) {
      if (aprovar && (!percentualAprovar || percentualAprovar <= 0)) {
        toast.error(`O percentual é obrigatório`);
        return;
      }
    }
    // Validações para Parcelas
    else if (tipoAprovacao.id === 2) {
      if (aprovar) {
        const listaItensFaltantes = propostaVersaoParcelas.filter(
          (item: any) =>
            !item.meioPagamentoId ||
            !item.percentual ||
            !item.dataPrevista ||
            !item.observacao,
        );

        if (listaItensFaltantes && listaItensFaltantes.length > 0) {
          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            html: 'Um ou mais registros da lista estão incompletos. Todos são obrigatórios.',
          });
          return;
        }

        // testa a soma dos valores
        const somaValor = propostaVersaoParcelas.reduce(
          (accumulator, object) => {
            // eslint-disable-next-line prettier/prettier
            return accumulator + (Number(object.valor ?? 0) * 100);
          },
          0,
        );

        // Não pode ser menor, nem maior que 100
        if (somaValor / 100 !== propostaVersao.valorFinal) {
          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            html: `A soma das parcelas (${formatCurrency(
              somaValor / 100,
              2,
            )}) deve ser igual ao valor da proposta ${formatCurrency(
              propostaVersao.valorFinal,
              2,
            )}`,
          });
          return;
        }
      }
    } else if (tipoAprovacao.id === 4) {
      if (aprovar && (!valorMaoObra || valorMaoObra <= 0)) {
        toast.error(`Valor de Mão de Obra é obrigatório`);
        return;
      }
    }

    if (!aprovar) {
      openModalReprovar();
    } else {
      openModalAprovar();
    }
  }

  async function getInformacoesAprovacao() {
    setIsLoading(true);
    const response2 = await api.get(
      `/proposta/versao/${propostaVersao.id}/aprovacao`,
      {},
    );

    setTipoAprovacao(response2.data?.propostaTipoAprovacao);
    setLogsAprovacao(response2.data?.propostaVersaoLogAprovacao);
    setValorMaximoUsuario(response2.data?.descontoMaximo?.descontoMaximo);
    setIsLoading(false);
  }

  useEffect(() => {
    getInformacoesAprovacao();
  }, []);

  function handleChangeValue(
    parcelaId: number,
    name: string,
    value: number | string,
  ) {
    const percentual =
      name === 'percentual'
        ? {
            valor: arredondaDecimais(
              (Number(value) * valorTotalProposta) / 100,
              2,
            ),
          }
        : {};

    const novaPropostaVersaoParcelas = propostaVersaoParcelas.map(
      propostaVersaoParcela =>
        propostaVersaoParcela.id === parcelaId
          ? {
              ...propostaVersaoParcela,
              ...percentual,
              [name]: value,
            }
          : propostaVersaoParcela,
    );

    setPropostaVersaoParcelas(novaPropostaVersaoParcelas);
  }

  function handleAdicionaNovaLinha() {
    const objMax =
      propostaVersaoParcelas && propostaVersaoParcelas.length > 0
        ? propostaVersaoParcelas?.reduce((max, curren) =>
            max.id > curren.id ? max : curren,
          )
        : null;

    setPropostaVersaoParcelas([
      ...propostaVersaoParcelas,
      {
        id: (objMax?.id ?? 0) + 1,
        propostaVersaoId: propostaVersao.id,
        meioPagamentoId: meioPagamentoSelecionado[0].id,
        dataPrevista: null,
        percentual: null,
        valor: 0,
        observacao: '',
      },
    ]);
  }

  async function deletaParcela(propostaVersaoParcela: IPropostaVersaoParcelas) {
    const novoPropostaVersaoParcelas = propostaVersaoParcelas.filter(
      parcela => parcela.id !== propostaVersaoParcela.id,
    );
    setPropostaVersaoParcelas(novoPropostaVersaoParcelas);
  }

  async function getParcelas() {
    const response = await api.get(
      `/proposta/versao/${propostaVersao.id}/parcelas`,
    );
    setPropostaVersaoParcelas(
      response.data.map((item: any, index: number) => {
        return {
          ...item,
          id: index + 1,
        };
      }),
    );
  }

  useEffect(() => {
    if (tipoAprovacao.id === 2 && propostaVersao) {
      setValorTotalProposta(propostaVersao?.valorFinal ?? 0);
      getParcelas();
    }
  }, [tipoAprovacao, propostaVersao]);

  return (
    <>
      <Card className="shadow mt-1 mb-1">
        <CardBody>
          <Row className="ml-0">
            <h6 className="heading-small text-muted mb-1">
              Aprovação de {tipoAprovacao.descricao}
              {isLoading && (
                <CircularProgress
                  className="mt-2 ml-2"
                  color="inherit"
                  size={10}
                />
              )}
            </h6>
          </Row>
          <hr className="mt-2" />

          <Row hidden={tipoAprovacao.id !== 1}>
            <Col className="mt-2" sm="5">
              <div
                className="float-right"
                style={{
                  textAlign: 'right',
                  paddingTop: '10px',
                }}
              >
                <strong className="mt-2">
                  Percentual desconto para aprovar:
                </strong>
                <br />
                <small>
                  Você pode alterar o percentual de desconto solicitado (
                  {percentualSolicitado}%) para outro valor, que será atualizado
                  na proposta após aprovação.
                </small>
              </div>
            </Col>
            <Col className="mt-2" sm="5">
              <InputGroup className="input-group-alternative text-center">
                <Input
                  type="number"
                  disabled={isLoading}
                  value={String(percentualAprovar)}
                  onChange={event =>
                    setPercentualAprovar(Number(event.target.value))
                  }
                />
                <InputGroupAddon addonType="append">%</InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>

          <Row hidden={tipoAprovacao.id !== 2}>
            <Col sm="12">
              <ContainerButtons>
                <Button
                  className="btn btn-outline-default"
                  type="button"
                  size="sm"
                  onClick={handleAdicionaNovaLinha}
                >
                  Novo <FaPlusCircle />
                </Button>
              </ContainerButtons>

              <Table responsive bordered>
                <thead>
                  <tr>
                    <Th>Nº</Th>
                    <Th>Percentual</Th>
                    <Th>Data prevista</Th>
                    <Th>Meio de pagamento</Th>
                    <Th className="text-right">Total</Th>
                    <Th>Evento</Th>
                    <Th />
                  </tr>
                </thead>
                <tbody>
                  {propostaVersaoParcelas.map(
                    (
                      propostaVersaoParcela: IPropostaVersaoParcelas,
                      index: number,
                    ) => {
                      const {
                        id,
                        percentual,
                        dataPrevista,
                        meioPagamentoId,
                        valor,
                        observacao,
                      } = propostaVersaoParcela;

                      const idRow = index + 1;

                      const dataPrevistaSemHora =
                        String(dataPrevista)?.split('T')[0];
                      return (
                        <tr key={idRow}>
                          <Td
                            style={{
                              verticalAlign: 'middle',
                              textAlign: 'center',
                            }}
                          >
                            {idRow}
                          </Td>
                          <Td>
                            <InputGroup className="input-group-alternative">
                              <Input
                                type="number"
                                value={String(percentual)}
                                onChange={event =>
                                  handleChangeValue(
                                    id,
                                    'percentual',
                                    event.target.value,
                                  )
                                }
                              />
                              <InputGroupAddon addonType="append">
                                %
                              </InputGroupAddon>
                            </InputGroup>
                          </Td>
                          <Td>
                            <Input
                              type="date"
                              value={dataPrevistaSemHora}
                              onChange={event =>
                                handleChangeValue(
                                  id,
                                  'dataPrevista',
                                  event.target.value,
                                )
                              }
                            />
                          </Td>
                          <Td>
                            <Input
                              type="select"
                              value={meioPagamentoId}
                              onChange={event =>
                                handleChangeValue(
                                  id,
                                  'meioPagamentoId',
                                  Number(event.target.value),
                                )
                              }
                            >
                              <option key="item_sem_valor" value="">
                                Selecione...
                              </option>

                              {meioPagamentoSelecionado.map(meioPagamento => (
                                <option
                                  key={meioPagamento.id}
                                  value={meioPagamento.id}
                                >
                                  {meioPagamento.descricao}
                                </option>
                              ))}
                            </Input>
                          </Td>
                          <Td className="text-right">
                            <Label className="pt-3 pr-4 text-muted">
                              <strong>{formatCurrency(valor)}</strong>
                            </Label>
                          </Td>
                          <Td>
                            <Input
                              value={String(observacao)}
                              onChange={event =>
                                handleChangeValue(
                                  id,
                                  'observacao',
                                  String(event.target.value),
                                )
                              }
                            />
                          </Td>
                          <Td>
                            <InputGroup className="mt-2 pl-3 pr--1">
                              <Button
                                outline
                                className="btn-icon btn-2 ml-1"
                                color="danger"
                                type="button"
                                size="sm"
                                onClick={() =>
                                  deletaParcela(propostaVersaoParcela)
                                }
                              >
                                <FaTrash />
                              </Button>
                            </InputGroup>
                          </Td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4} className="text-right">
                      <strong>Total:</strong>
                    </td>
                    <td className="text-right">
                      <strong>
                        {formatCurrency(
                          propostaVersaoParcelas.reduce(
                            // eslint-disable-next-line prettier/prettier
                            (total, item) => total + ((item.valor ?? 0) * 100),
                            0,
                          ) / 100,
                        )}
                      </strong>
                    </td>
                    <td colSpan={2} />
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>

          <Row hidden={tipoAprovacao.id !== 3}>
            <Col className="mt-2" sm="12">
              <p>
                Proposta aguarda a aprovação final para que seu status possa ser
                alterado para Aprovado.
              </p>
              <p>
                Ao aprovar, o status será alterado para Aprovado, ou voltará
                para o status original, caso seja reprovado.
              </p>
            </Col>
          </Row>

          <Row hidden={tipoAprovacao.id !== 4}>
            <Col className="mt-2" sm="5">
              <div
                className="float-right"
                style={{
                  textAlign: 'right',
                  paddingTop: '10px',
                }}
              >
                <strong className="mt-2">Valor Mão de Obra Supervisor:</strong>
                <br />
                <small>
                  Você pode alterar o valor (
                  {formatCurrency(
                    propostaVersao?.valorMaoObraSupervisor ?? 0,
                    2,
                  )}
                  ) para outro valor, que será atualizado na proposta após
                  aprovação.
                </small>
              </div>
            </Col>
            <Col className="mt-2" sm="5">
              <InputGroup className="input-group-alternative text-center">
                <InputGroupAddon addonType="append">R$</InputGroupAddon>
                <Input
                  type="number"
                  disabled={isLoading}
                  value={String(valorMaoObra)}
                  onChange={event =>
                    setValorMaoObra(Number(event.target.value))
                  }
                />
              </InputGroup>
            </Col>
          </Row>

          <Row hidden={tipoAprovacao.id !== 5}>
            <Col className="mt-2" sm="12">
              <p>Lista dos materiais que serão excluídos</p>
              <pre>{aprovacaoComplemento}</pre>
            </Col>
          </Row>

          <Row>
            <Col className="mt-4" sm="6" hidden={tipoAprovacao.id === 4}>
              <Button
                className="btn-block"
                color="danger"
                type="button"
                size="sm"
                disabled={isLoading}
                onClick={() => handleAprovarReprovar(false)}
              >
                <FaTimesCircle /> Reprovar
              </Button>
            </Col>
            <Col className="mt-4" sm="6">
              <Button
                className="btn-block"
                color="success"
                type="button"
                size="sm"
                disabled={isLoading}
                onClick={() => handleAprovarReprovar(true)}
              >
                <FaCheckCircle /> Aprovar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="shadow mt-2 mb-1">
        <CardBody>
          <Row className="ml-0">
            <h6 className="heading-small text-muted mb-1">
              Logs de Aprovação de {tipoAprovacao.descricao}
              {isLoading && (
                <CircularProgress
                  className="mt-2 ml-2"
                  color="inherit"
                  size={10}
                />
              )}
            </h6>
          </Row>
          <hr className="mt-2" />
          <Row>
            <Col sm="12">
              {logsAprovacao.map(item => {
                const dataGeracao = new Intl.DateTimeFormat('pt-BR', {
                  dateStyle: 'long',
                  timeStyle: 'medium',
                }).format(new Date(item.createdAt));

                return (
                  <div className="mt-1">
                    <small className="font-weight-bold">{dataGeracao}:</small>
                    <p className="text-sm my-2">
                      [{item.Usuario.nome}] - {item.descricao}
                    </p>
                  </div>
                );
              })}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

import styled from 'styled-components';
import { TextField as TextFieldC } from '@material-ui/core';

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 1% 0.5%;
`;

interface TdProps {
  editavel?: boolean;
}

export const Td = styled.td<TdProps>`
  background-color: ${props => (props.editavel ? 'unset' : '#f1f1f1')};
  padding-top: 10px !important;
  padding-bottom: 0 !important;
`;

interface TrProps {
  alterado?: boolean;
}

export const Tr = styled.tr<TrProps>`
  border: ${props => (props.alterado ? '1.1px solid #a5d2a7' : 'none')};
`;

export const TextField = styled(TextFieldC).attrs({
  InputProps: {
    style: { fontSize: 12, marginTop: -8 },
    disableUnderline: true,
  },
})``;

export const ColQuestaoTitulo = styled.div`
  flex-grow: 1;
  padding: 2px 1.6rem 0 1.6rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  background-color: #2f75b6;
`;

export const LabelQuestao = styled.label`
  font-size: 0.71rem;
  font-weight: 600;
  color: #8898aa;
`;

export const ColQuestao = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  vertical-align: middle;
`;

export const LabelQuestaoTitulo = styled.label`
  font-size: 0.8125rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
`;

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Row, Col } from 'reactstrap';

import FichaTecnica from 'models/FichaTecnica';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import { PropostaVersao } from 'models/PropostaVersao';

import api from 'services/api';
import formatCurrency from 'utils/formatCurrency';
import OrcamentoProduto from 'models/OrcamentoProduto';
import RegraItem from 'models/RegraItem';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import LogoImg from '../../../assets/img/logo_fck.png';
import {
  Atividade,
  Container,
  Descricao,
  HorizontalRule,
  Item,
  Typography,
  DecricaoTotal,
  DecricaoTotalDestaque,
} from './styles';

type TipoExclusao = {
  tipoExclusaoId: number;
  tipoExclusaoDescricao: string;
  sequenciaAtividade: number;
};

type SequenciaAtividadeData = {
  sequenciaAtividade: number[];
  orcamentoId: number;
  descricaoTipoId: TipoExclusao[];
};

interface AtividadeItem {
  id: number;
  descricao: string;
  tipoExclusaoDesc?: string | null;
  atividade: number;
  mostrarMateriais: boolean;
  materiais: {
    id: number;
    descricao: string;
    quantidade: number;
    descricaoProposta: string;
  }[];
  questoes: {
    id: number;
    descricao: string;
    resposta: string;
  }[];
}

interface Equipamento {
  propostaConfiguracao: PropostaConfiguracao;
  isPropostaExterior: boolean;
  valorDolar: number;
  propostaVersao: PropostaVersao;
}

interface Modelo {
  id: number;
  nome: string;
  sigla: string;
  patente?: string;
}

export default function Equipamento({
  propostaConfiguracao,
  isPropostaExterior,
  valorDolar,
  propostaVersao,
}: Equipamento): JSX.Element {
  const [fichaTecnica, setFichaTecnica] = useState<FichaTecnica>();
  const [orcamentoProduto, setOrcamentoProduto] = useState<OrcamentoProduto>();
  const [atividadesItens, setAtividadesItens] = useState<AtividadeItem[]>([]);
  const [modelo, setModelo] = useState<Modelo[]>([]);
  const [regrasItem, setRegrasItem] = useState<RegraItem[]>([]);
  const [tipoSeMaterialVazio, setTipoSeMaterialVazio] =
    useState<SequenciaAtividadeData>();
  const {
    fichaTecnica: fichaCadastrada,
    valorTotalItens,
    orcamento,
    orcamentoId,
  } = propostaConfiguracao;
  const pivoCentral = fichaCadastrada?.pivoCentral;
  const { codigoOportunidadeDomsge } = orcamento || {};
  const {
    ncm,
    finame,
    nome: descricaoProduto,
    nomeProposta,
  } = orcamentoProduto?.produto || {};
  const quantidadeLances = fichaTecnica?.FichaTecnicaLance?.reduce(
    (total, item) => total + item.quantidade,
    0,
  );
  const marca = 'Marca Fockink';
  const areaIrrigada = fichaTecnica?.areaTotalIrrigada;
  const sistema =
    fichaTecnica?.FichaTecnicaBombeamento?.[0]?.motor || 'NÃO INFORMADO';
  const raio = fichaTecnica?.raioEfetivoAreaIrrigada;
  // const [valorTotalGeral, setValorTotalGeral] = useState(0); Valor total por configuração
  const comprimentoTotalTubos = fichaCadastrada?.comprimentoTotalTubos;
  const raio1 = Number(fichaCadastrada?.aspersor1Raio ?? 0);
  const raio2 = Number(fichaCadastrada?.aspersor2Raio ?? 0);
  const incluirAspersorFinal = fichaCadastrada?.incluirAspersorFinal;
  const areaTotalIrrigadaCAF = fichaCadastrada?.areaTotalIrrigadaCAF;
  const areaEfetivaIrrigadaSAF = fichaCadastrada?.areaEfetivaIrrigadaSAF;
  const temMeioPagamentoFinanciado =
    propostaVersao.meioPagamento?.financiado ?? false;
  const recursoProprio = propostaVersao.meioPagamento?.descricao;
  const isDiferenteRecursoProprio =
    recursoProprio?.toUpperCase() !== 'Recurso Próprio'.toUpperCase();
  const fichatecnicaId = fichaTecnica?.id;

  const usaAspersorFinal: boolean =
    String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
    String(incluirAspersorFinal).toUpperCase() === 'S';

  const valorArea = usaAspersorFinal
    ? Number(areaTotalIrrigadaCAF ?? 0)
    : Number(areaEfetivaIrrigadaSAF ?? 0);

  const { data: cacheFichaTecnica } = useQuery(
    `ficha-tecnica-orcamento-${orcamentoId}`,
    async () => {
      const response = await api.get(
        `/ficha-tecnica/orcamento-versao/${orcamentoId}`,
      );
      return response.data;
    },
  );

  useEffect(() => {
    if (cacheFichaTecnica) {
      setFichaTecnica(cacheFichaTecnica);
    }
  }, [cacheFichaTecnica]);

  const { data: cacheOrcamentoProduto } = useQuery(
    `configuracao-${orcamentoId}-produto`,
    async () => {
      const response = await api.get(`/orcamento/versao/${orcamentoId}`, {
        params: { completo: true },
      });
      const orcamentoProdutoData: OrcamentoProduto =
        response.data.OrcamentoProduto[0];
      return orcamentoProdutoData;
    },
  );

  /*
  -- Valor total por configuração
  async function carregaOrcamentoMateriais(): Promise<void> {
    const response = await api.get(`/orcamento/lista-materiais-agrupado-novo`, {
      params: { orcamentoId },
    });
    setValorTotalGeral(response.data.total);
    const valorGeral = formatCurrency(Number(response.data.total), 2);
    console.log(valorGeral);
  }

  useEffect(() => {
    if (orcamentoId) {
      carregaOrcamentoMateriais();
    }
  }, []);
  */

  useEffect(() => {
    if (cacheOrcamentoProduto) {
      setOrcamentoProduto(cacheOrcamentoProduto);
    }
  }, [cacheOrcamentoProduto]);

  const { data: cacheAtividadesItens } = useQuery(
    `atividade-item-configuracao-${orcamentoId}`,
    async () => {
      const response = await api.get(
        `/proposta-atividade-item/orcamentoid/${orcamentoId}`,
      );
      /**
       *
      const response = await api.get(
        `/proposta-atividade-item/orcamento-id-novo/${orcamentoId}`,
      );
      */
      return response.data;
    },
  );

  const { data: cacheModelos } = useQuery(`modelos`, async () => {
    const response = await api.get(`/integracao/modelos`);
    return response.data;
  });

  /**
   *
  const { data: cacheTipoSeMaterialVazio } = useQuery(
    ['materiaisExcluidos', orcamentoId],
    async () => {
      if (atividadesItens.length > 0) {
        const ids = atividadesItens.map(item => item.id);

        const response = await api.post(
          `/tipo-exclusao/pegar-tipo-materiais-excluidos`,
          {
            seqAtiv: ids,
            orcamentoId,
          },
        );
        return response.data;
      }
      return [];
    },
    {
      enabled: atividadesItens.length > 0, // só roda se o array não estiver vazio
    },
  );

  useEffect(() => {
    if (cacheTipoSeMaterialVazio) {
      setTipoSeMaterialVazio(cacheTipoSeMaterialVazio);
    }
  }, [cacheTipoSeMaterialVazio]);
  */

  function arredondaValor(valor?: number | null): number {
    if (!valor) return 0;

    return Math.ceil((valor + 10) * 1.04);
  }

  useEffect(() => {
    if (cacheAtividadesItens) {
      setAtividadesItens(cacheAtividadesItens);
    }
  }, [cacheAtividadesItens]);

  useEffect(() => {
    if (cacheModelos) {
      setModelo(cacheModelos);
    }
  }, [cacheModelos]);

  function retornaPatenteSeExiste(modeloPivo: string): string | null {
    const modelosFormatados: Modelo[] = modelo.map(modelos => ({
      ...modelos,
      nome:
        modelos.nome.charAt(0).toUpperCase() +
        modelos.nome.slice(1).toLowerCase(),
    }));

    // Verifica se tem o modelo
    const modeloEncontrado = modelosFormatados.find(
      modelos => modelos.nome === modeloPivo,
    );

    // Caso não ache o modelo retorna null
    if (!modeloEncontrado) return null;

    // Se achar o modelo e tiver patente retorna a patente, se não tiver patente retorna null
    return modeloEncontrado.patente || null;
  }

  function mostrarTipoDescricao(atividadeItemId: number) {
    if (Number(tipoSeMaterialVazio?.orcamentoId) === orcamentoId) {
      const value = tipoSeMaterialVazio?.descricaoTipoId.find(
        item => item.sequenciaAtividade === atividadeItemId,
      );
      // console.log(atividadeItemId);
      // console.log(tipoSeMaterialVazio);

      return (
        value?.tipoExclusaoDescricao || 'Material não considerado na Proposta'
      );
    }

    return 'Material não considerado na Proposta';
  }

  function AtividadesItens(): JSX.Element {
    return (
      <div className="ml-3">
        {atividadesItens.map((atividadeItem, index) => (
          <>
            <div className="mb-6">
              <Row>
                <Atividade className="mt-4">
                  {`${index + 1} - ${atividadeItem.descricao}`}
                </Atividade>
              </Row>
              <div className="m-3" hidden={!atividadeItem.questoes.length}>
                {atividadeItem.questoes.map(questao => (
                  <>
                    <Row>
                      <Col sm="3">
                        <Item>{questao.descricao}:</Item>
                      </Col>
                      <Col sm="3">
                        <Item
                          style={{
                            fontStyle: 'italic',
                          }}
                        >
                          {questao.descricao === 'Condução de Energia' ||
                          questao.descricao === 'Comando'
                            ? arredondaValor(Number(questao.resposta))
                            : questao.resposta || ''}
                        </Item>
                      </Col>
                    </Row>

                    <div
                      className="mt-1 bg-info text-center"
                      // hidden={!(questao.resposta === 'Essencial')}
                      hidden={!retornaPatenteSeExiste(questao.resposta)}
                    >
                      <Row>
                        <Col>
                          <Item>
                            {retornaPatenteSeExiste(questao.resposta)}
                          </Item>
                        </Col>
                      </Row>
                    </div>
                  </>
                ))}
              </div>
              <div className="m-3">
                {(atividadeItem.materiais.length === 0 &&
                  atividadeItem.questoes.length === 0) ||
                (atividadeItem.materiais.length === 0 &&
                  atividadeItem.mostrarMateriais === true) ? (
                  <>
                    {/**
                      <Item>{mostrarTipoDescricao(atividadeItem.id)}</Item>
                    SeqId: {atividadeItem.id} ||| OrcamentoId: {orcamentoId} |||
                    Descricao:
                     */}
                    <Col sm="8">
                      <Item>
                        {/* tipoExclusaoDesc não existe em atividadeItem, por isso o ignore aqui */}
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        {atividadeItem.tipoExclusaoDesc ??
                          'Material não considerado na Proposta'}
                      </Item>
                    </Col>
                  </>
                ) : (
                  <>
                    {atividadeItem.materiais.length !== 0 ? (
                      <Row>
                        <Col sm="3">
                          <Item>Qtde</Item>
                        </Col>
                        <Col sm="8">
                          <Item>Descrição</Item>
                        </Col>
                      </Row>
                    ) : (
                      <> </>
                    )}

                    {atividadeItem.materiais.map(material => (
                      <Row>
                        <Col sm="3">
                          <Item>{material.quantidade || 'Não possui'}</Item>
                        </Col>
                        <Col sm="8">
                          <Item>
                            {material.descricaoProposta?.length > 0
                              ? material.descricaoProposta
                              : material.descricao}
                          </Item>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </div>
            </div>
            <HorizontalRule />
          </>
        ))}
      </div>
    );
  }

  function Totais(): JSX.Element {
    return (
      <>
        <Row>
          <Col>
            <DecricaoTotal>Área total irrigada</DecricaoTotal>
          </Col>
          <Col>
            <DecricaoTotal>{valorArea} HA</DecricaoTotal>
          </Col>
        </Row>
        {isDiferenteRecursoProprio && (
          <>
            <Row>
              <Col>
                <DecricaoTotal>Classificação fiscal</DecricaoTotal>
              </Col>
              <Col>
                <DecricaoTotal>NCM {ncm}</DecricaoTotal>
              </Col>
            </Row>

            <Row>
              <Col>
                <DecricaoTotalDestaque>
                  Código de identificação do produto na Finame
                </DecricaoTotalDestaque>
              </Col>
              <Col>
                <DecricaoTotalDestaque>{finame}</DecricaoTotalDestaque>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <DecricaoTotal>Sistema</DecricaoTotal>
          </Col>
          <Col>
            <DecricaoTotal>{sistema}</DecricaoTotal>
          </Col>
        </Row>
      </>
    );
  }

  function formataValor(valor?: number | null) {
    if (!valor || !valorDolar) return formatCurrency(0, 2, isPropostaExterior);

    if (isPropostaExterior) {
      // Converte para Dólar
      const convertido = valor / valorDolar;
      return formatCurrency(convertido, 2, true);
    }
    return formatCurrency(valor);
  }

  function PrecoTotal(): JSX.Element {
    return (
      <Row className="my-3">
        <Col>
          <Typography variant="h6" className="float-left">
            <strong>PREÇO TOTAL DO SISTEMA</strong>
          </Typography>
        </Col>
        <Col>
          <Typography variant="h6" className="float-right">
            <strong>{formatCurrency(propostaVersao?.valorFinal, 2)}</strong>
          </Typography>
        </Col>
      </Row>
    );
  }

  return (
    <Container>
      <Typography variant="h5" className="mb-4">
        <i>{nomeProposta ?? descricaoProduto}</i>
      </Typography>

      <div className="mb-3">
        <Descricao>
          01 {nomeProposta ?? descricaoProduto}, {marca}, sistema {sistema} com{' '}
          {quantidadeLances} torres de sustentação, raio{' '}
          {arredondaDecimais(
            Number(comprimentoTotalTubos ?? 0) +
              (raio1 > raio2 ? raio1 : raio2),
            2,
          )}{' '}
          M para área irrigada de {valorArea} HA, composto de
        </Descricao>
      </div>

      <AtividadesItens />
      <Totais />
      {/* Comentando o valor total por configuração/equipamento que é o valorTotalGeral, para posteriormente usá-lo}
      <PrecoTotal />
  { */}
      <div className="mb-4" />
    </Container>
  );
}

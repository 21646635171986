import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import api from 'services/api';
import { FaInfoCircle, FaUndo } from 'react-icons/fa';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { IconButton, Tooltip } from '@material-ui/core';

interface ResponseData {
  id: number;
  descricao: string;
  parteId: number;
  questaoId: number | null;
  ordem: number;
  tipo: string;
  valoresPossiveis: string | null;
  unidadeMedida: string | null;
  dataHoraInicio: string;
  dataHoraFim: string | null;
  createdAt: string;
  updatedAt: string;
  questaoSelecaoAssociadaId: number | null;
  valorMaximo: number | null;
  valorMinimo: number | null;
  tabelaGenericaId: number | null;
  isObrigatorio: boolean;
  permiteMultiSelecao: boolean;
  indicManutencaoAdm: boolean;
  indicCalculoImpressao: boolean;
  mudou?: boolean; // Só existe neste contexto para atualizar somente os valores que mudaram
  PropostaAtividadeQuestao: {
    id: number;
    questaoId: number | null;
    propostaAtividadeId: number | null;
    descricao: string | null;
    ordem: number;
  }[];
  OrcamentoResposta: {
    id: number;
    orcamentoParteId: number;
    questaoId: number;
    resposta: string;
    createdAt: string;
    updatedAt: string;
  }[];
}

interface Status {
  id: number;
  descricao: string;
}

interface PropostaVersao {
  id: number;
  propostaId: number;
  versao: number;
  status: Status;
}

interface Status {
  id: number;
  descricao: string;
}

interface PropostaVersao {
  id: number;
  propostaId: number;
  versao: number;
  status: Status;
}

interface PropostaConfiguracaoItem {
  orcamentoId: number;
  propostaVersao: PropostaVersao;
}

interface ResponseValidar {
  PropostaConfiguracao: PropostaConfiguracaoItem[];
}

const STATUS_ID_GERADO = 1;

export default function VariaveisFT(): JSX.Element {
  const [proposta, setProposta] = useState<any>({});
  const [propostaValidaManutencao, setPropostaValidaManutencao] =
    useState<boolean>(false);
  const [propostaManutencao, setPropostaManutencao] = useState<string>('');
  const [propostaVersaoManutencao, setPropostaVersaoManutencao] =
    useState<string>('');
  const [formModalState, setFormModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [configuracaoId, setConfiguracaoId] = useState('');
  const [configuracaoIdValidada, setConfiguracaoIdValidada] = useState('');
  const [questoesIndManuAdm, setQuestoesIndManuAdm] =
    useState<ResponseData[]>();
  const [statusDesc, setStatusDesc] = useState('');
  const [habilitarSalvar, setHabilitarSalvar] = useState(true);

  useEffect(() => {
    if (!propostaManutencao || !propostaVersaoManutencao) {
      setPropostaValidaManutencao(false);
      setProposta({});
      setFormModalState(!formModalState);
    }
  }, []);

  function arredondaValor(valor: string) {
    return Math.ceil((Number(valor) + 10) * 1.04);
  }

  async function validarPropostaVersao() {
    const response = await api.get<ResponseValidar>(
      `orcamento/list-by-proposta/${configuracaoId}`,
    );

    return response.data;
  }

  async function pegarProduto(configId: string) {
    const response = await api.get<ResponseData[]>(
      '/orcamento/questao-resposta-by-orcamentoid/',
      {
        params: { configId },
      },
    );

    return response.data;
  }

  async function handleValidarProposta() {
    setIsLoading(true);
    try {
      if (configuracaoId) {
        const registro = await validarPropostaVersao();

        setConfiguracaoIdValidada(configuracaoId);

        const { status } = registro.PropostaConfiguracao[0].propostaVersao;

        // valida status
        if (status.id !== STATUS_ID_GERADO) {
          Swal.fire({
            icon: 'error',
            title: 'Ops!',
            text: `O status atual dessa proposta [${status.descricao}] não permite modificação nessa tela. A proposta deve ter o status de gerada`,
          });
          return;
        }

        setStatusDesc(status.descricao);

        setProposta({
          propostaVersaoId: registro.PropostaConfiguracao[0].propostaVersao.id,
          propostaVersao:
            registro.PropostaConfiguracao[0].propostaVersao.versao,
          configId: registro.PropostaConfiguracao[0].orcamentoId,
          propostaId:
            registro.PropostaConfiguracao[0].propostaVersao.propostaId,
        });

        const produto = await pegarProduto(configuracaoId);
        const produtoFiltered = produto.filter(
          item => item.indicManutencaoAdm === true,
        );

        const produtoAplicadaFormula = produtoFiltered.map(prod => ({
          ...prod,
          OrcamentoResposta: [
            {
              ...prod.OrcamentoResposta[0],
              resposta: prod.indicCalculoImpressao
                ? String(arredondaValor(prod.OrcamentoResposta[0].resposta))
                : String(prod.OrcamentoResposta[0].resposta),
            },
          ],
        }));

        setQuestoesIndManuAdm(produtoAplicadaFormula);

        setPropostaValidaManutencao(true);
        setFormModalState(false);
      }
    } catch (error: any) {
      setQuestoesIndManuAdm([]);
      setHabilitarSalvar(false);
      setConfiguracaoId('');
      setConfiguracaoIdValidada('');
      setPropostaValidaManutencao(false);
    } finally {
      setIsLoading(false);
    }
  }

  const handleInputChange = (index: number, campo: string, valor: string) => {
    setHabilitarSalvar(false);

    if (!questoesIndManuAdm) {
      console.log('questoesIndManuAdm é undefined');
      return;
    }
    const updatedQuestoes: ResponseData[] = [...questoesIndManuAdm];
    if (campo === 'Valor') {
      updatedQuestoes[index].OrcamentoResposta[0].resposta = valor;
      updatedQuestoes[index].mudou = true;
    }
    setQuestoesIndManuAdm(updatedQuestoes);
  };

  async function atualizarQuestao() {
    if (!questoesIndManuAdm) {
      console.log('questoesIndManuAdm é undefined');
      return;
    }
    const newQuestoes = questoesIndManuAdm.map(questao => {
      return {
        resposta: questao.OrcamentoResposta[0].resposta,
        orcamentoRespostaId: questao.OrcamentoResposta[0].id,
        propostaId: proposta.propostaId,
        questaoId: questao.id,
        questaoDesc:
          questao.PropostaAtividadeQuestao[0]?.descricao ?? questao.id,
        mudou: questao.mudou,
        questaoindicCalculoImpressao: questao.indicCalculoImpressao,
      };
    });

    const resp = await api.put(`/orcamento/orcamento-resposta`, {
      questoes: newQuestoes,
      configId: configuracaoIdValidada,
      propostaVersaoId: proposta.propostaVersaoId,
    });
  }

  function salvar() {
    Swal.fire({
      title: `Alterar Resposta(s)?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Atualizando respostas`,
          icon: 'info',
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        await atualizarQuestao();
        if (questoesIndManuAdm)
          setQuestoesIndManuAdm(
            questoesIndManuAdm.map(questao => {
              return {
                ...questao,
                mudou: false,
              };
            }),
          );
        setHabilitarSalvar(true);
        toast.success('Respostas Atualizadas.');
        Swal.close();
      }
    });
  }

  async function openModalProposta(clearAll: boolean) {
    if (clearAll) {
      setPropostaManutencao('');
      setPropostaVersaoManutencao('');
      setProposta({});
    }
    setFormModalState(!formModalState);
  }

  return (
    <>
      <UserSmallHeader />
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader>
              <h2 className="text-center">
                Configuração para ajuste de valores
              </h2>
              <small>Informe a configuração.</small>
              <br />
              <small>
                OBS: Apenas propostas <u>Geradas</u> poderão ser modificadas
              </small>
            </CardHeader>
            <CardBody>
              {propostaValidaManutencao && (
                <Alert color="info">
                  <FaInfoCircle /> Alterações realizadas serão perdidas se
                  clicar em <strong>Iniciar Edição</strong>
                </Alert>
              )}
              <Row>
                <Col>
                  <Input
                    placeholder="Configuração"
                    type="text"
                    value={configuracaoId}
                    onChange={text => setConfiguracaoId(text.target.value)}
                    className="form-control-sm"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="default"
                      type="button"
                      disabled={isLoading}
                      onClick={() => {
                        setFormModalState(!formModalState);
                      }}
                    >
                      Fechar
                    </Button>

                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      disabled={isLoading}
                      onClick={handleValidarProposta}
                    >
                      Iniciar Edição
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Modal>

      <Card>
        <CardHeader>
          <Row>
            <Col sm="6">
              <h5 className="h3 mb-0">Variáveis FT</h5>
              <small>Ajuste de valores para propostas em estágio Gerada</small>
            </Col>
            <Col sm="6">
              <div className="float-right">
                <Button
                  className="my-4"
                  color="primary"
                  size="sm"
                  type="button"
                  title="Trocar Proposta"
                  onClick={() => openModalProposta(false)}
                >
                  <FaUndo /> Trocar Proposta
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="p-0 pr-1 pl-1" style={{ background: '#ebf3fa' }}>
          {!propostaValidaManutencao && (
            <>
              <div className="ml-2">
                Para abrir a modal e informar o registro que deseja dar
                manutenção{' '}
                <Button
                  className="my-4"
                  color="primary"
                  size="sm"
                  type="button"
                  onClick={() => openModalProposta(true)}
                >
                  Clique aqui
                </Button>
              </div>
            </>
          )}
          {propostaValidaManutencao && (
            <>
              <CardHeader className="mt-2">
                <Row>
                  <Col md="4" sm="4" xs="12" lg="4">
                    <h4 className="mb-0">Proposta {proposta.propostaId}</h4>{' '}
                    <small className="mb-0">
                      Versão {proposta.propostaVersao} | Configuração{' '}
                      {proposta.configId}
                    </small>
                  </Col>
                  <Col md="3" sm="3" xs="12" lg="2">
                    <div className="d-flex mt-2">
                      {statusDesc.toUpperCase()}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="mt-2">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Resposta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questoesIndManuAdm?.map((questao, index: number) => (
                      <tr key={questao.id}>
                        <td>
                          {questao.descricao} (
                          <i>
                            {questao.PropostaAtividadeQuestao[0]?.descricao}
                          </i>
                          )
                        </td>
                        <td>
                          <InputGroup className="input-group-alternative w-50">
                            <Input
                              type="number"
                              value={questao.OrcamentoResposta[0]?.resposta}
                              onChange={e =>
                                handleInputChange(
                                  index,
                                  'Valor',
                                  e.target.value,
                                )
                              }
                            />
                            <InputGroupAddon addonType="append">
                              {questao.unidadeMedida}
                            </InputGroupAddon>
                          </InputGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={salvar}
                  disabled={habilitarSalvar}
                >
                  Salvar
                </Button>
              </CardBody>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}

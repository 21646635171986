import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM, { render } from 'react-dom';
import {
  CardHeader,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Table,
} from 'reactstrap';
import { FaArrowCircleRight, FaTrash, FaInfoCircle } from 'react-icons/fa';

import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import { PropostaMaterial } from 'models/PropostaMaterial';
import toast from 'react-hot-toast';
import api from 'services/api';

import Orcamento from 'models/Orcamento';
import { useQuery } from 'react-query';
import { CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { PropostaContext } from 'views/pages/Proposta';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import InputSelect from 'components/InputSelect';
import { Th, Td } from './styles';
import MaterialItem from '../MaterialItem';
import MaterialTotalItem from '../MaterialTotalItem';
import { MateriaisContext } from '../..';

interface ConfiguracaoMaterial {
  registros: {
    atividade: number;
    materiais: PropostaMaterial[];
    total: number;
  }[];
  total: number;
}

const MySwal = withReactContent(Swal);

export default function PropostaConfiguracaoMaterial({
  propostaConfiguracao,
  atualizar,
}: {
  propostaConfiguracao: PropostaConfiguracao;
  atualizar: () => void;
}): JSX.Element {
  const { loadingMateriais, setLoadingMateriais } =
    useContext(MateriaisContext);
  const {
    propostaConfiguracoes,
    setPropostaConfiguracoes,
    permiteModificacao,
    setTriggerMaterialCheck,
    acessoLimitado,
  } = useContext(PropostaContext);

  const [materiais, setMateriais] = useState<ConfiguracaoMaterial>();
  const [configuracao, setConfiguracao] = useState<Orcamento>();
  const registros = materiais?.registros;
  const valorTotalConfiguracao = materiais?.total || 0;

  const pivoCentral = propostaConfiguracao.fichaTecnica?.pivoCentral || '-';
  const [deletingMaterial, setDeletingMaterial] = useState(false);
  const [deletingConfiguracao, setDeletingConfiguracao] = useState(false);
  const [reprocessando, setReprocessando] = useState(false);

  const [tiposExclusao, setTiposExclusao] = useState([]);

  const [acessoLimitadoListaMateriais, setAcessoLimitadoListaMateriais] =
    useState(true);
  async function retornaPropostaConfiguracaoMateriais(): Promise<ConfiguracaoMaterial> {
    const response = await api.get(
      `/proposta/configuracao/${propostaConfiguracao.id}/materiais`,
    );
    return response.data;
  }

  const { data: dataMateriais, isLoading: isLoadingMateriais } = useQuery(
    `proposta-configuracao-materiais-${propostaConfiguracao.id}`,
    async () => {
      const materiaisResponse = await retornaPropostaConfiguracaoMateriais();
      return materiaisResponse;
    },
  );

  const { data: dataConfiguracao, isLoading: isLoadingConfiguracao } = useQuery(
    `configuracao-${propostaConfiguracao.orcamentoId}`,
    async () => {
      async function carregaConfiguracao(): Promise<Orcamento> {
        const response = await api.get(
          `/orcamento/${propostaConfiguracao.orcamentoId}`,
        );
        return response.data;
      }

      const dataConfiguracaoResponse = await carregaConfiguracao();
      return dataConfiguracaoResponse;
    },
  );

  useEffect(() => {
    setLoadingMateriais(isLoadingMateriais);
  }, [isLoadingMateriais]);

  useEffect(() => {
    if (dataMateriais) {
      setMateriais(dataMateriais);
    }
  }, [dataMateriais]);

  useEffect(() => {
    if (dataConfiguracao) {
      setConfiguracao(dataConfiguracao);
    }
  }, [dataConfiguracao]);

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 60,
      },
    });
    setAcessoLimitadoListaMateriais(response.data);
  }

  useEffect(() => {
    getAcessoLimitadoPrograma();
  }, []);

  async function getTiposExclusao() {
    const response = await api.get(`/tipo-exclusao/tipos`);
    setTiposExclusao(response.data);
  }

  useEffect(() => {
    getTiposExclusao();
  }, []);

  async function handleAtualizaMateriais() {
    try {
      setLoadingMateriais(true);
      const materiaisResponse = await retornaPropostaConfiguracaoMateriais();
      setMateriais(materiaisResponse);
      toast.success('Feito!');
    } catch (error) {
      toast.dismiss();
      toast.error('Ops! Algo de errado aconteceu!');
    } finally {
      setLoadingMateriais(false);
    }
  }

  /* async function handleReprocessaPropostaConfiguracao() {
    try {
      setReprocessando(true);
      const reprocessandoId = toast.loading(
        `Calculando precificação da configuração ${propostaConfiguracao.orcamentoId}...`,
        { position: 'top-right' },
      );
      const response = await api.post(
        `/proposta/configuracao/${propostaConfiguracao.id}/reprocessar`,
      );
      toast.success('Feito!', {
        id: reprocessandoId,
      });
    } catch (error) {
      toast.dismiss();
      toast.error('Ops! Algo de errado aconteceu!');
    } finally {
      await handleAtualizaMateriais();
      setReprocessando(false);
      setTriggerMaterialCheck(true);
    }
  } */

  useEffect(() => {
    if (deletingMaterial) {
      toast.loading(
        `Excluindo item da configuração ${propostaConfiguracao.orcamentoId}...`,
        { position: 'top-right' },
      );
      return;
    }

    toast.dismiss();
  }, [deletingMaterial]);

  async function handleDeletePropostaConfiguracaoMaterial(
    propostaConfiguracaoMaterial: PropostaMaterial,
  ) {
    async function deletePropostaConfiguracaoMaterial(
      tipoExclusaoId: string,
      motivo: string,
    ) {
      try {
        setDeletingMaterial(true);

        await api.post('tipo-exclusao/salvar-excluir-materiais-proposta', {
          orcamentoId: propostaConfiguracao.orcamentoId,
          propostaConfiguracaoId: propostaConfiguracao.id,
          idMaterial: propostaConfiguracaoMaterial.id,
          motivo,
          tipoExclusao: tipoExclusaoId,
        });

        setDeletingMaterial(false);
        setLoadingMateriais(true);
        const materiaisResponse = await retornaPropostaConfiguracaoMateriais();
        setMateriais(materiaisResponse);
      } finally {
        setDeletingMaterial(false);
        setLoadingMateriais(false);
        setTriggerMaterialCheck(true);
      }
    }

    const { diteCodigo, descricao } = propostaConfiguracaoMaterial.material;

    async function openModal() {
      const wrapper = document.createElement('div');

      Swal.fire({
        title: `Excluir item da configuração ${propostaConfiguracao.orcamentoId}?`,
        html: wrapper,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, excluir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
        preConfirm: () => {
          const motivoInput = document.querySelector(
            '#motivo-input',
          ) as HTMLInputElement;

          const selectedTipo = document.querySelector(
            '#tipos_exclusao',
          ) as HTMLSelectElement;

          const motivo = motivoInput?.value;
          const tipo = selectedTipo?.value;

          if (!tipo || tipo.trim() === '') {
            Swal.showValidationMessage('Selecione um tipo de exclusão válido.');
            return false;
          }

          return { motivo, tipo };
        },
      }).then(result => {
        if (result.isConfirmed && result.value) {
          const { motivo, tipo } = result.value;
          deletePropostaConfiguracaoMaterial(tipo, motivo);
        }
      });

      const selectOptions = tiposExclusao
        .filter((tipo: any) => tipo.indicTipoExclusao)
        .map((tipo: any) => ({
          value: tipo.id,
          name: tipo.descricao,
        }));

      ReactDOM.render(
        <div>
          <strong>Selecione o motivo da exclusão*:</strong>
          <InputSelect id="tipos_exclusao" options={selectOptions} />
          <br />
          <strong>Complemento da exclusão do material:</strong>
          <input
            id="motivo-input"
            className="swal2-input"
            placeholder="Complemento da exclusão"
          />
        </div>,
        wrapper,
      );
    }

    openModal();
  }

  async function handleDeletePropostaConfiguracao(
    propostaConfiguracaoHandle: PropostaConfiguracao,
  ) {
    async function deletePropostaConfiguracao() {
      try {
        setDeletingConfiguracao(true);

        Swal.fire({
          icon: 'info',
          text: `Excluindo a configuração desta versão`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });

        await api.delete(
          `/proposta/configuracao/${propostaConfiguracaoHandle.id}`,
        );
        setDeletingConfiguracao(false);

        setPropostaConfiguracoes(
          propostaConfiguracoes.filter(
            item => item.id !== propostaConfiguracaoHandle.id,
          ),
        );

        // Dispara o processo para atualizar a lista de configurações
        atualizar();

        toast.success('Feito!', {});
        Swal.close();
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text:
            error?.response?.data?.message ??
            'Não foi possível prosseguir com a exclusão',
        });
      } finally {
        setDeletingConfiguracao(false);
        setTriggerMaterialCheck(true);
      }
    }

    Swal.fire({
      title: `Excluir a configuração ${propostaConfiguracaoHandle.orcamentoId} desta versão da proposta?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, excluir`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(result => {
      if (result.isConfirmed) {
        deletePropostaConfiguracao();
      }
    });
  }

  function BodyMateriais() {
    if (loadingMateriais) {
      return (
        <tr>
          <h5 className="m-3">
            Carregando... <CircularProgress size={14} className="ml-3" />
          </h5>
        </tr>
      );
    }

    if (!registros?.length) {
      return (
        <tr>
          <Td className="text-center" colSpan={5}>
            Nenhum registro encontrado
          </Td>
        </tr>
      );
    }

    return (
      <>
        {registros.map(configMaterial => {
          const {
            atividade,
            materiais: configMateriais,
            total,
          } = configMaterial;
          return (
            <>
              {configMateriais.map(propostaMaterial => (
                <MaterialItem
                  key={propostaMaterial.id}
                  propostaMaterial={propostaMaterial}
                  canDelete={permiteModificacao}
                  disabled={deletingMaterial}
                  onClickDelete={() =>
                    handleDeletePropostaConfiguracaoMaterial(propostaMaterial)
                  }
                  acessoLimitadoListaMateriais={acessoLimitadoListaMateriais}
                  acessoLimitado={acessoLimitado}
                />
              ))}
              <MaterialTotalItem
                texto={`Total atividade ${atividade || ''}`}
                valor={total}
                acessoLimitadoListaMateriais={acessoLimitadoListaMateriais}
              />
            </>
          );
        })}
      </>
    );
  }

  return (
    <>
      <CardHeader>
        <Row>
          <Col sm="7">
            <h4 className="mb--1">Pivô central: {pivoCentral}</h4>
            <small className="text-muted">
              Configuração {propostaConfiguracao.orcamentoId}
            </small>
            {isLoadingConfiguracao ? (
              <CircularProgress size={12} className="ml-2" />
            ) : (
              configuracao && (
                <Link
                  className="ml-2"
                  to={{
                    pathname: `/admin/cadastro-orcamento-novo`,
                    state: {
                      orcamento: configuracao,
                    },
                  }}
                >
                  <FaArrowCircleRight size={15} />
                </Link>
              )
            )}
          </Col>
          <Col sm="5">
            {permiteModificacao && (
              <Button
                className="btn-icon btn-2 float-right"
                color="danger"
                outline
                type="button"
                size="sm"
                title="Remover configuração"
                disabled={deletingConfiguracao || reprocessando}
                onClick={() =>
                  handleDeletePropostaConfiguracao(propostaConfiguracao)
                }
              >
                <FaTrash />
              </Button>
            )}

            {/*
            {permiteModificacao && (
              <Button
                className="btn-icon btn-2 float-right"
                color="warning"
                outline
                type="button"
                size="sm"
                style={{ marginRight: '0.8rem' }}
                disabled={deletingMaterial || reprocessando}
                onClick={handleReprocessaPropostaConfiguracao}
              >
                Atualizar Preço
              </Button>
            )}
            */}
          </Col>
        </Row>
      </CardHeader>

      <Card>
        <CardBody className="p-1">
          <Table bordered responsive>
            <thead>
              <tr>
                <Th>Ativ</Th>
                <Th>Item</Th>
                <Th className="text-center">Qtd</Th>
                {!acessoLimitadoListaMateriais && (
                  <>
                    <Th className="text-right">Valor unitário</Th>
                    <Th className="text-right">Valor total</Th>
                    {permiteModificacao && <Th />}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <BodyMateriais />
            </tbody>
            <tfoot>
              <MaterialTotalItem
                texto="Total materiais"
                valor={valorTotalConfiguracao}
                elementType="h2"
                acessoLimitadoListaMateriais={acessoLimitadoListaMateriais}
              />
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </>
  );
}

import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import Select from 'react-select';
// core components
import Header from 'components/Headers/Header.js';

import {
  FaCheck,
  FaEdit,
  FaPlusCircle,
  FaTimesCircle,
  FaExclamationCircle,
} from 'react-icons/fa';

import api from 'services/api';

import ProdutoModel from 'models/Produto';
import ModeloModel from '../../../models/ConfiguradorModelo';
import LanceModel from '../../../models/ConfiguradorLance';

const ConfiguradorModelos: React.FC = () => {
  const [modelos, setModelos] = useState([] as ModeloModel[]);
  const [ModeloEditing, setModeloEditing] = useState({} as ModeloModel);

  const [formModalState, setFormModalState] = useState(false);
  const [formModalModelo, setFormModalModelo] = useState(false);
  const [lances, setLances] = useState([] as LanceModel[]);
  const [lancesModelos, setLancesModelos] = useState([] as LanceModel[]);
  const [lancesEditing, setLancesEditing] = useState({} as LanceModel);
  const [newLance, setNewLance] = useState(false);
  const [produtoFiltrar, setProdutoFiltrar] = useState(0);
  const [listaProduto, setListaProduto] = useState([] as ProdutoModel[]);
  const [produtoSelecionado, setProdutoSelecionado] = useState<any>([] as any);

  async function listaModelos() {
    const response = await api.get('/integracao/modelos');
    setModelos(response.data);
  }

  async function listaLances() {
    const response = await api.get('/integracao/lances/tubos');

    setLances(response.data);
  }

  async function carregarProdutos() {
    const response = await api.get('/produto');

    setListaProduto(response.data);
  }

  async function listaLancesModelos() {
    const response = await api.get(
      `/integracao/lances/tubos/${ModeloEditing.id}`,
    );
    setLancesModelos(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    listaModelos();
    listaLances();
    carregarProdutos();
    if (ModeloEditing.id) {
      listaLancesModelos();
    }
  }, [ModeloEditing.id]);

  async function handleDelete(modeloId: number) {
    await api.delete(`/integracao/modelos/${modeloId}`);

    listaModelos();
  }

  async function handleInsert() {
    setModeloEditing({} as ModeloModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(Modelo: ModeloModel) {
    setModeloEditing(Modelo);
    setFormModalState(!formModalState);
  }

  async function handleUpdateLance(Modelo: ModeloModel) {
    const response = await api.get(`/integracao/lances/tubos/${Modelo.id}`);
    setModeloEditing(Modelo);
    setLancesEditing(response.data);
    setLancesModelos(response.data);
    setFormModalModelo(!formModalModelo);
  }

  async function handleAdicionaLance(modelo: ModeloModel) {
    try {
      if (!lancesEditing.id) {
        toast.error('O lance deve ser selecionado');
        return;
      }

      if (!produtoSelecionado || produtoSelecionado.length === 0) {
        toast.error('Informe os produtos');
        return;
      }

      await api.post(`/integracao/declividades`, {
        idLanceTubo: lancesEditing.id,
        idModelo: modelo.id,
        produtos: produtoSelecionado.map((item: any) => item.value),
        declividadeLimite: lancesEditing.declividadeLimite,
      });
    } finally {
      setNewLance(false);
    }

    listaLancesModelos();
  }

  async function handleDeleteLance(lance: any) {
    Swal.fire({
      title: `Deseja excluir o Lance?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(
          `/integracao/lances/tubos/${ModeloEditing.id}/modelos/${lance.id}`,
          {},
        );
        Swal.close();
        toast.success('Registro excluído');
        listaLances();
        listaLancesModelos();
      }
    });
  }

  async function handleSaveModelo() {
    // eslint-disable-next-line prettier/prettier
    const { nome, sigla, patente } = ModeloEditing;

    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    if (ModeloEditing.id) {
      await api.put(`/integracao/modelos/${ModeloEditing.id}`, {
        nome,
        sigla,
        patente,
      });
    } else {
      await api.post(`/integracao/modelos`, {
        nome,
        sigla,
        patente,
      });
      setNewLance(true);
    }

    toast.success('Registro concluído');
    setFormModalState(false);
    setModeloEditing({} as ModeloModel);
    listaModelos();
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Modelo {ModeloEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <small>Nome do Modelo *</small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nome do Modelo"
                            value={ModeloEditing.nome}
                            type="text"
                            onChange={text =>
                              setModeloEditing({
                                ...ModeloEditing,
                                nome: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <small>Sigla </small>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Sigla"
                            value={ModeloEditing.sigla}
                            type="text"
                            onChange={text =>
                              setModeloEditing({
                                ...ModeloEditing,
                                sigla: text.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <div>
                          {/* Small text with a tooltip */}
                          <small>Informações Adicionais </small>
                          <span
                            id="tooltipExclamacao"
                            style={{ cursor: 'pointer', color: 'blue' }}
                          >
                            <FaExclamationCircle />
                          </span>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="tooltipExclamacao"
                          >
                            Se informado, aparece na impressão da proposta.
                          </UncontrolledTooltip>
                        </div>

                        <Input
                          placeholder="Informações Adicionais"
                          value={ModeloEditing.patente}
                          style={{ height: '150px', resize: 'none' }}
                          type="textarea"
                          maxLength={300}
                          onChange={text =>
                            setModeloEditing({
                              ...ModeloEditing,
                              patente: text.target.value,
                            })
                          }
                        />
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={handleSaveModelo}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalModelo}
              toggle={() => setFormModalModelo(!formModalModelo)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Modelo {ModeloEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <Row>
                        <FormGroup className="col-sm-12 col-xl-6">
                          <small>Lista de Lances</small>
                          <InputGroup className="input-group-alternative input-group">
                            <Input
                              placeholder="Grupo UC"
                              type="select"
                              value={lancesEditing.id || ''}
                              onChange={event =>
                                setLancesEditing({
                                  ...lancesEditing,
                                  id: Number(event.target.value),
                                })
                              }
                            >
                              <option value="">Selecione...</option>
                              {lances
                                .filter(
                                  item => item.idModelo === ModeloEditing.id,
                                )
                                .map(lance => (
                                  <option value={lance.id}>{lance.nome}</option>
                                ))}
                            </Input>
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="col-sm-12 col-xl-6">
                          <small>Declividade Limite</small>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-tag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Declividade Limite"
                              value={lancesEditing.declividadeLimite}
                              type="number"
                              onChange={text =>
                                setLancesEditing({
                                  ...lancesEditing,
                                  declividadeLimite: Number(text.target.value),
                                })
                              }
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup className="col-sm-12">
                          <small>Produtos:</small>
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Selecione os Produtos"
                            isMulti
                            options={listaProduto.map((item: ProdutoModel) => {
                              return { label: item.nome, value: item.id };
                            })}
                            value={produtoSelecionado}
                            onChange={(e: any) => {
                              setProdutoSelecionado(e);
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="col-sm-12 col-xl-6">
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="primary"
                            type="button"
                            onClick={() => {
                              handleAdicionaLance(ModeloEditing);
                            }}
                          >
                            <FaCheck /> Salvar
                          </Button>
                        </FormGroup>
                      </Row>

                      <FormGroup>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th>
                                Lances Associados ao modelo {ModeloEditing.nome}
                              </th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lancesModelos.map(lance => (
                              <tr key={lance.id}>
                                <td className="py-2">{lance.nome}</td>
                                <td className="py-2">
                                  {lance.declividadeLimite}
                                </td>
                                <td className="py-2">
                                  <Button
                                    size="sm"
                                    className="btn-icon float-right"
                                    color="danger"
                                    type="button"
                                    onClick={() => {
                                      handleDeleteLance(lance);
                                    }}
                                  >
                                    <FaTimesCircle />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de Modelos</h3>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                    {modelos.map(modelo => (
                      <tr key={modelo.id}>
                        <td className="col">
                          <strong>{modelo.nome}</strong>
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="default"
                            title="Associar Lances"
                            type="button"
                            onClick={() => {
                              handleUpdateLance(modelo);
                            }}
                            hidden
                          >
                            Lances
                          </Button>
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="warning"
                            type="button"
                            onClick={() => {
                              handleUpdate(modelo);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="btn-icon"
                            color="danger"
                            type="button"
                            onClick={() => {
                              handleDelete(modelo.id);
                            }}
                          >
                            <FaTimesCircle />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorModelos;

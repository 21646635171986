import { Select, MenuItem } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import {
  FaCheckCircle,
  FaInfoCircle,
  FaPlusCircle,
  FaTrash,
  FaExclamationTriangle,
  FaBuffer,
} from 'react-icons/fa';
import { Button, Table, Col, Row, Card } from 'reactstrap';
import api from 'services/api';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import GenericModal from 'components/GenericModal';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import { FichaTecnicaTubulacao } from 'models/FichaTecnicaTubulacao';
import { CalculoAdutora } from 'models/AdutoraCalculo';
import { DesnivelTubos } from 'models/DesnivelTubos';
import TipoBombaData from 'staticData/TipoBombaData';
import { FichaTecnicaBombeamento } from 'models/FichaTecnicaBombeamento';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  ContainerButtons,
  Td,
  TextField,
  Tr,
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from './styles';
import Reprocessamento from '../Reprocessamento';
import { AdutoraBombeamentoNewContext } from '../..';
import './style.css';

declare let window: any;

interface AdutoraTubulacao {
  seq: number;
  nome: string;
  dn: number | null;
  de: number | null;
  e: number | null;
  di: number | null;
  coeficiente: number | null;
  pn: number | null;
  velocidadeMaximaAgua: number | null;
}

interface AdutoraTubulacao {
  seq: number;
  nome: string;
  dn: number | null;
  de: number | null;
  e: number | null;
  di: number | null;
  coeficiente: number | null;
  pn: number | null;
  velocidadeMaximaAgua: number | null;
}

interface SegTrechos {
  distAcc: number;
  totalAcc: number;
  cotaIni: number;
  cotaFin: number;
  classePN: string;
  pFinal: number;
  diametro: number;
  menorElev: number;
  mED: number;
}

interface ListaTrechosPn {
  distancia: number;
  elevacao: number;
  hf: number;
  pFin: number;
  pn: number;
  di: number;
}

interface AdutoraTubulacaoOrcamento extends CalculoAdutora {
  tubulacaoAdutoraId: number;
  orcamentoId: number;
  sessao: string;
  ordem: number;
  cotaInicial: number | null;
  cotaFinal: number | null;
  alterado?: boolean;
  novo?: boolean;
  velocidadeErro?: boolean;
}

interface AdutoraTubulacao {
  seq: number;
  nome: string;
  dn: number | null;
  de: number | null;
  e: number | null;
  di: number | null;
  coeficiente: number | null;
  pn: number | null;
  velocidadeMaximaAgua: number | null;
}

interface iProps {
  isBalsa: boolean;
}

function Tubulacao({ isBalsa }: iProps): JSX.Element {
  const {
    fichaTecnicaId,
    sessaoId,
    orcamentoId,
    necessidadeReprocessamento,
    fichaTecnica,
    orcamentoInfoBalsa,
    fichaTecnicaBombeamento,
    fichaTecnicaAdutora,
    respostaFuncaoEntrada,
    parametros,
    dadosElevacao,
    fichaTecnicaTubulacao: ftFichaTecnicaTubulacao,
    atualizarFuncoes,
    setNecessidadeReprocessamento,
  } = useContext(AdutoraBombeamentoNewContext);

  window.google.load('visualization', '1', {
    packages: ['columnchart', 'corechart', 'table'],
  });

  const [
    isNecessidadeReprocessamentoTrechos,
    setIsNecessidadeReprocessamentoTrechos,
  ] = useState(false);
  const [
    isNecessidadeReprocessamentoAdutora,
    setIsNecessidadeReprocessamentoAdutora,
  ] = useState(false);

  const [tubos, setTubos] = useState<AdutoraTubulacao[]>([]);
  const [loading, setLoading] = useState(false);
  const [adicionando, setAdicionando] = useState(false);
  const [modalStatusVisivel, setModalStatusVisivel] = useState(false);
  const [modalTitulo, setModalTitulo] = useState('');
  const [rowsModal, setRowsModal] = useState<string[]>([]);
  const [habilitaBotao, setHabilitaBotao] = useState(false);
  const [exibeTotais, setExibeTotais] = useState(false);
  const [exibeCotas, setExibeCotas] = useState(false);
  const [loadingCalculoAdutora, setLoadingCalculoAdutora] = useState(false);

  const [adutoraTotal, setAdutoraTotal] = useState(0);

  const [adutoraSaldo, setAdutoraSaldo] = useState(0);
  const [saldoAdutora, setSaldoAdutora] = useState(0);
  const [bomba, setBomba] = useState('');
  const [quantidadeBomba, setQuantidadeBomba] = useState(0);
  const [quantidadeVentosa, setQuantidadeVentosa] = useState(0);
  const [quantidadeModulos, setQuantidadeModulos] = useState(1);
  const [bombeamentoMestre, setBombeamentoMestre] = useState('');
  const [perdaCarga, setPerdaCarga] = useState(0);
  const [tipoCota, setTipoCota] = useState('');
  const [indicCotaEspecifica, setIndicCotaEspecifica] = useState(false);
  const [tipoCotaParaTubulacao, setTipoCotaParaTubulacao] = useState('');
  const [habilitarTrechos, setHabilitarTrechos] = useState(false);
  const [criarPrimeiroTrecho, setCriarPrimeiroTrecho] = useState(false);

  const [iniciarPEAD, setIniciarPEAD] = useState(false);
  const [usarDadosBalsa, setUsarDadosBalsa] = useState(false);

  const [sugestoes, setSugestoes] = useState<any[]>([]);
  const [mostrarBlocoSugestoes, setMostrarBlocoSugestoes] = useState(false);
  const [calculandoTrechos, setCalculandoTrechos] = useState(false);
  const [inserindoTrechos, setInserindoTrechos] = useState(false);
  const [listaTrechosPn, setListaTrechosPn] = useState<ListaTrechosPn[]>([]);
  const [segTrechos, setSegTrechos] = useState<SegTrechos[]>([]);
  const [ajustarCotaOrdem, setAjustarCotaOrdem] = useState(999);

  const headers = [
    'Trecho',
    'Comprimento',
    'Diâmetro (mm)',
    'Material',
    'Coef.',
    'Vazão m³/h',
    'HF Total (mca)',
    'Velocidade',
    indicCotaEspecifica ? 'Cota inicial' : 'hide',
    indicCotaEspecifica ? 'Cota específica' : 'hide',
    '',
  ];

  const [desnivelTubos, setDesnivelTubos] = useState<DesnivelTubos[]>([]);
  const [fichaTecnicaTubulacao, setFichaTecnicaTubulacao] =
    useState<FichaTecnicaTubulacao>();
  const [adutoraTubulacaoTrechos, setAdutoraTubulacaoTrechos] = useState<any[]>(
    [],
  );
  const [variacaoNivelAgua, setVariacaoNivelAgua] = useState(0);
  const [podeCalcularAdutora, setPodeCalcularAdutora] = useState(false);

  const [paramAdutoraCarregada, setParamAdutoraCarregada] = useState(false);
  const [parameRespostaCarregada, setParameRespostaCarregada] = useState(false);
  const [paramTubulacaoCarregada, setParamTubulacaoCarregada] = useState(false);
  const [dadosBaseCarregados, setDadosBaseCarregados] = useState(false);
  const [diametroLigacaoSugestao, setDiametroLigacaoSugestao] = useState(0);
  const [inserindoSugestao, setInserindoSugestao] = useState(false);
  const [tipoBomba, setTipoBomba] = useState('Normal');
  const PI = 3.14159265;
  const [vazaoAplicar, setVazaoAplicar] = useState(0);

  const [ftAdutora, setFtAdutora] = useState<any>({});

  const [ftBombeamento, setFtBombeamento] = useState<FichaTecnicaBombeamento[]>(
    [],
  );

  async function carregaDesniveisTubos() {
    const response = await api.get('/integracao/adutora/tubos/desnivel');

    return response.data;
  }

  async function carregaTubos() {
    const response = await api.get('/integracao/adutora/tubos');

    return response.data;
  }

  async function carregarTrechos() {
    const response = await api.get('/integracao/adutora/orcamento', {
      params: {
        sessao: sessaoId,
        fichaTecnicaId,
        orcamentoId,
      },
    });

    const retorno = response.data;

    return retorno?.filter((item: any) => item.tubulacaoSuccao === isBalsa);
  }

  async function carregaFichaTecnicaAdutora() {
    if (fichaTecnicaId) {
      const response = await api.get(
        `/ficha-tecnica-adutora/ficha-tecnica/${fichaTecnicaId}`,
      );

      setFtAdutora(response.data);
    }
  }

  async function carregaFichaTecnicaBombeamento() {
    const response = await api.get(
      `/integracao/bombeamento/calculo/${fichaTecnicaId}`,
    );

    setFtBombeamento(response.data);
  }

  async function carregarRespostas() {
    setParameRespostaCarregada(false);
    if (respostaFuncaoEntrada) {
      const {
        variacaoNivelAgua: vNivelAgua,
        desnivelCaptacaoCentroPivo,
        succaoComprimentoCasaBombasPrincipal,
      } = respostaFuncaoEntrada;

      setVariacaoNivelAgua(vNivelAgua ?? 0);

      if (isBalsa) {
        setAdutoraTotal(succaoComprimentoCasaBombasPrincipal ?? 0);
      } else {
        setAdutoraTotal(fichaTecnica?.comprimentoCasaMaquinaCentro ?? 0);
      }

      if (!fichaTecnicaAdutora) {
        setPerdaCarga((desnivelCaptacaoCentroPivo ?? 0) > 0 ? 10 : 20);
        setExibeTotais(false);
      }

      setParameRespostaCarregada(true);
    }
  }

  async function getVazaoDivisao() {
    if (fichaTecnicaAdutora) {
      const { vazaoCAF, vazaoSAF, incluirAspersorFinal } = fichaTecnica;

      const usaAspersorFinal: boolean =
        String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
        String(incluirAspersorFinal).toUpperCase() === 'S';

      let vazao = usaAspersorFinal
        ? Number(vazaoCAF ?? 0)
        : Number(vazaoSAF ?? 0);

      // Usa a quantidade de bomba parametrizada
      let qtdBomba = fichaTecnicaAdutora.quantidadeBomba ?? 1;

      if (String(fichaTecnicaAdutora.bomba).toUpperCase() !== 'PARALELO') {
        // Nesse caso, a quantidade de bomba será 1
        qtdBomba = 1;
      }

      if (vazaoAplicar > 0) return vazaoAplicar;

      if (isBalsa) {
        const bombaUsar =
          fichaTecnicaBombeamento.filter(item => item.isBalsa === true)?.[0]
            ?.bomba ?? 'Normal';

        const ftBomb = fichaTecnicaBombeamento.filter(
          item => item.isBalsa === false,
        )?.[0];

        // Verifica se é Série
        if (
          fichaTecnicaAdutora.bomba === 'Série' &&
          (ftBomb?.quantidadeModulos ?? 1) > 1
        ) {
          qtdBomba = ftBomb?.quantidadeModulos;
          if (bombaUsar === 'Paralelo') {
            qtdBomba += 2;
          }
        } else if (
          fichaTecnicaAdutora.bomba === 'Série' &&
          (ftBomb?.quantidadeModulos ?? 1) === 1 &&
          bombaUsar === 'Paralelo'
        ) {
          qtdBomba = 2;
        } else if (
          bombaUsar === 'Paralelo' &&
          String(fichaTecnicaAdutora.bomba) === 'Paralelo'
        ) {
          qtdBomba = 4; // Paralelo em ambos os pontos. Divide por 4
        } else if (
          bombaUsar !== 'Paralelo' &&
          String(fichaTecnicaAdutora.bomba) === 'Paralelo'
        ) {
          qtdBomba = 2; // Paralelo apenas na adutora. Divite 1x
        } else if (
          bombaUsar === 'Paralelo' &&
          String(fichaTecnicaAdutora.bomba) === 'Normal'
        ) {
          qtdBomba = 2;
        }

        vazao /= qtdBomba;

        return arredondaDecimais(vazao, 2);
      }

      return arredondaDecimais(vazao, 2);
    }
    return 1;
  }

  function getSyncVazao() {
    if (fichaTecnicaAdutora) {
      const { vazaoCAF, vazaoSAF, incluirAspersorFinal } = fichaTecnica;

      const usaAspersorFinal: boolean =
        String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
        String(incluirAspersorFinal).toUpperCase() === 'S';

      let vazao = usaAspersorFinal
        ? Number(vazaoCAF ?? 0)
        : Number(vazaoSAF ?? 0);

      let qtdBomba = fichaTecnicaAdutora.quantidadeBomba ?? 1;

      if (String(fichaTecnicaAdutora.bomba).toUpperCase() !== 'PARALELO') {
        // Nesse caso, a quantidade de bomba será 1
        qtdBomba = 1;
      }

      if (vazaoAplicar > 0) return vazaoAplicar;

      if (isBalsa) {
        const bombaUsar =
          fichaTecnicaBombeamento.filter(item => item.isBalsa === true)?.[0]
            ?.bomba ?? 'Normal';

        const ftBomb = fichaTecnicaBombeamento.filter(
          item => item.isBalsa === false,
        )?.[0];

        // Verifica se é Série
        if (
          fichaTecnicaAdutora.bomba === 'Série' &&
          (ftBomb?.quantidadeModulos ?? 1) > 1
        ) {
          qtdBomba = ftBomb?.quantidadeModulos;
          if (bombaUsar === 'Paralelo') {
            qtdBomba += 2;
          }
        } else if (
          fichaTecnicaAdutora.bomba === 'Série' &&
          (ftBomb?.quantidadeModulos ?? 1) === 1 &&
          bombaUsar === 'Paralelo'
        ) {
          qtdBomba = 2;
        } else if (
          bombaUsar === 'Paralelo' &&
          String(fichaTecnicaAdutora.bomba) === 'Paralelo'
        ) {
          qtdBomba = 4; // Paralelo em ambos os pontos. Divide por 4
        } else if (
          bombaUsar !== 'Paralelo' &&
          String(fichaTecnicaAdutora.bomba) === 'Paralelo'
        ) {
          qtdBomba = 2; // Paralelo apenas na adutora. Divite 1x
        } else if (
          bombaUsar === 'Paralelo' &&
          String(fichaTecnicaAdutora.bomba) === 'Normal'
        ) {
          qtdBomba = 2;
        }
        /* if (
          fichaTecnicaBombeamento.filter(item => item.isBalsa === false)?.[0]
            ?.vazaoPrevista
        )
          vazao =
            fichaTecnicaBombeamento.filter(item => item.isBalsa === false)?.[0]
              ?.vazaoPrevista ?? 0; */

        vazao /= qtdBomba;

        return arredondaDecimais(vazao, 2);
      }

      return arredondaDecimais(vazao, 2);
    }
    return 1;
  }

  async function carregarAdutora() {
    setParamAdutoraCarregada(false);
    if (fichaTecnicaAdutora) {
      const cota = fichaTecnicaAdutora.tipoCota || '';

      setTipoCota(cota);

      if (isBalsa) {
        setIndicCotaEspecifica(false);
      } else {
        setIndicCotaEspecifica(
          cota.toUpperCase() === 'ESPECÍFICA' ||
            cota.toUpperCase() === 'ESPECIFICA',
        );
      }

      setTipoCotaParaTubulacao(fichaTecnicaAdutora.tipoCota || '');
      setBombeamentoMestre(fichaTecnicaAdutora.bombeamentoMestre || '');
      setPerdaCarga(fichaTecnicaAdutora.percentualPerdaCarga || 0);
      const varBomba = fichaTecnicaAdutora.bomba || '';

      const tipoFiltrado = TipoBombaData.find(tipo => tipo.tipo === varBomba);

      if (tipoFiltrado && tipoFiltrado?.valoresCombo.length > 0) {
        setQuantidadeBomba(fichaTecnicaAdutora.quantidadeBomba || 0);
      }

      if (isBalsa) {
        const bombaUsar =
          fichaTecnicaBombeamento.filter(item => item.isBalsa === true)?.[0]
            ?.bomba ?? 'Normal';

        setBomba(bombaUsar);
        setTipoBomba(bombaUsar);
      } else {
        setBomba(varBomba);
      }

      setParamAdutoraCarregada(true);
    } else {
      setHabilitarTrechos(false);
    }
  }

  async function carregarTubulacao() {
    setParamTubulacaoCarregada(false);
    if (ftFichaTecnicaTubulacao) {
      const isArray = Array.isArray(ftFichaTecnicaTubulacao);

      let diametroLigacao: number =
        ftFichaTecnicaTubulacao?.diametroLigacaoSugestao ??
        ftFichaTecnicaTubulacao?.diametroLigacao ??
        0;

      if (isBalsa) {
        diametroLigacao = ftFichaTecnicaTubulacao?.diametroLigacaoBalsa ?? 0;
      }

      if (isArray) {
        const fichaArr: any = ftFichaTecnicaTubulacao;

        if (isBalsa) {
          diametroLigacao = fichaArr?.[0]?.diametroLigacaoBalsa ?? 0;
        } else {
          diametroLigacao =
            fichaArr?.[0]?.diametroLigacaoSugestao ??
            fichaArr?.[0]?.diametroLigacao ??
            0;
        }
      }

      setDiametroLigacaoSugestao(diametroLigacao);

      setFichaTecnicaTubulacao(ftFichaTecnicaTubulacao);
      setParamTubulacaoCarregada(true);
    }
  }

  async function getQuantidadesPEAD(comprimento: number) {
    let quantidade6M = 0;
    let quantidade12M = 0;
    let flutuador = 0;

    if (comprimento <= 6) {
      quantidade6M = 1;
    } else {
      const divisor = 12;
      const quociente = Math.floor(comprimento / divisor);
      const resto = comprimento % divisor;

      quantidade12M = quociente;
      quantidade6M = resto !== 0 ? 1 : 0;
      flutuador = quantidade12M + quantidade6M - 1;
    }

    return {
      quantidade6M,
      quantidade12M,
      flutuador: flutuador === 0 ? 1 : flutuador,
    };
  }

  async function getCurrOrNextValue(array: DesnivelTubos[], diametro: number) {
    let returnObject = {} as DesnivelTubos;
    let continuar = true;

    const lista = array.sort((a, b) => a.dn - b.dn);

    // eslint-disable-next-line no-restricted-syntax
    for await (const item of lista) {
      if (continuar && item.dn >= diametro) {
        continuar = false;
        returnObject = item;
      }
    }
    return returnObject;
  }

  async function getComprimentoPEAD() {
    let comprimento = 0;
    let quantidade6M = 0;
    let quantidade12M = 0;
    let flutuador = 0;
    let diametro = 0;

    const isArray = Array.isArray(fichaTecnicaTubulacao);

    let diametroLigacao: number = fichaTecnicaTubulacao?.diametroLigacao ?? 0;

    if (isBalsa) {
      diametroLigacao = fichaTecnicaTubulacao?.diametroLigacaoBalsa ?? 0;
      // velocidadeLigacao = fichaArr?.[0]?.velocLigacao ?? 0;
    }

    // let velocidadeLigacao = fichaTecnicaTubulacao?.velocLigacao ?? 0;

    if (isArray) {
      const fichaArr: any = fichaTecnicaTubulacao;

      // por algum motivo, identificava como array
      if (isBalsa) {
        diametroLigacao = fichaArr?.[0]?.diametroLigacaoBalsa ?? 0;
        // velocidadeLigacao = fichaArr?.[0]?.velocLigacao ?? 0;
      } else {
        diametroLigacao = fichaArr?.[0]?.diametroLigacao ?? 0;
        // velocidadeLigacao = fichaArr?.[0]?.velocLigacao ?? 0;
      }
    }

    const listaTotal6 = desnivelTubos?.filter(
      (item: any) => item.metragem === 6,
    );
    const listaTotal12 = desnivelTubos?.filter(
      (item: any) => item.metragem === 12,
    );

    const lista6 = await getCurrOrNextValue(listaTotal6 ?? [], diametroLigacao);
    const lista12 = await getCurrOrNextValue(
      listaTotal12 ?? [],
      diametroLigacao,
    );

    if (variacaoNivelAgua <= (lista6?.desnivel ?? 0)) {
      comprimento = 6;
      quantidade6M = 1;
      diametro = lista6?.dn ?? 0;
    } else {
      const calculoPEAD = (lista12?.desnivel ?? 0) * variacaoNivelAgua;
      comprimento = Math.ceil(calculoPEAD / 6) * 6;

      const quantidades = await getQuantidadesPEAD(comprimento);

      quantidade12M = quantidades.quantidade12M;
      quantidade6M = quantidades.quantidade6M;
      flutuador = quantidades.flutuador;
      diametro = lista12?.dn ?? 0;
    }

    return {
      comprimento,
      quantidade6M,
      quantidade12M,
      flutuador,
      diametro,
    };
  }

  async function handleCheckPN(listaTrechos: any[]) {
    const invalidos = listaTrechos.filter(adutora => {
      const tuboFiltrado = tubos.find(
        tubo => String(tubo.nome) === String(adutora.material),
      );

      if (adutora.material.includes('PVC') && tuboFiltrado) {
        const cl = adutora.classe;
        const { pn } = tuboFiltrado;

        const clSemTexto = cl?.replace(/\D/g, '') ?? '';

        if (Number(clSemTexto) > Number(pn)) return true;
      }
      return false;
    });

    if (invalidos.length > 0) {
      Swal.fire({
        icon: 'error',
        text: `O ${invalidos[0].ordem}º trecho está com o PN do material inferior ao calculado!`,
      });
    }

    return invalidos.length === 0;
  }

  useEffect(() => {
    if (loading) {
      toast.loading('Aguarde...', { position: 'top-right' });
      return;
    }

    toast.dismiss();
  }, [loading]);

  async function fnAtualizarTrechos() {
    const listaTrechos = await carregarTrechos();
    const vazaoDiv = await getVazaoDivisao();

    let data = listaTrechos?.map((item: any) => {
      const tuboFiltrado = tubos.find(
        (tubo: any) => tubo.nome === item.material,
      );

      const velocidadeCalculada = arredondaDecimais(
        vazaoDiv /
          // eslint-disable-next-line no-restricted-properties
          (Math.pow(Number(tuboFiltrado?.di || 0) / 1000 / 2, 2) * PI * 3600),
      );

      return {
        ...item,
        vazao: vazaoDiv,
        tubulacaoAdutoraId: tuboFiltrado?.seq,
        velocidade: velocidadeCalculada,
        velocidadeErro:
          velocidadeCalculada > (tuboFiltrado?.velocidadeMaximaAgua ?? 0),
      };
    });

    data = data.filter((item: any) => item.tubulacaoSuccao === isBalsa);

    const comprimentoTotal =
      adutoraTotal -
      data
        ?.filter(
          (item: any) => !(item.ordem === 1 && item.material.includes('PEAD')),
        )
        .reduce((total: any, item: any) => total + (item?.comprimento || 0), 0);

    setAdutoraSaldo(comprimentoTotal || 0);

    let criarTrecho = false;

    if (!data || data.length === 0) criarTrecho = true;

    setHabilitarTrechos(true);

    if (data && data.length) {
      setAdutoraTubulacaoTrechos(data);
    } else {
      setAdutoraTubulacaoTrechos([]);
    }

    setCriarPrimeiroTrecho(criarTrecho);

    // atualizarFuncoes([`TRECHOS${isBalsa ? '_BALSA' : ''}`]);
  }

  async function atualizarNecessidadeReprocessamento() {
    if (fichaTecnicaId && necessidadeReprocessamento) {
      await setNecessidadeReprocessamento({
        reprocessarAdutora: necessidadeReprocessamento.reprocessarAdutora,
        reprocessarBombeamento:
          necessidadeReprocessamento.reprocessarBombeamento,
        reprocessarTrechos: !isBalsa
          ? true
          : necessidadeReprocessamento.reprocessarTrechos,
        reprocessarSuccao: necessidadeReprocessamento.reprocessarSuccao,
        reprocessarSuccaoBalsa:
          necessidadeReprocessamento.reprocessarSuccaoBalsa,
        reprocessarTrechosBalsa: isBalsa
          ? true
          : necessidadeReprocessamento.reprocessarTrechosBalsa,
        reprocessarBombeamentoBalsa:
          necessidadeReprocessamento.reprocessarBombeamentoBalsa,
      });

      setIsNecessidadeReprocessamentoTrechos(true);
    }
  }

  async function calculaSimplificado(ordem: number) {
    await api.post('/integracao/adutora/orcamento/calculo-simplificado', {
      sessao: sessaoId,
      ordem,
      fichaTecnicaId: fichaTecnica.id,
      orcamentoId,
      isBalsa,
    });
  }

  async function triggerSalvaTubulacao(
    isNew: boolean,
    tubulacao: AdutoraTubulacaoOrcamento,
  ) {
    const {
      ordem,
      material,
      comprimento,
      cotaFinal,
      cotaInicial,
      velocidade,
      diametro,
    } = tubulacao;

    if (comprimento && material && velocidade) {
      if (indicCotaEspecifica && (cotaFinal == null || cotaFinal < 0)) {
        toast.error(
          'Tipo cota Específica obriga informar uma cota específica!',
        );
        return;
      }

      if (ordem === 1)
        if (indicCotaEspecifica && (cotaInicial == null || cotaInicial < 0)) {
          toast.error(
            'Tipo cota Específica obriga informar a cota inicial no primeiro trecho',
          );
          return;
        }

      const tuboFiltrado = tubos.find(tubo => tubo.nome === material);

      if (Number(velocidade) > (tuboFiltrado?.velocidadeMaximaAgua ?? 0)) {
        toast.error(
          `A velocidade ${velocidade} nesse trecho extrapola o máximo permitido (${
            tuboFiltrado?.velocidadeMaximaAgua ?? 0
          })!`,
        );
        return;
      }
    }

    if (Number(adutoraSaldo) > Number(adutoraTotal)) {
      toast.error(
        `A soma dos comprimentos [${adutoraSaldo}] é maior do que o comprimento informado no questionário [${adutoraTotal}]`,
      );
      return;
    }

    let quantidadePEAD6M = 0;
    let quantidadePEAD12M = 0;
    let flutuador = 0;

    // Faz algumas validações específicas para PEAD
    if (String(material).toUpperCase().includes('PEAD') && iniciarPEAD) {
      const infoPead = await getComprimentoPEAD();

      // Confere se o comprimento é inferior ao calculado
      if (Number(comprimento ?? 0) < infoPead.comprimento) {
        toast.error(
          `O comprimento informado [${Number(
            comprimento ?? 0,
          )}] é inferior ao mínimo calculado para o PEAD [${
            infoPead.comprimento
          }]`,
        );
        return;
      }

      // Confere se o comprimento é um múltiplo de 6

      const resto = Number(comprimento ?? 0) % 6;

      if (resto !== 0) {
        toast.error(
          `O comprimento informado [${Number(
            comprimento ?? 0,
          )}] no primeiro trecho de PEAD precisa ser múltiplo de 6`,
        );
        return;
      }

      // A validação deu certo. Agora, busco as quantidades de PEAD para salvar

      const quantidades = await getQuantidadesPEAD(Number(comprimento ?? 0));

      quantidadePEAD6M = quantidades.quantidade6M;
      quantidadePEAD12M = quantidades.quantidade12M;
      flutuador = quantidades.flutuador;
    }

    const tubulacaoSalvar =
      tubos.find(tubo => tubo.nome === material)?.seq || 0;

    if (!tubulacaoSalvar) return;

    async function insert() {
      await api.post('/integracao/adutora/orcamento', {
        sessao: sessaoId,
        ordem,
        fichaTecnicaId: fichaTecnica.id,
        tubulacaoAdutoraId: tubulacaoSalvar,
        tuboDescricao: material,
        metragem: comprimento,
        cotaFinal: tipoCota === 'Linear' ? null : cotaFinal,
        cotaInicial: tipoCota === 'Linear' ? null : cotaInicial,
        orcamentoId,
        velocidade,
        diametro,
        quantidadePEAD6M,
        quantidadePEAD12M,
        flutuador,
        isBalsa,
      });
    }

    async function update() {
      await api.put('/integracao/adutora/orcamento', {
        sessao: sessaoId,
        ordem,
        tubulacaoAdutoraId: tubulacaoSalvar,
        tuboDescricao: material,
        metragem: comprimento,
        cotaInicial: tipoCota === 'Linear' ? null : cotaInicial,
        cotaFinal: tipoCota === 'Linear' ? null : cotaFinal,
        orcamentoId,
        velocidade,
        diametro,
        fichaTecnicaId: fichaTecnica.id,
        quantidadePEAD6M,
        quantidadePEAD12M,
        flutuador,
        isBalsa,
      });
    }

    try {
      setLoading(true);
      if (isNew) {
        await insert();
      } else {
        await update();
      }

      await calculaSimplificado(ordem);

      await fnAtualizarTrechos();

      await atualizarNecessidadeReprocessamento();

      setVazaoAplicar(0);
    } finally {
      setLoading(false);
    }
  }

  function getElevPonto(
    adutoraTubulacaoTrechos1: any[],
    listaTrechosPn1: ListaTrechosPn[],
    comprimento: number,
    ordem: number,
  ) {
    let distAcc = 0;
    let primeiroMaior = null;
    let distanciaTotal = 0;
    if (
      adutoraTubulacaoTrechos1.length > 0 &&
      listaTrechosPn1.length > 0 &&
      comprimento
    ) {
      for (let i = 0; i < ordem - 1; i += 1) {
        distAcc += Number(adutoraTubulacaoTrechos1[i]?.comprimento);
      }

      distAcc += comprimento;

      for (let i = 0; i < listaTrechosPn1.length; i += 1) {
        distanciaTotal += listaTrechosPn1[i].distancia;
        if (distanciaTotal - 6 >= distAcc && i > 0) {
          primeiroMaior = listaTrechosPn1[i].elevacao;
          break;
        }
      }
      if (!primeiroMaior) {
        primeiroMaior = listaTrechosPn1[listaTrechosPn1.length - 1].elevacao;
      }
    }
    return primeiroMaior;
  }

  function onChangeAdutoraTubulacaoOrcamento(
    tubulacaoOrdem: number,
    name: string,
    valor: number,
  ) {
    const novoValor = valor;
    let novoAdutoraTubulacaoOrcamento = null;

    const vazao = getSyncVazao();

    let diametroSugestoes = 0;

    if (name === 'tubulacaoAdutoraId') {
      const tuboDescricao = tubos.find(tubo => tubo.seq === novoValor)?.nome;
      const tuboDI = tubos.find(tubo => tubo.seq === novoValor)?.di;
      const tuboDN = tubos.find(tubo => tubo.seq === novoValor)?.dn;

      diametroSugestoes = Number(tuboDN ?? 0);
      novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora =>
        adutora.ordem === tubulacaoOrdem
          ? {
              ...adutora,
              material: tuboDescricao,
              [name]: novoValor,
              diametro: tuboDI,
              alterado: true,
              salvar: true,
            }
          : adutora,
      );
    } else if (name === 'comprimento') {
      const cota = getElevPonto(
        adutoraTubulacaoTrechos,
        listaTrechosPn,
        novoValor,
        tubulacaoOrdem,
      );

      novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora =>
        adutora.ordem === tubulacaoOrdem
          ? {
              ...adutora,
              [name]: novoValor,
              cotaFinal: cota,
              alterado: true,
              salvar: true,
            }
          : adutora,
      );
    } else {
      novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora =>
        adutora.ordem === tubulacaoOrdem
          ? { ...adutora, [name]: novoValor, alterado: true, salvar: true }
          : adutora,
      );
    }

    // if (name === 'tubulacaoAdutoraId') {

    novoAdutoraTubulacaoOrcamento = novoAdutoraTubulacaoOrcamento.map(
      adutora => {
        if (adutora.ordem !== tubulacaoOrdem || !adutora.diametro)
          return { ...adutora };

        // const tuboDI = tubos.find(tubo => tubo.di === adutora.diametro)?.di;
        const tuboFiltrado = tubos.find(
          tubo => tubo.di === adutora.diametro || tubo.dn === adutora.diametro,
        );

        const velocidadeCalculada = arredondaDecimais(
          vazao /
            // eslint-disable-next-line no-restricted-properties
            (Math.pow(Number(tuboFiltrado?.di || 0) / 1000 / 2, 2) * PI * 3600),
        );

        return {
          ...adutora,
          velocidade: velocidadeCalculada,
          velocidadeErro:
            velocidadeCalculada > (tuboFiltrado?.velocidadeMaximaAgua ?? 0),
        };
      },
    );
    // }

    const comprimentoTotal =
      adutoraTotal -
      novoAdutoraTubulacaoOrcamento
        ?.filter(
          (item: any) => !(item.ordem === 1 && item.material.includes('PEAD')),
        )
        .reduce((total: any, item: any) => total + (item?.comprimento || 0), 0);

    setAdutoraSaldo(comprimentoTotal || 0);
    setSaldoAdutora(comprimentoTotal || 0); // Variável para controlar o botão de concluir etapa

    // Vai carregar Sugestões apenas qunado não for balsa
    if (!isBalsa && diametroSugestoes !== 0 && tubulacaoOrdem === 1) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      calcularSugestoes(diametroSugestoes);
    }
    setAdutoraTubulacaoTrechos(novoAdutoraTubulacaoOrcamento);
    setHabilitaBotao(true);
  }

  function onFocusOutAdutoraTubulacaoOrcamento(
    tubulacaoOrdem: number,
    name: string,
    valor: number,
  ) {
    const novoValor = valor;
    let novoAdutoraTubulacaoOrcamento = null;

    const findOld = adutoraTubulacaoTrechos.filter(
      adutora => adutora.ordem === tubulacaoOrdem,
    );

    const { salvar, material, comprimento, velocidade, novo } = findOld?.[0];

    if (salvar === true && comprimento && material && velocidade) {
      novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.map(adutora =>
        adutora.ordem === tubulacaoOrdem
          ? { ...adutora, [name]: novoValor, alterado: true, salvar: false }
          : adutora,
      );

      setAdutoraTubulacaoTrechos(novoAdutoraTubulacaoOrcamento);

      triggerSalvaTubulacao(
        novo,
        novoAdutoraTubulacaoOrcamento.filter(
          adutora => adutora.ordem === tubulacaoOrdem,
        )[0],
      );
    }
  }

  async function mostrarModalTubulacao(tubulacao?: CalculoAdutora) {
    try {
      setLoading(true);

      if (tubulacao) {
        const rows: any[] = [];

        const mat = tubulacao?.material ?? '';

        if (mat.includes('PEAD')) {
          if (tubulacao?.quantidadePEAD6M != null)
            rows.push(
              `Quantidade tubos PEAD 6M: ${tubulacao?.quantidadePEAD6M}`,
            );
          if (tubulacao?.quantidadePEAD12M != null)
            rows.push(
              `Quantidade tubos PEAD 12M: ${tubulacao?.quantidadePEAD12M}`,
            );
          if (tubulacao?.flutuador != null)
            rows.push(`Quantidade flutuador: ${tubulacao?.flutuador}`);
        } else if (mat.includes('PVC')) {
          if (tubulacao?.tamanhoPVC != null)
            rows.push(`Tamanho tubos PVC: ${tubulacao?.tamanhoPVC}`);
          if (tubulacao?.quantidadePVC != null)
            rows.push(`Quantidade tubos PVC: ${tubulacao?.quantidadePVC}`);
        } else if (mat.includes('AZ')) {
          if (tubulacao?.quantidadeAZ05M != null)
            rows.push(
              `Quantidade tubos AZ 0.5M: ${tubulacao?.quantidadeAZ05M}`,
            );
          if (tubulacao?.quantidadeAZ1M != null)
            rows.push(`Quantidade tubos AZ 1M: ${tubulacao?.quantidadeAZ1M}`);
          if (tubulacao?.quantidadeAZ2M != null)
            rows.push(`Quantidade tubos AZ 2M: ${tubulacao?.quantidadeAZ2M}`);
          if (tubulacao?.quantidadeAZ3M != null)
            rows.push(`Quantidade tubos AZ 3M: ${tubulacao?.quantidadeAZ3M}`);
          if (tubulacao?.quantidadeAZ6M != null)
            rows.push(`Quantidade tubos AZ 6M: ${tubulacao?.quantidadeAZ6M}`);
        } else {
          rows.push(`O material desse trecho não possui dados para exibir`);
        }

        setRowsModal(rows);
        setModalTitulo(
          `Tubulação da ${isBalsa ? 'Balsa' : 'Adutora'} - Trecho ${
            tubulacao?.ordem
          }`,
        );
        setModalStatusVisivel(true);
      }
    } finally {
      setLoading(false);
    }
  }

  function handleAdicionaNovaLinha() {
    if (loading) return;

    const existeNovoNaoSalvo = adutoraTubulacaoTrechos.some(
      adutora => adutora.novo,
    );

    if (existeNovoNaoSalvo) {
      return;
    }

    setAdutoraTubulacaoTrechos([
      ...adutoraTubulacaoTrechos,
      {
        novo: true,
        tubulacaoAdutoraId: 0,
        material: '',
        comprimento: 0,
        orcamentoId,
        sessao: String(sessaoId),
        ordem: adutoraTubulacaoTrechos.length + 1,
        cotaFinal: null,
        cotaInicial: null,
        diametro: null,
        velocidade: null,
        vazao: null,
        coeficiente: null,
        quantidade: null,
        classe: '',
        indicTipo: '',
        quantidadeAZ05M: null,
        quantidadeAZ1M: null,
        quantidadeAZ2M: null,
        quantidadeAZ3M: null,
        quantidadeAZ6M: null,
        quantidadePVC: null,
        tamanhoPVC: null,
        alterado: false,
        hfTotal: null,
        velocidadeErro: false,
        isSaving: false,
        tubulacaoSuccao: isBalsa,
      },
    ]);
    setAdicionando(true);
    // setNovoTrecho(true);
  }

  // O ajuste da velocidade não será aplicado aqui. Não sei qual o motivo da listagem manual dos tubos AZ
  function getDiamTuboInicial(vazao: any) {
    const todosDiAZ = [
      100, 141, 150, 165, 200, 250, 300, 350, 400, 450, 500, 600,
    ];
    let velocidadeTubo: any;

    for (let j = 0; j < todosDiAZ.length; j += 1) {
      const diamentro = todosDiAZ[j];
      velocidadeTubo = arredondaDecimais(
        vazao /
          // eslint-disable-next-line no-restricted-properties
          (Math.pow(Number(diamentro || 0) / 1000 / 2, 2) * PI * 3600),
      );
      if (velocidadeTubo <= 2.5) {
        velocidadeTubo = todosDiAZ[j];
        break;
      }
    }
    return velocidadeTubo;
  }

  async function criarTrechoInicial() {
    const vazao = await getVazaoDivisao();
    const diametroPrimLance: number = getDiamTuboInicial(vazao);

    const comprimentoPadrao = parametros?.adutoraComprimentoPadraoTrecho1 || 0;

    const tipoTuboBuscar = iniciarPEAD ? 'PEAD' : 'AZ';
    const tubosAZ = tubos.filter(item => item.nome.includes(tipoTuboBuscar));

    let quantidadePEAD6M = 0;
    let quantidadePEAD12M = 0;
    let flutuador = 0;
    let diametro = 0;

    let comprimentoUsar = comprimentoPadrao;
    if (iniciarPEAD) {
      const infoPead = await getComprimentoPEAD();
      comprimentoUsar = infoPead.comprimento;
      quantidadePEAD6M = infoPead.quantidade6M;
      quantidadePEAD12M = infoPead.quantidade12M;
      flutuador = infoPead.flutuador;
      diametro = infoPead.diametro;
    }

    const isArray = Array.isArray(fichaTecnicaTubulacao);

    let diametroLigacao: number = fichaTecnicaTubulacao?.diametroLigacao ?? 0;

    // let velocidadeLigacao = fichaTecnicaTubulacao?.velocLigacao ?? 0;

    if (isArray) {
      const fichaArr: any = fichaTecnicaTubulacao;
      // por algum motivo, identificava como array
      diametroLigacao = fichaArr?.[0]?.diametroLigacao ?? 0;
      // velocidadeLigacao = fichaArr?.[0]?.velocLigacao ?? 0;
    }

    if (diametroLigacao < diametroPrimLance) {
      diametroLigacao = diametroPrimLance;
    }

    // diametroLigacao /= vazaoDivisao;
    // const tuboFiltrado = tubosAZ.filter(item => item.di === diametroLigacao);
    let diametroFiltrar = diametroLigacao;

    if (!tubosAZ.filter(item => item.dn === diametroLigacao).length) {
      diametroFiltrar = diametro;
    }

    const tuboFiltrado = tubosAZ.filter(item => item.dn === diametroFiltrar);

    const velocidadeCalculada = arredondaDecimais(
      vazao /
        // eslint-disable-next-line no-restricted-properties
        (Math.pow(Number(tuboFiltrado[0]?.di || 0) / 1000 / 2, 2) * PI * 3600),
    );

    const trechoInicial: any = {
      novo: true,
      tubulacaoAdutoraId: tuboFiltrado[0]?.seq || 0,
      material: tuboFiltrado[0]?.nome || '',
      comprimento: comprimentoUsar,
      orcamentoId,
      sessao: String(sessaoId),
      ordem: 1,
      cotaFinal: null,
      cotaInicial: null,
      diametro: tuboFiltrado[0]?.di || null,
      vazao: null,
      coeficiente: null,
      quantidade: null,
      classe: '',
      indicTipo: '',
      quantidadeAZ05M: null,
      quantidadeAZ1M: null,
      quantidadeAZ2M: null,
      quantidadeAZ3M: null,
      quantidadeAZ6M: null,
      quantidadePVC: null,
      quantidadePEAD6M,
      quantidadePEAD12M,
      flutuador,
      tamanhoPVC: null,
      alterado: false,
      hfTotal: null,
      tubulacaoSuccao: isBalsa,
      velocidade: velocidadeCalculada,
      velocidadeErro:
        velocidadeCalculada > (tuboFiltrado[0]?.velocidadeMaximaAgua ?? 0),
    };

    setAdutoraTubulacaoTrechos([trechoInicial]);

    // No caso de específica, não disparo o processo de salvar. Pois ele precisa informar as cotas.
    // Assim, não vai disparar os erros
    if (!indicCotaEspecifica) triggerSalvaTubulacao(true, trechoInicial);

    // Ovidio no primeiro trecho
    // carregarCamposElevacao(trechoInicial?.diametro || 0);
  }

  async function handleCalcularAdutoraTotal(tipo = '') {
    const isCamposPreenchidos =
      tipoCota &&
      bombeamentoMestre &&
      bomba &&
      quantidadeBomba &&
      quantidadeModulos;

    const toastId = toast.loading('Calculando...');
    if (!isCamposPreenchidos) {
      Swal.fire({
        icon: 'error',
        text: `Algum campo da adutora não foi respondido!`,
      });
      toast.dismiss(toastId);
      return;
    }

    try {
      setLoadingCalculoAdutora(true);

      if (tipo !== '') {
        // tipo vem de processo específico. Preciso recalcular os trechos.
        await calculaSimplificado(1);
      }

      await api.post('/integracao/adutora/orcamento/calculo-novo', {
        orcamentoId,
        fichaTecnicaId,
        tipoExecucao: 'G',
        isBalsa,
        tipoBomba: tipo !== '' ? tipo : tipoBomba,
        tipoCota,
      });

      setVazaoAplicar(0);

      atualizarFuncoes([`TRECHOS${isBalsa ? '_BALSA' : ''}`], isBalsa);

      toast.success('Cálculo finalizado!', { position: 'top-right' });
    } finally {
      toast.dismiss(toastId);
      setLoadingCalculoAdutora(false);
    }
  }

  async function handleRecalcularVelocidades(
    vazaoUsar: number,
    listaUsar: any[],
  ) {
    let valido = true;

    let novoAdutoraTubulacaoOrcamento = null;

    novoAdutoraTubulacaoOrcamento = listaUsar.map(adutora => {
      const tuboFiltrado = tubos.find(
        tubo => String(tubo.nome) === String(adutora.material),
      );

      const tuboSelecionar = tuboFiltrado?.seq ?? null;

      const novoDiametro = tubos.find(
        tubo => String(tubo.nome) === String(adutora.material),
      )?.di;

      const velocidadeCalculada = arredondaDecimais(
        vazaoUsar /
          // eslint-disable-next-line no-restricted-properties
          (Math.pow(Number(novoDiametro || 0) / 1000 / 2, 2) * PI * 3600),
      );

      return {
        ...adutora,
        vazao: vazaoUsar,
        diametro: novoDiametro,
        tubulacaoAdutoraId: tuboSelecionar,
        velocidade: velocidadeCalculada,
        velocidadeErro:
          velocidadeCalculada > (tuboFiltrado?.velocidadeMaximaAgua ?? 0),
      };
    });

    valido =
      novoAdutoraTubulacaoOrcamento?.filter(
        (it: any) => it.velocidadeErro === true,
      ).length > 0;

    return { temErro: valido, lista: novoAdutoraTubulacaoOrcamento };
  }

  async function handlePreValidar() {
    const toastId = toast.loading('Processando trechos antes de calcular');

    try {
      const vazao = await getVazaoDivisao();
      // Dispara o recalculo
      await calculaSimplificado(1);

      // Busca os trechos
      const listaTrechos = await carregarTrechos();

      const { temErro, lista } = await handleRecalcularVelocidades(
        vazao,
        listaTrechos,
      );
      setAdutoraTubulacaoTrechos(lista);

      if (temErro) {
        toast.dismiss(toastId);
        Swal.fire({
          icon: 'error',
          text: `Um ou mais trechos estão com velocidade excedente`,
        });
        return;
      }

      const valido = await handleCheckPN(listaTrechos);
      if (!valido) {
        toast.dismiss(toastId);
        return;
      }

      if (iniciarPEAD) {
        // Buscar o primeiro trecho, se for PEAD
        const primeiro = adutoraTubulacaoTrechos.find(
          (item: any) => item.ordem === 1 && item.material.includes('PEAD'),
        );
        if (primeiro) {
          const infoPead = await getComprimentoPEAD();

          const { comprimento } = primeiro;

          // Confere se o comprimento é inferior ao calculado
          if (Number(comprimento ?? 0) < infoPead.comprimento) {
            Swal.fire({
              icon: 'error',
              text: `O comprimento do trecho PEAD informado [${Number(
                comprimento ?? 0,
              )}] é inferior ao mínimo calculado para o PEAD [${
                infoPead.comprimento
              }]`,
            });
            toast.dismiss(toastId);
            return;
          }
        }
      }

      toast.dismiss(toastId);
      if (valido === true) await handleCalcularAdutoraTotal();
    } finally {
      toast.dismiss(toastId);
    }
  }

  async function deleteTubulacao(tubulacao: any) {
    if (tubulacao.novo) {
      const novoAdutoraTubulacaoOrcamento = adutoraTubulacaoTrechos.filter(
        adutora => !adutora.novo,
      );

      setAdutoraTubulacaoTrechos(novoAdutoraTubulacaoOrcamento);

      if (novoAdutoraTubulacaoOrcamento.length === 0) {
        criarTrechoInicial();
      }
      setAdicionando(false);
    } else {
      const { ordem } = tubulacao;
      try {
        setLoading(true);

        await api.delete('/integracao/adutora/orcamento', {
          params: {
            sessao: sessaoId,
            ordem,
            fichaTecnicaId: fichaTecnica.id,
            orcamentoId,
            isBalsa,
          },
        });

        fnAtualizarTrechos();
      } finally {
        setLoading(false);
        setExibeTotais(false);
      }
    }

    await atualizarNecessidadeReprocessamento();
  }

  async function handlerInserirSugestoesLote(lista: any[]) {
    const isArray = Array.isArray(fichaTecnicaTubulacao);

    const vazao = await getVazaoDivisao();
    let diametroLigacao: number = fichaTecnicaTubulacao?.diametroLigacao ?? 0;

    if (isArray) {
      const fichaArr: any = fichaTecnicaTubulacao;
      diametroLigacao = fichaArr?.[0]?.diametroLigacao ?? 0;
    }

    const listaSalvar: any[] = [];

    let checkFirst = true;
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of lista) {
      let quantidadePEAD6M = 0;
      let quantidadePEAD12M = 0;
      let flutuador = 0;

      const tuboUsar = tubos.filter(
        item2 => item2.nome === (item.classePN ?? ''),
      );

      if (tuboUsar) {
        const velocidadeCalculada = arredondaDecimais(
          vazao /
            // eslint-disable-next-line no-restricted-properties
            (Math.pow(Number(tuboUsar[0]?.di || 0) / 1000 / 2, 2) * PI * 3600),
        );

        if (iniciarPEAD && checkFirst) {
          checkFirst = false;
          const infoPead = await getComprimentoPEAD();
          const comprimento = item.distAcc;

          // Confere se o comprimento é inferior ao calculado
          if (Number(comprimento ?? 0) < infoPead.comprimento) {
            toast.error(
              `O comprimento informado [${Number(
                comprimento ?? 0,
              )}] é inferior ao mínimo calculado para o PEAD [${
                infoPead.comprimento
              }]`,
            );
            return;
          }

          // Confere se o comprimento é um múltiplo de 6

          const resto = Number(comprimento ?? 0) % 6;

          if (resto !== 0) {
            toast.error(
              `O comprimento informado [${Number(
                comprimento ?? 0,
              )}] no primeiro trecho de PEAD precisa ser múltiplo de 6`,
            );
            return;
          }

          // A validação deu certo. Agora, busco as quantidades de PEAD para salvar

          const quantidades = await getQuantidadesPEAD(
            Number(comprimento ?? 0),
          );

          quantidadePEAD6M = quantidades.quantidade6M;
          quantidadePEAD12M = quantidades.quantidade12M;
          flutuador = quantidades.flutuador;
        }

        listaSalvar.push({
          novo: true,
          tubulacaoAdutoraId: tuboUsar[0]?.seq || 0,
          material: tuboUsar[0]?.nome || '',
          comprimento: item.distAcc ?? 0,
          orcamentoId,
          sessao: String(sessaoId),
          ordem: listaSalvar.length + 1,
          cotaFinal: item.cotaFin ?? null,
          cotaInicial: item.cotaIni ?? null,
          diametro: tuboUsar[0]?.di || null,
          vazao: null,
          coeficiente: null,
          quantidade: null,
          classe: '',
          indicTipo: '',
          quantidadeAZ05M: null,
          quantidadeAZ1M: null,
          quantidadeAZ2M: null,
          quantidadeAZ3M: null,
          quantidadeAZ6M: null,
          quantidadePVC: null,
          quantidadePEAD6M,
          quantidadePEAD12M,
          flutuador,
          tamanhoPVC: null,
          alterado: false,
          hfTotal: null,
          tubulacaoSuccao: isBalsa,
          velocidade: velocidadeCalculada,
          velocidadeErro:
            velocidadeCalculada > (tuboUsar[0]?.velocidadeMaximaAgua ?? 0),
        });
      }
    }

    if (listaSalvar.length !== lista.length) {
      toast.error('Não foi possível gerar a lista de trechos a ser salva');
      return;
    }

    if (listaSalvar.filter(item => item.velocidadeErro === true).length > 0) {
      toast.error(
        'Um ou mais itens da sugestão extrapolam a velocidade limite',
      );
      return;
    }

    setInserindoSugestao(true);

    // Faz a exclusão dos itens que tem hoje (se houver)

    if (adutoraTubulacaoTrechos && adutoraTubulacaoTrechos.length > 0) {
      Swal.fire({
        icon: 'info',
        text: `Excluindo registros antigos`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await api.delete('/integracao/adutora/orcamento', {
        params: {
          sessao: sessaoId,
          fichaTecnicaId: fichaTecnica.id,
          orcamentoId,
          isBalsa,
          deleteAll: true,
        },
      });
    }

    let index = 0;
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of listaSalvar) {
      Swal.fire({
        icon: 'info',
        text: `Salvando ${index + 1}º trecho`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await api.post('/integracao/adutora/orcamento', {
        sessao: sessaoId,
        ordem: index + 1,
        fichaTecnicaId: fichaTecnica.id,
        tubulacaoAdutoraId: item.tubulacaoAdutoraId,
        tuboDescricao: item.material,
        metragem: item.comprimento,
        cotaFinal: tipoCota === 'Linear' ? null : item.cotaFinal,
        cotaInicial: tipoCota === 'Linear' ? null : item.cotaInicial,
        orcamentoId,
        velocidade: item.velocidade,
        diametro: item.diametro,
        quantidadePEAD6M: item.quantidadePEAD6M,
        quantidadePEAD12M: item.quantidadePEAD12M,
        flutuador: item.flutuador,
        isBalsa,
      });

      index += 1;
    }

    Swal.fire({
      icon: 'info',
      text: `Calculando trechos`,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    // Dispara o cálculo dos trechos
    await calculaSimplificado(1);

    // Dispara o cálculo completo
    await handleCalcularAdutoraTotal();

    await atualizarNecessidadeReprocessamento();

    Swal.close();

    setVazaoAplicar(0);

    atualizarFuncoes([`TRECHOS${isBalsa ? '_BALSA' : ''}`], isBalsa);

    setInserindoSugestao(false);
  }

  useEffect(() => {
    if (orcamentoInfoBalsa) {
      const infoIsBalsa = orcamentoInfoBalsa?.isBalsa ?? false;
      const infoBalsaAux = orcamentoInfoBalsa?.isBalsaAuxiliar ?? false;
      const infoSalvar =
        (infoIsBalsa && !infoBalsaAux) ||
        (infoIsBalsa && infoBalsaAux && isBalsa);

      setIniciarPEAD(infoSalvar);
    }
  }, [orcamentoInfoBalsa]);

  useEffect(() => {
    if (necessidadeReprocessamento) {
      const varIsNecessidadeReprocessamentoTrechos = isBalsa
        ? necessidadeReprocessamento?.reprocessarTrechosBalsa ?? false
        : necessidadeReprocessamento?.reprocessarTrechos ?? false;

      const varIsNecessidadeReprocessamentoAdutora =
        necessidadeReprocessamento?.reprocessarAdutora || false;

      setIsNecessidadeReprocessamentoAdutora(
        varIsNecessidadeReprocessamentoAdutora,
      );
      setIsNecessidadeReprocessamentoTrechos(
        varIsNecessidadeReprocessamentoTrechos,
      );
    }
  }, [necessidadeReprocessamento]);

  async function carregarDados() {
    setDadosBaseCarregados(false);
    if (fichaTecnicaAdutora && fichaTecnicaAdutora.tipoCota) {
      const listaDesniveis = await carregaDesniveisTubos();
      const listaTubos = await carregaTubos();
      const listaTrechos = await carregarTrechos();
      const vazao = await getVazaoDivisao();

      let data = listaTrechos?.map((item: any) => {
        const tuboFiltrado = listaTubos.find(
          (tubo: any) => tubo.nome === item.material,
        );

        const velocidadeCalculada = arredondaDecimais(
          vazao /
            // eslint-disable-next-line no-restricted-properties
            (Math.pow(Number(tuboFiltrado?.di || 0) / 1000 / 2, 2) * PI * 3600),
        );

        return {
          ...item,
          vazao,
          tubulacaoAdutoraId: tuboFiltrado?.seq,
          velocidade: velocidadeCalculada,
          velocidadeErro:
            velocidadeCalculada > (tuboFiltrado?.velocidadeMaximaAgua ?? 0),
        };
      });

      data = data.filter((item: any) => item.tubulacaoSuccao === isBalsa);

      const comprimentoTotal =
        adutoraTotal -
        data
          ?.filter(
            (item: any) =>
              !(item.ordem === 1 && item.material.includes('PEAD')),
          )
          .reduce(
            (total: any, item: any) => total + (item?.comprimento || 0),
            0,
          );

      setCriarPrimeiroTrecho(false);
      setAdutoraSaldo(comprimentoTotal || 0);

      let criarTrecho = false;

      if (!data || data.length === 0) criarTrecho = true;

      setDesnivelTubos(listaDesniveis);
      setTubos(listaTubos);
      setHabilitarTrechos(true);

      if (data && data.length) {
        setAdutoraTubulacaoTrechos(data);
      }

      setCriarPrimeiroTrecho(criarTrecho);
      setDadosBaseCarregados(true);
    }
  }

  async function renderParametros() {
    await carregarRespostas();
    await carregarTubulacao();
    await carregarAdutora();
  }

  useEffect(() => {
    if (fichaTecnica && fichaTecnicaAdutora && ftFichaTecnicaTubulacao) {
      renderParametros();
    } else {
      setHabilitarTrechos(false);
    }
  }, [fichaTecnica, fichaTecnicaAdutora, ftFichaTecnicaTubulacao]);

  useEffect(() => {
    if (paramAdutoraCarregada === true && parameRespostaCarregada === true) {
      carregarDados();
    } else {
      setHabilitarTrechos(false);
    }
  }, [paramAdutoraCarregada, parameRespostaCarregada]);

  useEffect(() => {
    if (criarPrimeiroTrecho === true) {
      criarTrechoInicial();
    }
  }, [criarPrimeiroTrecho]);

  useEffect(() => {
    if (
      adutoraTotal &&
      adutoraTubulacaoTrechos &&
      adutoraTubulacaoTrechos.length
    ) {
      const comprimentoTotal =
        adutoraTotal -
        adutoraTubulacaoTrechos
          ?.filter(
            (item: any) =>
              !(item.ordem === 1 && item.material.includes('PEAD')),
          )
          .reduce(
            (total: any, item: any) => total + (item?.comprimento || 0),
            0,
          );

      setAdutoraSaldo(comprimentoTotal || 0);
      setSaldoAdutora(comprimentoTotal || 0); // Variável para controlar o botão de concluir etapa

      if (comprimentoTotal === 0 && !isNecessidadeReprocessamentoAdutora) {
        setPodeCalcularAdutora(true);
      } else {
        setPodeCalcularAdutora(false);
      }
    }
  }, [adutoraTubulacaoTrechos, adutoraTotal]);

  function getClassePN(pressaoI: number, pressaoF: number) {
    let p = pressaoI;
    if (pressaoF > p) p = pressaoF;

    let retorno = 0;
    if (p <= 58) {
      retorno = 60;
    } else if (p > 58 && p <= 78) {
      retorno = 80;
    } else if (p > 78 && p <= 122) {
      retorno = 125;
    } else if (p > 122 && p <= 138) {
      retorno = 140;
    } else if (p > 138 && p <= 158) {
      retorno = 160;
    } else if (p > 158 && p <= 178) {
      retorno = 180;
    } else {
      retorno = 0;
    }
    return retorno;
  }

  function getHF(
    diametro: number,
    vazao: number,
    distancia: number,
    coeficiente: number,
  ) {
    // eslint-disable-next-line no-restricted-properties
    const diX = Math.pow(diametro / 1000, 4.87);
    // eslint-disable-next-line no-restricted-properties
    const vaX = Math.pow(vazao / (3600 * coeficiente), 1.85);
    const hf = (distancia / diX) * 10.643 * vaX;
    return hf;
  }

  async function getPeadUsar(diametro: number): Promise<{
    classe: string;
    diametro: number;
    diametroDN: number;
  }> {
    const tubosFiltrado = tubos.filter(
      item => item.nome.includes('PEAD') && (item.dn ?? 0) >= diametro,
    );

    return {
      diametro: tubosFiltrado?.[0]?.di ?? diametro,
      diametroDN: tubosFiltrado?.[0]?.dn ?? diametro,
      classe: tubosFiltrado?.[0]?.nome,
    };
  }

  async function getTrechosAdutora(
    tubosPVC: AdutoraTubulacao[] = [],
    diametroAZ: number,
    coeficienteAZ: number,
    diametro: number,
    elevacoes: any = [],
  ) {
    const {
      perdaCargaTuboPivo,
      alturaSuccao,
      pressaoTotalCentroPivo,
      vazaoCAF,
      vazaoSAF,
      incluirAspersorFinal,
    } = fichaTecnica;

    const { percentualPerdaCarga, desnivelCaptacaoCentroPivo } =
      fichaTecnicaAdutora;

    const usaAspersorFinal: boolean =
      String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
      String(incluirAspersorFinal).toUpperCase() === 'S';

    const vazaoP = usaAspersorFinal ? Number(vazaoCAF) : Number(vazaoSAF);

    let perdasHF = 0;
    let perdasAdutora = 0;
    let perdaLocal = 0;
    let mca = 0;

    // Prepara lista Elevações ******************************* 1 ***********************
    // ###################################################################################
    let listaTrechos: any[];
    let seguimentosTrechos: SegTrechos[];
    let distAdutoraTotal = 12;
    // eslint-disable-next-line prefer-const
    listaTrechos = [];
    // eslint-disable-next-line prefer-const
    seguimentosTrechos = [];

    const hfAz = getHF(diametroAZ, vazaoP, 12, coeficienteAZ); // <===reajustar para buscar do cadastro

    // Aqui vai precisar um ajuste para considerar os valores para PEAD
    listaTrechos.push({
      distancia: 12,
      elevacao: elevacoes[0]?.elevacao,
      hf: hfAz,
      pressao: 0,
      pn: 125,
      di: diametroAZ,
    });

    const info = {
      perdaLocal,
      hfAz,
      perdaCargaTuboPivo,
      percentualPerdaCarga,
      desnivelCaptacaoCentroPivo,
      alturaSuccao,
      pressaoTotalCentroPivo,
      perdasHF,
      vazaoCAF,
      vazaoSAF,
      incluirAspersorFinal,
      perdasAdutora,
      mca,
    };

    if (vazaoP) {
      for (let i = 1; i < elevacoes.length; i += 1) {
        const distancia = elevacoes[i].distancia - elevacoes[i - 1].distancia;
        listaTrechos.push({
          distancia,
          elevacao: elevacoes[i]?.elevacao ?? 0,
          hf: 0,
          pressao: 0,
          pn: 125,
          di: 0,
        });
      }

      for (let k = 0; k < 4; k += 1) {
        perdasAdutora = perdasHF;

        perdaLocal =
          (perdasAdutora + (perdaCargaTuboPivo ?? 0)) *
          (percentualPerdaCarga / 100);

        mca =
          desnivelCaptacaoCentroPivo +
          alturaSuccao +
          pressaoTotalCentroPivo +
          perdasAdutora +
          perdaLocal;

        perdasHF = 0;
        let pIni = mca;
        let pFin = 0;
        let eIni = 0;
        let eFin = 0;
        const altSuc = alturaSuccao ?? 0;
        let pnAgora = 0;
        let di = 0;
        let bandaPn = 0.2;
        let pnfAnt = 180;

        for (let i = 0; i < listaTrechos.length; i += 1) {
          const { elevacao, distancia, pn } = listaTrechos[i];
          // tubo
          if (
            pnAgora !== pn &&
            tubosPVC.filter(item => item.pn === pn) &&
            tubosPVC.filter(item => item.pn === pn).length > 0
          ) {
            const tuboFiltrado = tubosPVC.filter(item => item.pn === pn)[0];
            di = tuboFiltrado?.di || 0;
            pnAgora = pn;
          }

          // hf tubo
          let hf = getHF(di, vazaoP, distancia, 140);

          // Comentando temporariamente. Por enquanto serão analisados cadastros
          // if (hf === Infinity || hf === -Infinity) hf = 0;

          // elevacao
          eFin = elevacao;

          if (i === 0) {
            eIni = elevacao;
            hf = hfAz;
            // const xhfAz = getHF(diametroAZ, vazaoP, 12, 135); // <===reajustar para buscar do cadastro
            const xhfAz = getHF(diametroAZ, vazaoP, 12, coeficienteAZ);
            pFin = pIni - (eFin - eIni) - xhfAz - altSuc;
          } else {
            eIni = listaTrechos[i - 1].elevacao;
            pFin = pIni - (eFin - eIni) - hf;
          }

          // controla mudança de Pressao
          const pnf = getClassePN(pIni + bandaPn, pFin + bandaPn);

          /* const pnf = getClassePN(
            (pIni === Infinity || Number.isNaN(pIni) ? 0 : pIni) + bandaPn,
            (pFin === Infinity || Number.isNaN(pFin) ? 0 : pFin) + bandaPn,
          ); */

          if (pnf > pnfAnt) {
            bandaPn = 0.7;
            pnfAnt = pnf;
          }

          if (pnf < pnfAnt) {
            bandaPn = 0.2;
            pnfAnt = pnf;
          }

          pIni = pFin;

          perdasHF += hf;

          /* console.log({
            distancia,
            elevacao,
            hf,
            pFin,
            pIni,
            pn: pnf,
            di,
            original: { elevacao, distancia, pn },
          });
          */
          listaTrechos[i] = {
            distancia,
            elevacao,
            hf,
            pFin,
            pn: pnf,
            di,
          };
        }
      }

      // Finaliza Trechos ******************************* 3 ***********************
      // ###################################################################################
      let pnIni = listaTrechos[1].pn;
      let pFinal = listaTrechos[1].pFin;
      let cotaIni = listaTrechos[0].elevacao;
      let cotaFin = listaTrechos[1].elevacao;
      let distAcc = 12;

      let classePN = `AZ ${diametroAZ}`;

      let menorElev = 9999;
      let mED = 0;

      seguimentosTrechos.push({
        distAcc,
        totalAcc: 0,
        cotaIni,
        cotaFin,
        classePN,
        pFinal,
        diametro: diametroAZ,
        menorElev: cotaIni,
        mED,
      });

      distAcc = -6;

      for (let i = 1; i < listaTrechos.length; i += 1) {
        const { elevacao, distancia, pn } = listaTrechos[i];

        if (pnIni !== pn) {
          cotaFin = listaTrechos[i - 1].elevacao;
          pFinal = listaTrechos[i - 1].pFin;
          classePN = `PVC ${diametro}/${listaTrechos[i - 1].pn}`;

          seguimentosTrechos.push({
            distAcc,
            totalAcc: 0,
            cotaIni,
            cotaFin,
            classePN,
            pFinal,
            diametro,
            menorElev,
            mED,
          });

          distAdutoraTotal += distAcc;
          pnIni = pn;
          cotaIni = cotaFin;
          distAcc = distancia;
          menorElev = 9999;
          mED = 0;
        } else {
          distAcc += distancia;
          // if (elevacao < menorElev) {
          if (elevacao < menorElev) {
            menorElev = elevacao;
            mED = distAcc;
          }
        }
      }

      if (distAcc > 0) {
        distAdutoraTotal += distAcc;
        cotaFin = listaTrechos[listaTrechos.length - 1].elevacao;
        pFinal = listaTrechos[listaTrechos.length - 1].pFin;
        classePN = `PVC ${diametro}/${
          listaTrechos[listaTrechos.length - 1].pn
        }`;
        seguimentosTrechos.push({
          distAcc,
          totalAcc: 0,
          cotaIni,
          cotaFin,
          classePN,
          pFinal,
          diametro,
          menorElev,
          mED,
        });
      }
    }

    // restante no 2 trecho
    // alert(adutoraTotal - distAdutoraTotal);
    if (seguimentosTrechos.length > 0)
      seguimentosTrechos[seguimentosTrechos.length - 1].distAcc +=
        adutoraTotal - distAdutoraTotal;

    // cota intermediária
    for (let i = 0; i < seguimentosTrechos.length; i += 1) {
      seguimentosTrechos[i].cotaFin = Number(
        seguimentosTrechos[i].cotaFin.toFixed(1),
      );
      const seg = seguimentosTrechos[i];
      const difI = seg.menorElev - seg.cotaIni;
      const difF = seg.menorElev - seg.cotaFin;

      if (difI <= -1.0 && difF <= -1.0) {
        const difC = seg.distAcc - seg.mED;
        const newSeg = JSON.parse(JSON.stringify(seg));

        seguimentosTrechos[i].cotaFin = Number(seg.menorElev.toFixed(1));
        seguimentosTrechos[i].distAcc = seg.mED;
        i += 1;
        seguimentosTrechos.splice(i, 0, newSeg);
        seguimentosTrechos[i].cotaIni = newSeg.menorElev;
        seguimentosTrechos[i].distAcc = difC;
      } else {
        seguimentosTrechos[i].mED = -1;
      }
    }

    let totalAcc = 0;
    let restoAcc = 0;

    for (let i = 0; i < seguimentosTrechos.length; i += 1) {
      if ((seguimentosTrechos[i]?.distAcc ?? 0) < 1) {
        restoAcc += seguimentosTrechos[i].distAcc;
        seguimentosTrechos.splice(i, 1);
      }

      totalAcc += seguimentosTrechos[i]?.distAcc ?? 0;
      if (seguimentosTrechos[i]) seguimentosTrechos[i].totalAcc = totalAcc;
    }

    if (seguimentosTrechos[seguimentosTrechos.length - 1]) {
      seguimentosTrechos[seguimentosTrechos.length - 1].distAcc += restoAcc;
      seguimentosTrechos[seguimentosTrechos.length - 1].totalAcc += restoAcc;
    }

    let seguimentos = seguimentosTrechos;

    if (iniciarPEAD) {
      const infoPead = await getComprimentoPEAD();
      const usar = await getPeadUsar(diametroAZ);

      const classePN = usar.classe;
      const diametroUsar = usar.diametro;

      const primeiroTrecho = seguimentosTrechos[0];

      seguimentos = [
        {
          ...primeiroTrecho,
          classePN,
          distAcc: infoPead.comprimento,
          totalAcc: 0,
          diametro: diametroUsar,
        },
        ...seguimentosTrechos,
      ];
    }

    // navigator.clipboard.writeText(`${JSON.stringify(seguimentosTrechos)}`); // Temporário só para testes
    return {
      listaTrechos,
      seguimentosTrechos: seguimentos,
      info,
      fichaTecnica,
      fichaTecnicaAdutora,
    };
  }

  async function carregarCamposElevacao(diametroAZ: number) {
    const response = await api.get(
      `/orcamento/pontos-mapa?orcamentoId=${orcamentoId}&tipo=elevacao_adutora`,
    );

    // Buscar aqui os dados de PEAD, se houver.
    // Identificar o diametro-base a ser usado.

    let coeficiente = 130;

    if (diametroAZ > 0) {
      let xDiametro = diametroAZ;

      if (iniciarPEAD) {
        // Condições para PEAD => AZ
        const usar = await getPeadUsar(diametroAZ);
        const diametroUsar = usar.diametroDN;

        let diametroBuscar = diametroUsar;
        if (diametroUsar === 160) diametroBuscar = 150;
        else if (diametroUsar === 315) diametroBuscar = 300;
        else if (diametroUsar === 355) diametroBuscar = 350;

        const tuboAZx = tubos.find(
          item => item.nome.includes('AZ') && item.dn === diametroBuscar,
        );

        coeficiente = tuboAZx?.coeficiente ?? 130;

        xDiametro = diametroBuscar;

        /* const tuboUsarAcimaFiltrado = tuboAZx.filter(
          item => (item?.dn ?? 0) >= diametroUsar,
        );

        const tuboUsarAbaixoFiltrado = tuboAZx.filter(
          item => (item?.dn ?? 0) <= diametroUsar,
        );

        const tuboUsarAcima = tuboUsarAcimaFiltrado.reduce(
          (prev: any, current: any) =>
            prev && prev.dn < current.dn ? prev : current,
        );
        const tuboUsarAbaixo = tuboUsarAbaixoFiltrado.reduce(
          (prev: any, current: any) =>
            prev && prev.dn > current.dn ? prev : current,
        );

        const diametroAbaixo = tuboUsarAbaixo?.dn ?? 0;
        const diametroAcima = tuboUsarAcima?.dn ?? 0;

        xDiametro = diametroAcima;
        coeficiente = tuboUsarAcima?.coeficiente ?? 130; */

        /* if (diametroAcima - diametroUsar < diametroUsar - diametroAbaixo) {
          xDiametro = diametroAcima;
          coeficiente = tuboUsarAcima?.coeficiente ?? 130;
        } else {
          xDiametro = diametroAbaixo;
          coeficiente = tuboUsarAbaixo?.coeficiente ?? 130;
        } */
      } else {
        // Condições para AZ => PVC

        let diametroBuscar = diametroAZ;
        if (diametroAZ === 141) diametroBuscar = 150;
        else if (diametroAZ === 165) diametroBuscar = 200;

        const tuboAZx = tubos.find(
          item => item.nome.includes('PVC') && item.dn === diametroBuscar,
        );

        coeficiente = tuboAZx?.coeficiente ?? 130;

        xDiametro = diametroBuscar;
        /*
        const tuboAZx = tubos.filter(item => item.nome.includes('PVC'));
        const tuboUsarAcimaFiltrado = tuboAZx.filter(
          item => (item?.dn ?? 0) >= xDiametro,
        );

        const tuboUsarAbaixoFiltrado = tuboAZx.filter(
          item => (item?.dn ?? 0) <= xDiametro,
        );

        const tuboUsarAcima = tuboUsarAcimaFiltrado.reduce(
          (prev: any, current: any) =>
            prev && prev.dn < current.dn ? prev : current,
        );
        const tuboUsarAbaixo = tuboUsarAbaixoFiltrado.reduce(
          (prev: any, current: any) =>
            prev && prev.dn > current.dn ? prev : current,
        );

        const diametroAbaixo = tuboUsarAbaixo?.dn ?? 0;
        const diametroAcima = tuboUsarAcima?.dn ?? 0;

        console.log({
          a: diametroAcima - xDiametro,
          b: xDiametro - diametroAbaixo,
          c: diametroAcima - xDiametro < xDiametro - diametroAbaixo,
          diametroAcima,
          xDiametro,
          diametroAbaixo,
        });

        xDiametro = diametroAcima;
        coeficiente = tuboUsarAcima?.coeficiente ?? 130; */

        /* if (diametroAcima - xDiametro < xDiametro - diametroAbaixo) {
          xDiametro = diametroAcima;
          coeficiente = tuboUsarAcima?.coeficiente ?? 130;
        } else {
          xDiametro = diametroAbaixo;
          coeficiente = tuboUsarAbaixo?.coeficiente ?? 130;
        } */
      }

      const xElevacoes = response.data;
      const tubosPVC = tubos.filter(item =>
        item.nome.includes(`PVC ${xDiametro}/`),
      );

      // executa trechos
      if (fichaTecnicaAdutora) {
        const listaTrechosX = await getTrechosAdutora(
          tubosPVC,
          diametroAZ,
          coeficiente,
          xDiametro,
          xElevacoes,
        );

        setSugestoes(listaTrechosX?.seguimentosTrechos);
        setListaTrechosPn(listaTrechosX?.listaTrechos);
      }
    }
  }

  function mostra_grafico(mostrar: boolean) {
    const chartDiv = document.getElementById(`trecho_chart_${isBalsa}`);
    const segDiv = document.getElementById('segDiv');
    const segTab = document.getElementById('segTab');

    if (chartDiv && segDiv && segTab) {
      if (
        chartDiv.style.height === '1px' ||
        mostrar ||
        segTrechos.length === 0
      ) {
        chartDiv.style.height = '480px';
        segDiv.style.height = '480px';
        segTab.style.height = 'auto';
        chartDiv.style.visibility = 'visible';
        segDiv.style.visibility = 'visible';
        segTab.style.visibility = 'visible';
      } else {
        chartDiv.style.height = '1px';
        segDiv.style.height = '1px';
        segTab.style.height = '1px';
        chartDiv.style.visibility = 'hidden';
        segDiv.style.visibility = 'hidden';
        segTab.style.visibility = 'hidden';
      }
    }
  }

  async function initChart() {
    if (window.google && listaTrechosPn?.length > 0) {
      const chartDiv = document.getElementById(`trecho_chart_${isBalsa}`);
      const chart = new window.google.visualization.ComboChart(chartDiv);
      const data = new window.google.visualization.DataTable();

      const inicioElev =
        parseInt((listaTrechosPn[0]?.elevacao / 100).toFixed(1), 10) * 100;

      data.addColumn('number', 'Distância');
      data.addColumn('number', `Elevação = ${inicioElev}+`);
      data.addColumn('number', 'Pressão');
      data.addColumn('number', 'PN');

      let dist = 0;

      for (let i = 0; i < listaTrechosPn.length; i += 1) {
        dist += listaTrechosPn[i].distancia;

        data.addRow([
          dist,
          listaTrechosPn[i].elevacao - inicioElev,
          listaTrechosPn[i].pFin,
          listaTrechosPn[i].pn,
        ]);
      }
      // Draw the chart using the data within its DIV.
      chart.draw(data, {
        height: 450,
        title: 'Pressão na Adutora (Sugerido)',
        legend: 'true',
        titleY: 'Pressao',
        titleX: 'Distância',
        curveType: 'line',
        series: {
          0: {
            type: 'area',
            color: 'blue',
            pointSize: 0,
            // lineSize: 0,
          },
        },
      });
    } else if (window.google) {
      const chartDiv = document.getElementById(`trecho_chart_${isBalsa}`);
      const chart = new window.google.visualization.ComboChart(chartDiv);
      const data = new window.google.visualization.DataTable();
      data.addColumn('number', 'distancia');
      data.addColumn('number', 'elevacao');
      data.addRow([0, 0]);
      chart.draw(data, {
        height: 450,
        title: `Não encontrada dados`,
      });
    }
    mostra_grafico(true);
  }

  async function calcularSugestoes(diametro: number) {
    setCalculandoTrechos(true);
    await carregarCamposElevacao(diametro);
    setCalculandoTrechos(false);
  }

  async function confirmInserirSugestoes() {
    if (sugestoes && sugestoes.length) {
      const confirmacao = await Swal.fire({
        title: `Deseja inserir as sugestões de trechos? Isso substituirá quaisquer trechos que tenham sido informados. Deseja prosseguir?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      });

      if (confirmacao.isConfirmed) {
        handlerInserirSugestoesLote(sugestoes);
      }
    }
  }

  useEffect(() => {
    if (listaTrechosPn?.length > 0) initChart();
  }, [listaTrechosPn]);

  useEffect(() => {
    if (
      !isBalsa &&
      diametroLigacaoSugestao &&
      tubos &&
      dadosBaseCarregados === true
    ) {
      // adutora.novo
      const primeiroTubo = adutoraTubulacaoTrechos?.filter(
        adutora => adutora.ordem === 1,
      );

      const diametroTubo = primeiroTubo?.[0]?.diametroNominal ?? 0;

      calcularSugestoes(
        diametroTubo !== 0 ? diametroTubo : diametroLigacaoSugestao,
      );
    }
  }, [isBalsa, diametroLigacaoSugestao, dadosBaseCarregados, tubos]);

  useEffect(() => {
    if (sugestoes && sugestoes.length > 0) {
      setMostrarBlocoSugestoes(true);
    }
  }, [sugestoes]);

  async function handleUpdateTipoBombaBalsa(tipo: string) {
    setLoadingCalculoAdutora(true);
    setTipoBomba(tipo);

    // Faz update
    const retorno = await api.put('/integracao/adutora/orcamento/tipo', {
      orcamentoId,
      fichaTecnicaId,
      isBalsa,
      tipoBomba: tipo,
    });

    // Deveria conferir a vazão nova, simular o cálculo da velocidade e marcar os itens errados.

    const vazaoNova = Number(retorno.data.novaVazao ?? 0);

    const { temErro, lista } = await handleRecalcularVelocidades(
      vazaoNova,
      adutoraTubulacaoTrechos,
    );

    // Se estiver tudo ok, prossegue com o cálculo.

    setLoadingCalculoAdutora(false);
    setAdutoraTubulacaoTrechos(lista);

    if (!temErro) {
      setIsNecessidadeReprocessamentoTrechos(false);
      await handleCalcularAdutoraTotal(tipo);
    } else {
      setIsNecessidadeReprocessamentoTrechos(true);
      setVazaoAplicar(vazaoNova);
      Swal.fire({
        icon: 'error',
        text: `A troca para ${tipo} deixa um ou mais trechos com velocidade excedente do limite!`,
      });
    }
  }

  return (
    <>
      <Card>
        <Row className="mt--3">
          <ColQuestaoTitulo>
            <LabelQuestaoTitulo>
              Trechos {isBalsa ? `da Balsa` : `da Adutora`}
            </LabelQuestaoTitulo>
          </ColQuestaoTitulo>
        </Row>

        <Card className="shadow" hidden={habilitarTrechos}>
          <Row>
            <Col className="mt-2 pb-4">
              Salve a adutora para habilitar o campo de trechos
            </Col>
          </Row>
        </Card>
        <Card className="shadow" hidden={!habilitarTrechos}>
          {!isBalsa && (
            <div className="div-sugestao">
              <Row>
                <Col sm="12" className="ml-2">
                  <h3>Visualizar Gráfico PN Adutora / Sugeridos</h3>
                </Col>
              </Row>
              <Row className="mb-2" hidden={mostrarBlocoSugestoes}>
                <Col sm="12" className="ml-2">
                  <p>Nenhuma sugestão de trecho disponível</p>
                </Col>
              </Row>
              <Row className="mb-2 ml-2" hidden={!mostrarBlocoSugestoes}>
                <Col sm="4">
                  <div id="segDiv" className="mt-4">
                    <Table id="segTab" style={{ margin: 'auto' }}>
                      <thead>
                        <tr>
                          <th>Comprim</th>
                          <th>Acc</th>
                          <th>Material</th>
                          <th>Cota</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sugestoes.map(seg => (
                          <tr id={`tab_sug_seg.distAcc_${seg.cotaFin}`}>
                            <td>{seg.distAcc}</td>
                            <td>{seg.totalAcc}</td>
                            <td>{seg.classePN}</td>
                            <td>{seg.cotaFin}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Button
                      disabled={inserindoTrechos || sugestoes.length === 0}
                      className="btn-icon btn-2 mr-3 float-right"
                      color="warning"
                      type="button"
                      size="sm"
                      title="Insere os trechos sugeridos"
                      onClick={confirmInserirSugestoes}
                    >
                      <FaCheckCircle /> Inserir sugeridos
                    </Button>
                  </div>
                </Col>
                <Col sm="7">
                  <div
                    id={`trecho_chart_${isBalsa}`}
                    className="mt--4"
                    style={{
                      marginTop: 0,
                      padding: 0,
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}

          <GenericModal
            title={modalTitulo}
            rows={rowsModal}
            modalState={modalStatusVisivel}
            toggle={() => setModalStatusVisivel(false)}
          />
          <Row>
            <Col sm="5" className="mt-2 ml-2 mb-2 mr--2">
              <div className="mb-4">
                <strong>Total {isBalsa ? `Balsa` : `Adutora`}:</strong>{' '}
                {adutoraTotal}m | <strong>Saldo: </strong>{' '}
                <span style={adutoraSaldo < 0 ? { color: '#FF0000' } : {}}>
                  {adutoraSaldo}m
                </span>
                {iniciarPEAD && (
                  <>
                    <br />
                    <i>
                      O saldo não considera o comprimento do primeiro trecho
                      PEAD
                    </i>
                  </>
                )}
              </div>
            </Col>
            <Col sm="7" className="mt-2 ml-2 mb-2 mr--2">
              <div className="mb-4">
                <ToggleButtonGroup
                  size="small"
                  value={tipoBomba}
                  exclusive
                  hidden={!isBalsa}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="Normal"
                    aria-label="Normal"
                    disabled={
                      loading || loadingCalculoAdutora || !podeCalcularAdutora
                    }
                    onClick={() => handleUpdateTipoBombaBalsa('Normal')}
                  >
                    Normal
                  </ToggleButton>
                  <ToggleButton
                    value="Paralelo"
                    aria-label="Paralelo"
                    disabled={
                      loading || loadingCalculoAdutora || !podeCalcularAdutora
                    }
                    onClick={() => handleUpdateTipoBombaBalsa('Paralelo')}
                  >
                    Paralelo
                  </ToggleButton>
                </ToggleButtonGroup>

                <Button
                  className="btn btn-outline-default mr-3 float-right"
                  type="button"
                  size="sm"
                  disabled={loading}
                  onClick={handleAdicionaNovaLinha}
                >
                  Novo <FaPlusCircle />
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="mt-2 ml-2 mb-2 mr--2">
              <Table bordered responsive>
                <thead>
                  <tr>
                    {headers.map((header, index) => (
                      <th hidden={header === 'hide'} key={index}>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {adutoraTubulacaoTrechos.map(tubulacao => {
                    const alterandoOutro = false;

                    const tuboFiltrado = tubos.find(
                      tubo => String(tubo.nome) === String(tubulacao.material),
                    );

                    const tuboSelecionar = tuboFiltrado?.seq ?? null;
                    /* adutoraTubulacaoTrechos.some(
              adutora =>
                (adutora.alterado || (adicionando && adutora.novo)) &&
                adutora.ordem !== tubulacao.ordem,
            ); */

                    const objetoConvertido = tubulacao as CalculoAdutora;

                    return (
                      <Tr key={tubulacao.ordem} alterado={tubulacao.alterado}>
                        <Td>
                          <strong>{tubulacao.ordem}º</strong>
                        </Td>
                        <Td editavel>
                          <TextField
                            disabled={alterandoOutro}
                            size="small"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={tubulacao.comprimento || ''}
                            onChange={event => {
                              onChangeAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'comprimento',
                                Number(event.target.value ?? 0),
                              );
                              setExibeTotais(false);
                              if (
                                tubulacao.ordem <= ajustarCotaOrdem ||
                                tubulacao.ordem === ajustarCotaOrdem + 1
                              )
                                setAjustarCotaOrdem(tubulacao.ordem);
                            }}
                            onBlur={event => {
                              onFocusOutAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'comprimento',
                                Number(event.target.value ?? 0),
                              );
                              setExibeTotais(false);
                            }}
                          />
                        </Td>
                        <Td>{tubulacao?.diametro}</Td>
                        <Td editavel>
                          <Select
                            disabled={alterandoOutro}
                            className="mt--5"
                            autoWidth
                            disableUnderline
                            value={tuboSelecionar}
                            onChange={event => {
                              onChangeAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'tubulacaoAdutoraId',
                                Number(event.target.value ?? 0),
                              );
                              setExibeTotais(false);
                            }}
                            onBlur={event => {
                              onFocusOutAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'tubulacaoAdutoraId',
                                Number(event.target.value ?? 0),
                              );
                              setExibeTotais(false);
                            }}
                          >
                            {tubos.map(tubo => (
                              <MenuItem key={tubo.seq} value={tubo.seq}>
                                {tubo.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </Td>
                        <Td>{tubulacao?.coeficiente}</Td>
                        <Td>{tubulacao?.vazao}</Td>
                        <Td>{tubulacao?.hfTotal}</Td>
                        <Td>
                          {tubulacao?.velocidade}{' '}
                          {tubulacao.velocidadeErro && (
                            <FaExclamationTriangle
                              color="red"
                              title={`Valor extrapola a velocidade máxima permitida de ${
                                tuboFiltrado?.velocidadeMaximaAgua ?? '0'
                              } m/s`}
                            />
                          )}
                        </Td>
                        <Td
                          editavel={
                            tubulacao.ordem === 1 && indicCotaEspecifica
                          }
                          hidden={!indicCotaEspecifica && !exibeCotas}
                        >
                          <TextField
                            disabled={
                              tubulacao.ordem !== 1 ||
                              (alterandoOutro && tubulacao.ordem === 1)
                            }
                            size="small"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={tubulacao.cotaInicial || ''}
                            onChange={event => {
                              onChangeAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'cotaInicial',
                                Number(event.target.value ?? 0),
                              );
                              setExibeTotais(false);
                              if (
                                tubulacao.ordem <= ajustarCotaOrdem ||
                                tubulacao.ordem === ajustarCotaOrdem + 1
                              )
                                setAjustarCotaOrdem(tubulacao.ordem);
                            }}
                            onBlur={event => {
                              onFocusOutAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'cotaInicial',
                                Number(event.target.value ?? 0),
                              );
                              setExibeTotais(false);
                            }}
                          />
                        </Td>
                        <Td
                          editavel
                          hidden={!indicCotaEspecifica && !exibeCotas}
                        >
                          <TextField
                            disabled={alterandoOutro}
                            size="small"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={tubulacao.cotaFinal || ''}
                            onChange={event => {
                              onChangeAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'cotaFinal',
                                Number(event.target.value ?? 0),
                              );
                              setExibeTotais(false);
                            }}
                            onBlur={event => {
                              onFocusOutAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'cotaFinal',
                                Number(event.target.value ?? 0),
                              );
                              setExibeTotais(false);
                            }}
                          />

                          <Button
                            disabled={loading}
                            className="btn-icon btn-2"
                            color={
                              tubulacao.ordem > ajustarCotaOrdem
                                ? 'warning'
                                : 'info'
                            }
                            hidden={tubulacao.ordem <= ajustarCotaOrdem}
                            type="button"
                            size="sm"
                            title="Busca elevação trecho Adutora"
                            onMouseDown={() => {
                              if (listaTrechosPn.length > 0) {
                                onChangeAdutoraTubulacaoOrcamento(
                                  tubulacao.ordem,
                                  'comprimento',
                                  Number(tubulacao.comprimento ?? 0),
                                );
                                setExibeTotais(false);
                                if (
                                  tubulacao.ordem <= ajustarCotaOrdem ||
                                  tubulacao.ordem === ajustarCotaOrdem + 1
                                )
                                  setAjustarCotaOrdem(tubulacao.ordem);
                              } else {
                                toast(
                                  `Nessecita carregar o gráfico PN Adutora Sugeridos!`,
                                  {
                                    icon: '⚠️',
                                  },
                                );
                              }
                            }}
                            onMouseUp={event => {
                              onFocusOutAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'cotaFinal',
                                Number(tubulacao.cotaFinal ?? 0),
                              );
                              setExibeTotais(false);
                            }}
                          >
                            <FaBuffer />
                          </Button>
                        </Td>
                        <Td>
                          <Button
                            disabled={loading}
                            className="btn-icon btn-2"
                            color="info"
                            type="button"
                            size="sm"
                            onClick={() => {
                              onChangeAdutoraTubulacaoOrcamento(
                                tubulacao.ordem,
                                'comprimento',
                                Number(tubulacao.comprimento ?? 0),
                              );
                              mostrarModalTubulacao(objetoConvertido);
                            }}
                          >
                            <FaInfoCircle />
                          </Button>

                          <Button
                            disabled={loading}
                            className="btn-icon btn-2"
                            color="danger"
                            type="button"
                            size="sm"
                            onClick={() => deleteTubulacao(tubulacao)}
                          >
                            <FaTrash />
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <ContainerButtons>
            <Button
              className="btn-icon btn-2"
              color="danger"
              disabled={
                loading || loadingCalculoAdutora || !podeCalcularAdutora
              }
              type="button"
              onClick={handlePreValidar}
              size="sm"
            >
              {loadingCalculoAdutora ? 'Calculando...' : 'Calcular'}
            </Button>
            {isNecessidadeReprocessamentoTrechos && <Reprocessamento />}
          </ContainerButtons>

          <Row>
            <Col sm="12" className="mt-2 ml-2 mb-2 mr--2">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Trecho</th>
                    <th hidden={!indicCotaEspecifica}>Cota inicial</th>
                    <th hidden={!indicCotaEspecifica}>Cota final</th>
                    <th>Diâmetro Nominal</th>
                    <th>Pressão no início</th>
                    <th>Pressão no final</th>
                    <th>Classe</th>
                  </tr>
                </thead>
                <tbody>
                  {adutoraTubulacaoTrechos.map(perfilAdutora => (
                    <tr key={perfilAdutora.ordem}>
                      <Td>{perfilAdutora.ordem}º</Td>
                      <Td hidden={!indicCotaEspecifica}>
                        {perfilAdutora.cotaInicial}
                      </Td>
                      <Td hidden={!indicCotaEspecifica}>
                        {perfilAdutora.cotaFinal}
                      </Td>
                      <Td>{perfilAdutora.diametroNominal}</Td>
                      <Td>{perfilAdutora.pressaoInicio}</Td>
                      <Td>{perfilAdutora.pressaoFinal}</Td>
                      <Td>{perfilAdutora.classe}</Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="mt-2 ml-2 mb-2 mr--2">
              {!isNecessidadeReprocessamentoTrechos &&
              adutoraTubulacaoTrechos.length > 0 ? (
                <Row>
                  <Col className="mt-2 ml-2 mb-2 mr--2">
                    <Table
                      bordered
                      responsive
                      style={{ width: '50%', margin: 'auto' }}
                    >
                      <thead>
                        <tr>
                          <th>Vazão Total</th>
                          <th>Pressão Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <Td>
                            {fichaTecnicaBombeamento.filter(
                              item => item.isBalsa === isBalsa,
                            )?.[0]?.vazaoPrevista ?? ''}
                            &nbsp;m³/h
                          </Td>
                          <Td>
                            {isBalsa
                              ? fichaTecnicaAdutora?.pressaoTotalBalsa ?? ''
                              : fichaTecnicaAdutora?.pressaoTotal ?? ''}
                            &nbsp;mca
                          </Td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Card>
      </Card>
    </>
  );
}

export default Tubulacao;

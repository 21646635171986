import FichaTecnica from 'models/FichaTecnica';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Col, Row, Table } from 'reactstrap';
import api from 'services/api';

import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
} from '@material-ui/core';

import { FichaTecnicaBombeamento } from 'models/FichaTecnicaBombeamento';
import BombaModel from 'models/ConfiguradorBomba';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import toast from 'react-hot-toast';
import { FaCheckCircle, FaGlobe } from 'react-icons/fa';
import FuncaoEntrada from 'models/FuncaoEntrada';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import Swal from 'sweetalert2';
import { ContainerRow, ContainerButtons, TextField } from './styles';
import { AdutoraBombeamentoNewContext } from '../..';
import { Td } from '../Adutora/styles';
import Reprocessamento from '../Reprocessamento';
import './style.css';

declare let window: any;

interface inserirDados {
  fabricante: string;
  bomba: string;
  rendimento: number;
  npsh: number;
  ocupacaoCurva: number;
  amplitude: number;
  diametroRotores: number;
  validar: boolean;
}

interface Props {
  bombas: any[];
  fabricantes: any[];
  tipoBombaFiltrar: string;
  vazaoAlternativa: number;
  pressaoAlternativa: number;
  isBalsa: boolean;
  vazaoPrevista: number;
  pressaoPrevista: number;
  handleInserir: (value: inserirDados) => void;
  modeloBomba: string;
  modeloBombaSelecionado: string;
}

interface CurvasBomba {
  seq: number;
  modelo: string;
  fabricante: string;
  faixaVazaoInicial: number | 0;
  faixaVazaoFinal: number | 0;
  faixaAlturaInicial: number | 0;
  faixaAlturaFinal: number | 0;
  seqModeloBomba: number | 0;
  vazao: number | 0;
  alturaInicial: number | 0;
  alturaFinal: number | 0;
  rendAlturaInicial: number | 0;
  rendAltura33p: number | 0;
  rendAltura66p: number | 0;
  rendAlturaFinal: number | 0;
  npshAlturaInicial: number | 0;
  npshAltura33p: number | 0;
  npshAltura66p: number | 0;
  npshAlturaFinal: number | 0;
  rotorAlturaInicial: number | 0;
  rotorAltura33p: number | 0;
  rotorAltura66p: number | 0;
  rotorAlturaFinal: number | 0;
  // calculos
  calcNpsh: number | 0;
  calcRend: number | 0;
  calcRotor: number | 0;
  // Adicionais Jun24
  indicTipo: string;
  codigo: string;
  valor: number | 0;
}

interface BombaSugeria {
  seq: number;
  modelo: string;
  fabricante: string;
  seqModeloBomba: number;
  alturaDesej: number | 0;
  vazaoDesej: number | 0;
  valido: boolean | false;
  calcAlturaInicial: number | 0;
  calcAlturaFinal: number | 0;
  calcNpsh: number | 0;
  calcRend: number | 0;
  calcRotor: number | 0;
  calcPercOcupacao: number | 0;
  curvas: CurvasBomba[];
  calcPotencia: number | 0;
  valor: number | 0;
  indicTipo: string;
  aplicacao: string;
  grafico: any[];
  leadTime: number | 0;
  vlrUnitario: number | 0;
  potencia: number | 0;
  modeloBase: string | '';
}

interface LocationState {
  fichaTecnica: FichaTecnica;
  orcamentoId: number;
  orcamentoParteId: number;
}

interface Bomba {
  id: number;
  nome: string;
  urlFabricante?: string;
  indicAtivo: string;
}

interface ModeloBomba {
  seq: number;
  nome: string;
  indicAtivo?: string;
  tipoBomba?: string;
}

interface OpcaoEletricaBombeamento {
  tensaoNominal: number;
  bitolaCabo: number;
  quedaTensao: number;
}

interface iProps {
  isBalsa: boolean;
}

interface CurvasBomba {
  seq: number;
  modelo: string;
  fabricante: string;
  faixaVazaoInicial: number | 0;
  faixaVazaoFinal: number | 0;
  faixaAlturaInicial: number | 0;
  faixaAlturaFinal: number | 0;
  seqModeloBomba: number | 0;
  vazao: number | 0;
  alturaInicial: number | 0;
  alturaFinal: number | 0;
  rendAlturaInicial: number | 0;
  rendAltura33p: number | 0;
  rendAltura66p: number | 0;
  rendAlturaFinal: number | 0;
  npshAlturaInicial: number | 0;
  npshAltura33p: number | 0;
  npshAltura66p: number | 0;
  npshAlturaFinal: number | 0;
  rotorAlturaInicial: number | 0;
  rotorAltura33p: number | 0;
  rotorAltura66p: number | 0;
  rotorAlturaFinal: number | 0;
  // calculos
  calcNpsh: number | 0;
  calcRend: number | 0;
  calcRotor: number | 0;
  // novos
  leadTime: number | 0;
  vlrUnitario: number | 0;
  potencia: number | 0;
}

// interface BombaSugeria {
//   seq: number;
//   modelo: string;
//   fabricante: string;
//   seqModeloBomba: number;
//   alturaDesej: number | 0;
//   vazaoDesej: number | 0;
//   valido: boolean | false;
//   calcAlturaInicial: number | 0;
//   calcAlturaFinal: number | 0;
//   calcNpsh: number | 0;
//   calcRend: number | 0;
//   calcRotor: number | 0;
//   calcPercOcupacao: number | 0;
//   leadTime: number | 0;
//   vlrUnitario: number | 0;
//   potencia: number | 0;
//   curvas: CurvasBomba[];
//   grafico: any[];
//   modeloBase: string | '';
// }

function Bombeamento({ isBalsa }: iProps): JSX.Element {
  const {
    orcamentoId,
    fichaTecnica,
    sessaoId,
    respostaFuncaoEntrada,
    fichaTecnicaBombeamento: fichaTecnicaBombeamentoContext,
    fichaTecnicaAdutora,
    necessidadeReprocessamento,
    fichaTecnicaId,
    atualizarFuncoes,
    acessoProgramas,
    orcamentoInfoBalsa,
    atualizarBombeamento,
    atualizarBombeamentoBalsa,
  } = useContext(AdutoraBombeamentoNewContext);

  const location = useLocation<LocationState>();
  const { fichaTecnica: fichaTecnicaDados } = location.state;

  window.google.load('visualization', '1', {
    packages: ['columnchart', 'corechart', 'table'],
  });

  const fichaTecnicaBombeamento = fichaTecnicaBombeamentoContext?.find(
    (item: any) => item.isBalsa === isBalsa,
  ) as FichaTecnicaBombeamento;

  const RENDIMENTO_DEFAULT = 75;
  const ENERGIA_BOMBEAMENTO_DEFAULT = 'Conjunta';
  const MOTOR_DEFAULT = 'Elétrico';

  const [marcasBomba, setMarcasBomba] = useState<Bomba[]>([]);
  const [modelosBomba, setModelosBomba] = useState<ModeloBomba[]>([]);

  const [marcaBombaSelecionada, setMarcaBombaSelecionada] = useState('');
  const [carregandoModelosBomba, setCarregandoModelosBomba] = useState(false);
  const [comboMarcas, setComboMarcas] = useState<Bomba[]>([]);
  const [modeloBombaSalva, setModeloBombaSalva] = useState('');
  const [modeloBombaSelecionado, setModeloBombaSelecionado] = useState('');
  const [vazaoAlternativa, setVazaoAlternativa] = useState(0);
  const [pressaoAlternativa, setPressaoAlternativa] = useState(0);
  const [rendimento, setRendimento] = useState(RENDIMENTO_DEFAULT);
  const [energiaBombeamento, setEnergiaBombeamento] = useState(
    ENERGIA_BOMBEAMENTO_DEFAULT,
  );
  const [motor, setMotor] = useState(MOTOR_DEFAULT);
  const [potenciaNominal, setPotenciaNominal] = useState(0);

  const [tipoBombaFiltrar, setTipoBombaFiltrar] = useState('P');
  const [diametroSuccaoEntrada, setDiametroSuccaoEntrada] = useState('');
  const [diametroRecalqueSaida, setDiametroRecalqueSaida] = useState('');
  const [BombaTipoFlange, setBombaTipoFlange] = useState('');
  const [fabricanteSelecionado, setfabricanteSelecionado] = useState('');
  const [isUrlFabricante, setIsUrlFabricante] = useState(false);
  const [numeroEstagios, setNumeroEstagios] = useState(0);
  const [diametroEstimadoRotores, setDiametroEstimadoRotores] = useState(0);
  const [potenciaAbsorvidaEixoBomba, setPotenciaAbsorvidaEixoBomba] =
    useState(0);
  const [potenciaCasaMaquina, setPotenciaCasaMaquina] = useState(0);

  const [loadingCalculoBombeamento, setLoadingCalculoBombeamento] =
    useState(false);
  const [fichaTecnicaDadosBombeamento, setFichaTecnicaDadosBombeamento] =
    useState({} as FichaTecnicaBombeamento);
  const [funcaoEntradas, setFuncaoEntradas] = useState<FuncaoEntrada[]>();
  const [bombasSugeridas, setbombasSugeridas] = useState<BombaSugeria[]>([]); // ovidio
  const [semSugestaoBomba, setSemSugestaoBomba] = useState(true);

  const [ficahBombe, setFichaTecnicaBombeamento] = useState<any>();

  const [acessoPrograma, setAcessoPrograma] = useState(
    {} as ValidarAcessoPrograma,
  );

  const [vazaoPrevista, setVazaoPrevista] = useState(0);
  const [pressaoPrevista, setPressaoPrevista] = useState(0);

  // NPSH
  const [amplitudeCurva, setAmplitudeCurva] = useState(0);
  const [npshRequerido, setNpshRequerido] = useState(0);
  const [ocupacaoCurva, setOcupacaoCurva] = useState(0);
  const [acessoLimitadoEditaNpsh, setAcessoLimitadoEditaNpsh] = useState(false);
  const [bombas, setBombas] = useState([] as BombaModel[]);

  const [bomba, setBomba] = useState('');
  const [quantidadeModulos, setQuantidadeModulos] = useState(1);
  const [quantidadeBomba, setQuantidadeBomba] = useState(0);
  const [renderData, setRenderData] = useState(false);

  const [necessarioReprocessar, setIsNecessarioReprocessar] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [limparDados, setLimparDados] = useState(false);
  const isFirstRender = useRef(true);

  const [sugestoes, setSugestoes] = useState<any[]>([]);
  const [mostrarBlocoSugestoes, setMostrarBlocoSugestoes] = useState(false);
  const [calculandoSugestao, setCalculandoSugestao] = useState(false);
  const [calculoSugestaoDisparado, setCalculoSugestaoDisparado] =
    useState(false);
  const [inserindoSugestao, setinserindoSugestao] = useState(false);
  const [acimaLimiteCurva, setAcimaLimiteCurva] = useState(false);
  const [isInfinity, setIsInfinity] = useState(false);
  const [sugestaoSelecionada, setSugestaoSelecionada] = useState<any>({});
  const debounceTimeoutRef = useRef<number | undefined>();

  const [atualizarSugestoes, setAtualizarSugestoes] = useState(false);

  const [alteradoManualmente, setAlteradoManualmente] = useState(false);

  useEffect(() => {
    if (orcamentoInfoBalsa) {
      const balsa = orcamentoInfoBalsa?.isBalsa ?? false;
      const auxi = orcamentoInfoBalsa?.isBalsaAuxiliar ?? false;

      let tipoFiltrar = 'P';
      if (balsa) {
        if (auxi) {
          if (isBalsa) tipoFiltrar = 'B';
        } else {
          // Não tem bomba auxiliar. Então vai filtrar pelo B
          tipoFiltrar = 'B';
        }
      }
      setTipoBombaFiltrar(tipoFiltrar);
    }
  }, [orcamentoInfoBalsa]);

  useEffect(() => {
    if (necessidadeReprocessamento) {
      const isNecessidadeReprocessamento = isBalsa
        ? necessidadeReprocessamento?.reprocessarBombeamentoBalsa
        : necessidadeReprocessamento?.reprocessarBombeamento;

      setIsNecessarioReprocessar(isNecessidadeReprocessamento);
    }
  }, [necessidadeReprocessamento]);

  useEffect(() => {
    if (acessoProgramas && acessoProgramas.length) {
      const acessoProgramaNpsh = acessoProgramas?.find(
        item => item.programa === 78,
      ) ?? {
        acessoLimitado: false,
        temAcesso: false,
      };

      setAcessoLimitadoEditaNpsh(acessoProgramaNpsh.acessoLimitado);
    }
  }, [acessoProgramas]);

  async function carregaMarcasBombas() {
    const response = await api.get('/integracao/bombas/fabricantes');
    setMarcasBomba(
      response.data.filter(
        (item: { indicAtivo: string }) => item.indicAtivo === 'S',
      ),
    );
  }

  async function listarBombas() {
    const response = await api.get('/integracao/bombas');

    setBombas(response.data);
  }

  useEffect(() => {
    listarBombas();
  }, []);

  async function carregaModelosBombas() {
    const response = await api.get('/integracao/bombas/todos-modelos');

    setModelosBomba(
      response.data.filter(
        (item: { indicAtivo: string }) => item.indicAtivo === 'S',
      ),
    );
  }

  async function calculaEletricaBombeamentoBalsa() {
    const response = await api.post(
      '/integracao//bombeamento/eletrica-balsa/calculo',
      {
        sessao: sessaoId,
        orcamentoId,
        fichaTecnicaId,
      },
    );

    return response.data as OpcaoEletricaBombeamento[];
  }

  async function calculaEletricaBombeamento() {
    const response = await api.post(
      '/integracao/bombeamento/eletrica-subterranea/calculo',
      {
        sessao: sessaoId,
        orcamentoId,
        fichaTecnicaId,
        isBalsa,
      },
    );

    return response.data as OpcaoEletricaBombeamento[];
  }

  async function calculaTransformadorBombeamento() {
    await api.post('/integracao/bombeamento/transformador/calculo', {
      sessao: sessaoId,
      orcamentoId,
      fichaTecnicaId,
      isBalsa,
    });
  }

  async function calculaTransformadorBombeamentoGeral() {
    await api.post('/integracao/bombeamento/transformador/calculo-ambos', {
      sessao: sessaoId,
      orcamentoId,
      fichaTecnicaId,
    });
  }

  async function calculaBombeamento() {
    try {
      setLoadingCalculoBombeamento(true);
      const bombaFiltrada = bombas?.filter(
        item =>
          String(item.nome).toUpperCase() ===
          String(modeloBombaSelecionado).toUpperCase(),
      );
      const numeroPolos = bombaFiltrada?.find(item => item)?.nroPolos;
      const diteCodigo = bombaFiltrada?.find(item => item)?.ditbDiteCodigo;

      const response = await api.post(
        '/integracao/bombeamento/orcamento/calculo',
        {
          sessao: sessaoId,
          orcamentoId,
          fichaTecnicaId,
          bomba,
          quantidadeBomba,
          marcaBomba: marcaBombaSelecionada,
          modeloBomba: modeloBombaSelecionado,
          quantidadeModulos,
          vazaoAlternativa,
          pressaoAlternativa,
          rendimento,
          energiaBombeamento,
          motor,
          potenciaNominal,
          numeroEstagios,
          diametroEstimadoRotores,
          // npsh
          amplitudeCurva,
          npshRequerido,
          ocupacaoCurva,
          numeroPolos,
          diteCodigo,
          isBalsa,
          alteradoManualmente,
          reprocessar: false,
        },
      );

      const balsa = orcamentoInfoBalsa?.isBalsa ?? false;
      const auxi = orcamentoInfoBalsa?.isBalsaAuxiliar ?? false;
      const mesmaCasa = fichaTecnica?.mesmaCasaMaquinas ?? false;

      if (isBalsa) {
        await calculaEletricaBombeamentoBalsa();

        await calculaTransformadorBombeamento();
      } else {
        await calculaTransformadorBombeamento();

        await calculaEletricaBombeamento();
      }

      if (!isBalsa && balsa && auxi && mesmaCasa) {
        // Se tiver balsa, e for auxiliar, e na mesma casa de máuqinas,
        // Dsiparamos o outro processo
        await calculaTransformadorBombeamentoGeral();

        await calculaEletricaBombeamento();
      }

      // No final, atualiza as funçções
      atualizarFuncoes([`BOMBEAMENTO${isBalsa ? '_BALSA' : ''}`], false);

      toast.success('Cálculo finalizado!', { position: 'top-right' });
    } finally {
      setLoadingCalculoBombeamento(false);
    }
  }

  useEffect(() => {
    // Atualiza as bombas de acordo com o fabricante selecionado
    if (marcaBombaSelecionada && marcasBomba.length) {
      const urlMarcaSelecionada = marcasBomba?.find(
        marcaBomba => marcaBomba.nome === marcaBombaSelecionada,
      )?.urlFabricante;

      if (!urlMarcaSelecionada) {
        setIsUrlFabricante(false);
        return;
      }

      setfabricanteSelecionado(urlMarcaSelecionada);
      setIsUrlFabricante(true);
    }
  }, [marcaBombaSelecionada, marcasBomba]);

  async function carregarRespostaFuncao() {
    if (respostaFuncaoEntrada) {
      const { fonteEnergia } = respostaFuncaoEntrada;

      if (fonteEnergia) {
        const fonteUpper = String(fonteEnergia).toUpperCase();
        let energiaUsar = ENERGIA_BOMBEAMENTO_DEFAULT;

        if (fonteUpper === 'BOMBEAMENTO') energiaUsar = 'Conjunta';
        else if (fonteUpper.includes('RAIO')) energiaUsar = 'Individual';

        // Fonte de Energia = Bombeamento → Energia bombeamento = Conjunta
        // Fonte de Energia = Raio Pivô → Energia bombeamento = Individual
        setEnergiaBombeamento(energiaUsar);
      }
    }
  }

  useEffect(() => {
    if (renderData === true) {
      setLoadingPage(true);
      setAtualizarSugestoes(false);
      if (fichaTecnicaBombeamento) {
        setPotenciaAbsorvidaEixoBomba(
          Number(fichaTecnicaBombeamento?.potenciaAbsorvidaEixoBomba),
        );
        setPotenciaCasaMaquina(
          Number(fichaTecnicaBombeamento?.potenciaCasaMaquina),
        );

        setFichaTecnicaBombeamento(fichaTecnicaBombeamento);

        setFichaTecnicaDadosBombeamento(fichaTecnicaBombeamento);

        if (
          fichaTecnicaBombeamentoContext?.find(
            (item: any) => item.isBalsa === isBalsa,
          )
        ) {
          const bombeamento = fichaTecnicaBombeamentoContext?.find(
            (item: any) => item.isBalsa === isBalsa,
          ) as FichaTecnicaBombeamento;
          setVazaoPrevista(bombeamento?.vazaoPrevista ?? 0);
        }

        const {
          marcaBomba: ftMarcaBomba,
          motor: ftMotor,
          potenciaNominal: ftPotenciaNominal,
          pressaoAlternativa: ftPressaoAlternativa,
          vazaoAlternativa: ftVazaoAlternativa,
          rendimento: ftRendimento,
          quantidadeModulos: ftQuantidadeModulos,
          diametroSuccaoEntrada: ftDiametroSuccaoEntrada,
          diametroRecalqueSaida: ftDiametroRecalqueSaida,
          bombaTipoFlange: ftBombaTipoFlange,
          numeroEstagios: ftNumeroEstagios,
          diametroEstimadoRotores: ftDiametroEstimadoRotores,
          // npsh
          amplitudeCurva: ftAmplitudeCurva,
          npshRequerido: ftNpshRequerido,
          ocupacaoCurva: ftOcupacaoCurva,
          modeloBomba,
          alteradoManualmente: ftAtualizadoManualmente,
          bomba: ftBomba,
          quantidadeBomba: ftQuantidadeBomba,
        } = fichaTecnicaBombeamento;

        setMarcaBombaSelecionada(ftMarcaBomba || '');
        setModeloBombaSelecionado(modeloBomba || '');
        setModeloBombaSalva(modeloBomba || '');

        setMotor(ftMotor || MOTOR_DEFAULT);
        setPotenciaNominal(ftPotenciaNominal || 0);
        setPressaoAlternativa(ftPressaoAlternativa || 0);
        setVazaoAlternativa(ftVazaoAlternativa || 0);
        setRendimento(ftRendimento || RENDIMENTO_DEFAULT);
        setQuantidadeModulos(ftQuantidadeModulos || 1);
        setNumeroEstagios(ftNumeroEstagios || 1);
        setDiametroEstimadoRotores(ftDiametroEstimadoRotores || 0);
        setDiametroRecalqueSaida(
          ftDiametroRecalqueSaida ? String(ftDiametroRecalqueSaida) : '',
        );
        setDiametroSuccaoEntrada(
          ftDiametroSuccaoEntrada ? String(ftDiametroSuccaoEntrada) : '',
        );
        setBombaTipoFlange(ftBombaTipoFlange || '');

        setAlteradoManualmente(ftAtualizadoManualmente);

        // NPSH
        setAmplitudeCurva(ftAmplitudeCurva || 0);
        setNpshRequerido(ftNpshRequerido || 0);
        setOcupacaoCurva(ftOcupacaoCurva || 0);

        setBomba(ftBomba ?? '');
        setQuantidadeBomba(ftQuantidadeBomba ?? 0);
      }

      if (fichaTecnicaAdutora) {
        const varBomba = fichaTecnicaAdutora.bomba ?? '';
        const varQuantidade = fichaTecnicaAdutora.quantidadeBomba ?? 0;

        if (!fichaTecnicaBombeamento) {
          setBomba(varBomba);
          setQuantidadeBomba(varQuantidade);
        }

        setPressaoPrevista(
          isBalsa
            ? fichaTecnicaAdutora?.pressaoTotalBalsa ?? 0
            : fichaTecnicaAdutora?.pressaoTotal ?? 0,
        );
      }

      // Setar processo para disparar o cálculo de sugestões
      setAtualizarSugestoes(true);

      setRenderData(false);
      setLoadingPage(false);
    }
  }, [renderData]);

  async function carregarDados() {
    await carregaMarcasBombas();
    await carregaModelosBombas();
    await carregarRespostaFuncao();

    setRenderData(true);
  }

  useEffect(() => {
    if (fichaTecnica) {
      carregarDados();
    }
  }, [fichaTecnica]);

  async function handleInserirSugestao(item: any) {
    let atualizarDados = true;
    if (modeloBombaSelecionado === item.bomba) {
      /* console.log(`Item da Balsa: ${isBalsa}`);
      console.log(
        `amplitudeCurva: ${amplitudeCurva} = ${item.amplitude} ${
          amplitudeCurva !== item.amplitude
        }`,
      );
      console.log(
        `ocupacaoCurva: ${ocupacaoCurva} = ${item.ocupacaoCurva} ${
          ocupacaoCurva !== item.ocupacaoCurva
        }`,
      );
      console.log(
        `npshRequerido: ${npshRequerido} = ${item.npsh} ${
          npshRequerido !== item.npsh
        }`,
      );
      console.log(
        `rendimento: ${rendimento} = ${Number(item.rendimento ?? 0)} ${
          rendimento !== Number(item.rendimento ?? 0)
        }`,
      );
      console.log(
        `diametroEstimadoRotores: ${diametroEstimadoRotores} = ${
          item.diametroRotores
        } ${diametroEstimadoRotores !== item.diametroRotores}`,
      ); */

      /* if (
        (amplitudeCurva > 0 && amplitudeCurva !== item.amplitude) ||
        (ocupacaoCurva > 0 && ocupacaoCurva !== item.ocupacaoCurva) ||
        (npshRequerido > 0 && npshRequerido !== item.npsh) ||
        (rendimento > 0 && rendimento !== Number(item.rendimento ?? 0)) ||
        (diametroEstimadoRotores > 0 &&
          diametroEstimadoRotores !== item.diametroRotores)
      ) */

      if (
        amplitudeCurva !== item.amplitude ||
        ocupacaoCurva !== item.ocupacaoCurva ||
        npshRequerido !== item.npsh ||
        rendimento !== Number(item.rendimento ?? 0) ||
        diametroEstimadoRotores !== item.diametroRotores
      ) {
        atualizarDados = true;
      } else {
        atualizarDados = false;
      }
    }

    if (atualizarDados) {
      setAmplitudeCurva(item.amplitude);
      setNpshRequerido(item.npsh);
      setOcupacaoCurva(item.ocupacaoCurva);
      setRendimento(Number(item.rendimento ?? 0));
      setDiametroEstimadoRotores(item.diametroRotores);

      if (marcaBombaSelecionada !== item.fabricante)
        setMarcaBombaSelecionada(item.fabricante);

      if (modeloBombaSelecionado !== item.bomba)
        setModeloBombaSelecionado(item.bomba);

      if (item.alertar === true) {
        setIsNecessarioReprocessar(true);
        /* toast.success(
          `${
            isBalsa === true ? `[Balsa] ` : ``
          }Valores da sugestão de bomba foram inclusos no bloco de bombeamento${
            isBalsa === true ? ` da Balsa` : ``
          }. Por favor, efetue o cálculo novamente para salvar as informações.`,
        ); */
      }
    }
  }

  useEffect(() => {
    if (limparDados) {
      setAmplitudeCurva(0);
      setNpshRequerido(0);
      setOcupacaoCurva(0);
      setRendimento(0);
      setDiametroEstimadoRotores(0);
      setIsNecessarioReprocessar(true);
      setLimparDados(false);
    }
  }, [limparDados]);

  function newBomba() {
    const curvas: CurvasBomba[] = [];
    const grafico: any[] = [];
    return {
      seq: 0,
      modelo: '',
      fabricante: '',
      seqModeloBomba: 0,
      alturaDesej: 0,
      vazaoDesej: 0,
      valido: false,
      calcAlturaInicial: 0,
      calcAlturaFinal: 0,
      calcNpsh: 0,
      calcRend: 0,
      calcRotor: 0,
      calcPercOcupacao: 0,
      leadTime: 0,
      vlrUnitario: 0,
      potencia: 0,
      curvas,
      valor: 0,
      indicTipo: '',
      aplicacao: '',
      grafico,
      modeloBase: '',
    };
  }

  function cloneJson(estrutura: any) {
    if (estrutura) {
      const varString = JSON.stringify(estrutura);
      return JSON.parse(varString);
    }
    return null;
  }

  function getGraficoBomba(bombaAnalise: BombaSugeria) {
    const cc: CurvasBomba[] = bombaAnalise.curvas;
    // let chartbb: any[] = [];
    const ponto: any = null;
    const chartbb = cc.map(
      ({
        vazao,
        alturaInicial,
        alturaFinal,
        calcNpsh,
        calcRend,
        calcRotor,
      }) => ({
        vazao,
        alturaInicial,
        alturaFinal,
        calcNpsh,
        calcRend,
        calcRotor,
        ponto,
      }),
    );

    chartbb.push({
      vazao: bombaAnalise.vazaoDesej,
      alturaInicial: bombaAnalise.calcAlturaInicial,
      alturaFinal: bombaAnalise.calcAlturaFinal,
      calcNpsh: bombaAnalise.calcNpsh,
      calcRend: bombaAnalise.calcRend,
      calcRotor: bombaAnalise.calcRotor,
      ponto: bombaAnalise.alturaDesej,
    });

    return chartbb;
  }

  function getAreaVariavel(bombaAnalise: BombaSugeria, variavel: string) {
    const bombaSug: BombaSugeria = cloneJson(bombaAnalise);

    const { alturaDesej, calcAlturaInicial, calcAlturaFinal, curvas } =
      bombaSug;

    let AltIni = calcAlturaInicial;
    const AltBase = AltIni;
    let AltFim = calcAlturaFinal;
    const ppAlt = AltFim - AltIni;
    let percAlt = (alturaDesej - AltIni) / ppAlt;

    let rab: any[] = [];
    const ra33: any[] = [];
    const ra66: any[] = [];
    let raa: any[] = [];

    for (let i = 0; i < curvas.length; i += 1) {
      if (variavel === 'npsh') {
        rab.push(curvas[i].npshAlturaInicial);
        ra33.push(curvas[i].npshAltura33p);
        ra66.push(curvas[i].npshAltura66p);
        raa.push(curvas[i].npshAlturaFinal);
      }

      if (variavel === 'rotor') {
        rab.push(curvas[i].rotorAlturaInicial);
        ra33.push(curvas[i].rotorAltura33p);
        ra66.push(curvas[i].rotorAltura66p);
        raa.push(curvas[i].rotorAlturaFinal);
      }

      if (variavel === 'rend') {
        rab.push(curvas[i].rendAlturaInicial);
        ra33.push(curvas[i].rendAltura33p);
        ra66.push(curvas[i].rendAltura66p);
        raa.push(curvas[i].rendAlturaFinal);
      }
    }

    if (percAlt <= 0.3333) {
      raa = ra33;
      AltFim = 0.3333 * ppAlt + AltBase;
    }
    if (percAlt > 0.3333 && percAlt <= 0.6666) {
      AltFim = 0.6666 * ppAlt + AltBase;
      AltIni = 0.3333 * ppAlt + AltBase;
      rab = ra33;
      raa = ra66;
    }
    if (percAlt > 0.6666) {
      AltIni = 0.6666 * ppAlt + AltBase;
      rab = ra66;
    }

    const pontosAlt = AltFim - AltIni;
    percAlt = (alturaDesej - AltIni) / pontosAlt;

    for (let k = 0; k < curvas.length; k += 1) {
      const xfluxo = curvas[k].vazao;

      if (xfluxo > 0) {
        const xraa = raa[k];
        const xrab = rab[k];
        if (variavel === 'rend')
          bombaSug.curvas[k].calcRend = (xraa - xrab) * percAlt + xrab;
        if (variavel === 'npsh')
          bombaSug.curvas[k].calcNpsh = (xraa - xrab) * percAlt + xrab;
        if (variavel === 'rotor')
          bombaSug.curvas[k].calcRotor = (xraa - xrab) * percAlt + xrab;
      }
    }

    return bombaSug;
  }

  function calcularPercentuais(arr: number[]): number[] {
    const valoresFiltrados = arr;
    const soma = valoresFiltrados.reduce((acc, valor) => acc + valor, 0);
    const percentuais = valoresFiltrados.map(valor => (valor / soma) * 100);
    return percentuais;
  }

  function rgbParaHex(r: number, g: number, b: number): string {
    const componenteParaHex = (componente: number) => {
      const hex = componente.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };

    return `#${componenteParaHex(r)}${componenteParaHex(g)}${componenteParaHex(
      b,
    )}`;
  }

  function interpolarCor(percentual: number): string {
    const verde = { r: 0, g: 255, b: 0 };
    const vermelho = { r: 255, g: 0, b: 0 };

    const r = Math.round(
      vermelho.r * (percentual / 100) + verde.r * (1 - percentual / 100),
    );
    const g = Math.round(
      vermelho.g * (percentual / 100) + verde.g * (1 - percentual / 100),
    );
    const b = Math.round(
      vermelho.b * (percentual / 100) + verde.b * (1 - percentual / 100),
    );

    return rgbParaHex(r, g, b);
  }

  function calcularCores(arr: number[]): string[] {
    const percentuais = calcularPercentuais(arr);
    const cores = percentuais.map(interpolarCor);
    return cores;
  }

  function getAreaBomba(bombaAnalise: BombaSugeria) {
    const bombaSug: BombaSugeria = cloneJson(bombaAnalise);
    let ppAltInic: number;
    let ppAltFin: number;
    let ppNpsh: number;
    let ppRend: number;
    let ppRotor: number;
    let ppvazao: number;

    let fxAltInic: number;
    let fxAltFin: number;
    let fxNpsh: number;
    let fxRend: number;
    let fxRotor: number;

    let vazaoMin = 9999;
    let vazaoMax = -9999;
    let qSeg: number;
    let ppDesej: number;
    let percFx: number;
    let difRange = 0;
    let difRangeIni = 0;

    const { vazaoDesej, alturaDesej } = bombaSug;

    const lista = bombaSug.curvas;

    for (let i = 0; i < lista.length; i += 1) {
      const xVazao = lista[i].vazao;
      if (xVazao < vazaoMin) vazaoMin = xVazao;
      if (xVazao > vazaoMax) vazaoMax = xVazao;

      const difX = lista[i].alturaFinal - lista[i].alturaInicial;
      if (difRangeIni === 0) difRangeIni = difX;
      if (difX > difRange) difRange = difX;
    }

    qSeg = -1;

    // encontra entre faixas
    for (let i = 0; i < lista.length - 1; i += 1) {
      if (vazaoDesej >= lista[i].vazao && vazaoDesej <= lista[i + 1].vazao) {
        qSeg = i;
        break;
      }
    }

    fxAltInic = 0;
    fxAltFin = 0;

    const I = 10;

    if (qSeg > -1) {
      ppvazao = lista[qSeg + 1].vazao - lista[qSeg].vazao;
      ppAltInic =
        (lista[qSeg + 1].alturaInicial - lista[qSeg].alturaInicial) / ppvazao;

      ppAltFin =
        (lista[qSeg + 1].alturaFinal - lista[qSeg].alturaFinal) / ppvazao;

      ppNpsh = (lista[qSeg + 1].calcNpsh - lista[qSeg].calcNpsh) / ppvazao;
      ppRend = (lista[qSeg + 1].calcRend - lista[qSeg].calcRend) / ppvazao;
      ppRotor = (lista[qSeg + 1].calcRotor - lista[qSeg].calcRotor) / ppvazao;

      ppDesej = vazaoDesej - lista[qSeg].vazao;

      fxAltInic = lista[qSeg].alturaInicial + ppAltInic * ppDesej;
      fxAltFin = lista[qSeg].alturaFinal + ppAltFin * ppDesej;
      fxNpsh = lista[qSeg].calcNpsh + ppNpsh * ppDesej;
      fxRend = lista[qSeg].calcRend + ppRend * ppDesej;
      fxRotor = lista[qSeg].calcRotor + ppRotor * ppDesej;

      bombaSug.calcAlturaInicial = fxAltInic;
      bombaSug.calcAlturaFinal = fxAltFin;
      bombaSug.calcNpsh = fxNpsh;
      bombaSug.calcRend = fxRend;
      bombaSug.calcRotor = fxRotor;
      bombaSug.valido = true;
    }

    if (
      alturaDesej >= fxAltInic &&
      alturaDesej <= fxAltFin &&
      fxAltFin !== fxAltInic
    ) {
      percFx = (alturaDesej - fxAltInic) / (fxAltFin - fxAltInic);

      if (fxAltFin - fxAltInic < difRangeIni) {
        const fxAltInicX = fxAltFin - difRange;
        percFx = (alturaDesej - fxAltInicX) / (fxAltFin - fxAltInicX);
      }

      // rotorEnc = rotorMin + (rotorMax - rotorMin) * percFx;
      bombaSug.calcPercOcupacao = percFx;
      bombaSug.valido = true;
    } else {
      bombaSug.calcAlturaInicial = 0;
      bombaSug.calcAlturaFinal = 0;
      bombaSug.calcNpsh = 0;
      bombaSug.calcRend = 0;
      bombaSug.calcRotor = 0;
      bombaSug.calcPercOcupacao = 0;
      bombaSug.valido = false;
    }

    return bombaSug;
  }

  async function getCurvasBombas(
    altura: number,
    vazao: number,
    modelo: string,
  ) {
    // const response = await api.get(`/busca-curvas/${{ altura, vazao }}`);
    // Retornando a coluna INDIC_TIPO, onde seus valores representam o seguinte:
    // P - Padrão
    // B - Balsa
    // S - Submersa
    // tipoBombaFiltrar = contem o tipo para o componente

    // const url = `/integracao/curvas-bombas/modelos?vazao=${vazao}&altura=${altura}&modelo=${encodeURI(
    //   modelo,
    // )}`;
    // alert(tipoBombaFiltrar);
    const url = `/integracao/curvas-bombas/modelos?tipoFiltrar=${tipoBombaFiltrar}&vazao=${vazao}&altura=${altura}&modelo=${encodeURI(
      modelo,
    )}`;

    const response = await api.get(url);
    // navigator.clipboard.writeText(`${JSON.stringify(response)}`);
    //  "leadTime":45,
    //  "vlrUnitario":41195.72,
    //  "potencia":0,

    if (response.data) {
      const lista: CurvasBomba[] = response.data;
      const listaBombas: BombaSugeria[] = [];
      let seqAgora = -1;
      let bombaAgora = newBomba();

      for (let i = 0; i < lista.length; i += 1) {
        lista[i].calcNpsh = lista[i].npshAlturaInicial;
        lista[i].calcRend = lista[i].rendAlturaInicial;
        lista[i].calcRotor = lista[i].rotorAlturaInicial;
        if (seqAgora !== lista[i].seqModeloBomba) {
          if (seqAgora > -1) {
            const bb = cloneJson(bombaAgora);
            listaBombas.push(bb);
          }

          // valores inicias
          bombaAgora = newBomba();
          bombaAgora.curvas = [];
          bombaAgora.seq = lista[i].seq;
          bombaAgora.seqModeloBomba = lista[i].seqModeloBomba;
          bombaAgora.modelo = lista[i].modelo;
          bombaAgora.fabricante = lista[i].fabricante;
          bombaAgora.alturaDesej = altura;
          bombaAgora.vazaoDesej = vazao;
          seqAgora = lista[i].seqModeloBomba;
          // bombaAgora.valor = i;
          // novos
          bombaAgora.leadTime = lista[i].leadTime;
          bombaAgora.vlrUnitario = lista[i].vlrUnitario;
          bombaAgora.potencia = lista[i].potencia;

          bombaAgora.indicTipo = lista[i].indicTipo;
          switch (lista[i].indicTipo) {
            case 'P':
              bombaAgora.aplicacao = 'Bomba Padrão Bombeamento';
              break;
            case 'B':
              bombaAgora.aplicacao = 'Bomba para Balsa';
              break;
            case 'S':
              bombaAgora.aplicacao = 'Bomba para Submersa';
              break;
            default:
              bombaAgora.aplicacao = '';
          }

          bombaAgora.valido = false;
        }
        bombaAgora.curvas.push(lista[i]);
      }
      const bb = cloneJson(bombaAgora);
      listaBombas.push(bb);
      return listaBombas;
    }
    return null;
  }

  async function getListaSugestaoBombas(
    altura: number,
    vazao: number,
    modelo: string,
  ) {
    let lista: any[] = [];

    try {
      const listaCurvasBombas: any = await getCurvasBombas(
        altura,
        vazao,
        modelo,
      );

      if (listaCurvasBombas) {
        for (let i = 0; i < listaCurvasBombas.length; i += 1) {
          let bombaSug: BombaSugeria = getAreaBomba(listaCurvasBombas[i]);

          bombaSug = getAreaVariavel(bombaSug, 'rend');
          bombaSug = getAreaVariavel(bombaSug, 'npsh');
          bombaSug = getAreaVariavel(bombaSug, 'rotor');
          bombaSug = getAreaBomba(bombaSug);

          bombaSug.calcPotencia = (altura * vazao) / (2.7 * bombaSug.calcRend);

          if (bombaSug.valido || bombaSug.modelo === modelo) {
            if (
              bombaSug.potencia === 0 ||
              bombaSug.potencia >= bombaSug.calcPotencia ||
              bombaSug.modelo === modelo
            ) {
              bombaSug.grafico = getGraficoBomba(bombaSug);
              bombaSug.curvas = [];
              lista.push(bombaSug);
              // valorTotalSugeridos += bombaSug.valor;
              // if (bombaSug.vlrUnitario > maiorValor) {
              //   maiorValor = bombaSug.vlrUnitario;
              // }
              // if (
              //   bombaSug.vlrUnitario > 0 &&
              //   bombaSug.vlrUnitario < menorValor
              // ) {
              //   menorValor = bombaSug.vlrUnitario;
              // }
            }
          }
        }
        // navigator.clipboard.writeText(`${JSON.stringify(lista)}`);
        // return lista;
      }
    } catch (error: any) {
      lista = [];
    }

    // ve percentual dos validos
    for (let i = 0; i < lista.length; i += 1) {
      let modeloBase: string = lista[i]?.modelo || '';
      modeloBase = modeloBase.replaceAll(' ', '');
      lista[i].modeloBase = modeloBase?.split('-')[0];
      lista[i].potencia = modeloBase?.split('-')[1] || lista[i].potencia;
    }

    // ver modelo x potencia x potenciaABS
    const bombasOrdenadas: BombaSugeria[] = lista.sort((a, b) => {
      const modeloA = a.modeloBase || '';
      const modeloB = b.modeloBase || '';

      if (modeloA < modeloB) return -1;
      if (modeloA > modeloB) return 1;

      return Math.ceil(a.potencia) - Math.ceil(b.potencia);
    });

    const menoresPotenciasPorModelo: BombaSugeria[] = [];

    bombasOrdenadas.forEach((bombau, index) => {
      if (
        index === 0 ||
        bombau.modelo === modelo ||
        bombau.modeloBase !== bombasOrdenadas[index - 1].modeloBase
      ) {
        menoresPotenciasPorModelo.push(bombau);
      }
    });

    // navigator.clipboard.writeText(
    //   `${JSON.stringify(menoresPotenciasPorModelo)}`,
    // );

    // Ajusta  maior Menor
    let maiorValor = 0;
    let menorValor = 999999999999;

    menoresPotenciasPorModelo.forEach(item => {
      if (item.vlrUnitario > maiorValor) {
        maiorValor = item.vlrUnitario;
      }
      if (item.vlrUnitario > 0 && item.vlrUnitario < menorValor) {
        menorValor = item.vlrUnitario;
      }
    });
    // alert(maiorValor);
    // alert(menorValor);
    for (let i = 0; i < menoresPotenciasPorModelo.length; i += 1) {
      if (maiorValor !== menorValor) {
        if (menoresPotenciasPorModelo[i].vlrUnitario > 0) {
          menoresPotenciasPorModelo[i].valor =
            (menoresPotenciasPorModelo[i].vlrUnitario - menorValor) /
            (maiorValor - menorValor);
          // alert(
          //   `indice ${menoresPotenciasPorModelo[i].vlrUnitario} - ${menoresPotenciasPorModelo[i].valor} `,
          // );
        }
      } else {
        menoresPotenciasPorModelo[i].valor =
          menoresPotenciasPorModelo[i].vlrUnitario / maiorValor;
      }
    }

    return menoresPotenciasPorModelo;
    // return lista;
  }

  async function calcularSugestoes() {
    const html = document.getElementById(`bomba_chart_${isBalsa}`);

    if (html) {
      html.innerHTML = '';
    }

    setCalculandoSugestao(true);
    setMostrarBlocoSugestoes(false);
    setAcimaLimiteCurva(false);
    setIsInfinity(false);
    setSugestaoSelecionada({});

    const modelo = '';

    let prt = pressaoPrevista;
    let vzt = vazaoPrevista;
    let modb = modeloBombaSelecionado ?? ''; // modeloBombaSelecionado;
    if (vazaoAlternativa > 0) vzt = vazaoAlternativa;
    if (pressaoAlternativa > 0) prt = pressaoAlternativa;
    if (modelo !== '') modb = modelo;
    const bbs = await getListaSugestaoBombas(
      Number(prt.toFixed(2)),
      Number(vzt.toFixed(2)),
      modb,
    );

    bbs.sort((a: any, b: any) => b.calcRend - a.calcRend);

    const balsa = orcamentoInfoBalsa?.isBalsa ?? false;
    const auxi = orcamentoInfoBalsa?.isBalsaAuxiliar ?? false;

    setSugestoes(
      bbs
        .filter(item =>
          modelosBomba.find(
            (item2: any) =>
              item2.seq === item.seqModeloBomba &&
              item2.tipoBomba === tipoBombaFiltrar,
          ),
        )
        ?.map(item => {
          if ((balsa && auxi && isBalsa) || (balsa && !auxi && !isBalsa)) {
            if (Number(item.calcPotencia) > Number(item.potencia))
              return {
                ...item,
                calcPotencia: Infinity,
                valido: false,
                calcRend: 0,
              };
          }

          return item;
        }),
    );

    // const bbsel = bbs.find(bbc => bbc?.modelo === modb);

    setCalculandoSugestao(false);
    setAtualizarSugestoes(false);
  }

  async function initChart(selecionado: string) {
    const bombaSelecionada = sugestoes.find(
      bombax => String(bombax?.modelo) === selecionado,
    );

    if (window.google && bombaSelecionada) {
      const chartDiv = document.getElementById(`bomba_chart_${isBalsa}`);
      const chart = new window.google.visualization.ComboChart(chartDiv);
      const data = new window.google.visualization.DataTable();

      data.addColumn('number', 'vazao');
      data.addColumn('number', 'alturaInicial');
      data.addColumn('number', 'alturaFinal');
      data.addColumn('number', 'ponto');

      const lista = bombaSelecionada.grafico;

      for (let i = 0; i < lista.length; i += 1) {
        const ai = lista[i].alturaInicial > 0 ? lista[i].alturaInicial : null;
        const af = lista[i].alturaFinal > 0 ? lista[i].alturaFinal : null;
        data.addRow([lista[i].vazao, ai, af, lista[i].ponto]);
      }

      // Draw the chart using the data within its DIV.
      chart.draw(data, {
        height: 450,
        width: 950,
        title: bombaSelecionada.modelo,
        legend: 'true',
        titleY: `Altura ( ${bombaSelecionada.alturaDesej} mca) `,
        titleX: `Vazão ( ${bombaSelecionada.vazaoDesej} m³/h ) `,
        curveType: 'function',
        series: {
          2: {
            type: 'scatter',
            color: 'red',
            pointSize: 7,
          },
        },
      });
    } else if (window.google) {
      const chartDiv = document.getElementById(`bomba_chart_${isBalsa}`);
      const chart = new window.google.visualization.ComboChart(chartDiv);
      const data = new window.google.visualization.DataTable();
      data.addColumn('number', 'vazao');
      data.addColumn('number', 'ponto');
      data.addRow([0, 0]);
      chart.draw(data, {
        height: 450,
        width: 950,
        title: `Não encontrada as curvas da ${selecionado}`,
      });
    }
  }

  async function handleAtualizarValores() {
    if (
      !loadingPage &&
      vazaoPrevista &&
      pressaoPrevista &&
      !calculandoSugestao &&
      modeloBombaSelecionado
    )
      if (debounceTimeoutRef.current !== undefined) {
        clearTimeout(debounceTimeoutRef.current);
      }

    if (debounceTimeoutRef) {
      debounceTimeoutRef.current = window.setTimeout(() => {
        setCalculoSugestaoDisparado(true);
        setAtualizarSugestoes(true);
      }, 500);
    }

    return () => {
      if (debounceTimeoutRef.current !== undefined) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }

  async function inserirSugestoes(itemUsar: any, alertar = true) {
    const sugestao = itemUsar;
    if (sugestao) {
      const bombaSelecionada = sugestoes.find(
        bombax => String(bombax?.modelo) === sugestao.modelo,
      );

      const fabricante = bombaSelecionada.fabricante ?? '';
      const bombaSug = bombaSelecionada.modelo; // sugestao.seq seqModeloBomba

      let rendimentoSug = 0;
      let diametroRotores = 0;
      if (bombaSelecionada.calcRotor > 0) {
        rendimentoSug = bombaSelecionada.calcRend.toFixed(2);
        diametroRotores = Number(bombaSelecionada.calcRotor.toFixed(0));
      }
      const npsh = Number(bombaSelecionada.calcNpsh.toFixed(2));
      const ocupacaoCurvaSug = Number(
        (bombaSelecionada.calcPercOcupacao * 100).toFixed(2),
      );
      const amplitude = Number(
        (
          bombaSelecionada.calcAlturaFinal - bombaSelecionada.calcAlturaInicial
        ).toFixed(0),
      );

      handleInserirSugestao({
        amplitude,
        bomba: bombaSug,
        fabricante,
        npsh,
        ocupacaoCurva: ocupacaoCurvaSug,
        rendimento: rendimentoSug,
        diametroRotores,
        alertar,
      });
    }
  }

  async function handleSelecaoSugestao(
    item: any,
    inserirSugestao = true,
    alertar = true,
  ) {
    initChart(item.modelo);
    setSugestaoSelecionada(item);

    setAcimaLimiteCurva(item.calcPercOcupacao > 0.9);

    setIsInfinity(item.calcPotencia === Infinity);

    setAlteradoManualmente(false);

    const inserir = calculoSugestaoDisparado ? true : inserirSugestao;

    // Vai inserir os dados, mas não vai dar alerta. O processo vai modificar e alertar.
    if (inserir) inserirSugestoes(item, alertar);

    setCalculoSugestaoDisparado(false);
  }

  useEffect(() => {
    if (sugestoes && sugestoes.length > 0) {
      // Se não tiver sugestaoSelecionada, tenta selecionar o modelo atual do bombeamento
      if (!Object.keys(sugestaoSelecionada).length) {
        const modelosDiferentes = modeloBombaSelecionado !== modeloBombaSalva;
        const modeloBuscar = modelosDiferentes
          ? modeloBombaSelecionado
          : modeloBombaSalva;

        const itemSelecionado = sugestoes?.find(
          (item: any) => item.modelo === modeloBuscar,
        );

        const valor = isBalsa
          ? atualizarBombeamentoBalsa
          : atualizarBombeamento;
        if (itemSelecionado) {
          handleSelecaoSugestao(itemSelecionado, valor, !modelosDiferentes);
        }
      }
      setMostrarBlocoSugestoes(true);
    }
  }, [necessarioReprocessar, sugestoes]);

  async function handleConfirmarSugestao() {
    if (
      !sugestaoSelecionada.valido &&
      sugestaoSelecionada.calcPotencia !== Infinity
    ) {
      toast.error('O modelo selecionado não é válido para essa operação');
      return;
    }

    const confirmacao = await Swal.fire({
      title: `Deseja inserir as informações da bomba ${
        sugestaoSelecionada?.modelo ?? ''
      }? Isso substituirá informações no bloco de Bombeamento. Sendo necessário disparar o processo de 'Calcular Bombeamento'. Deseja prosseguir?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    });

    if (confirmacao.isConfirmed) {
      inserirSugestoes(sugestaoSelecionada, true);
    }
  }

  function renderIcond(
    valor: number,
    valorUnit: number,
    nitem: number,
  ): string {
    if (valorUnit === 0) {
      return '⚪';
    }
    if ((valor >= 0 && valor <= 0.33) || nitem < 2) {
      return '🟢';
    }
    if (valor > 0.33 && valor <= 0.66) {
      return '🟡';
    }
    if (valor > 0.66) {
      return '🔴';
    }
    return '⚪';
  }

  useEffect(() => {
    if (atualizarSugestoes === true) calcularSugestoes();
  }, [atualizarSugestoes]);

  return (
    <>
      <Card className="shadow">
        <Row className="mt--3">
          <ColQuestaoTitulo>
            <LabelQuestaoTitulo>
              Bombeamento {isBalsa ? `da Balsa` : ``}
            </LabelQuestaoTitulo>
          </ColQuestaoTitulo>
        </Row>
        <div className="div-sugestao">
          {!loadingPage && (
            <>
              <Row>
                <Col sm="12" className="ml-2">
                  <h3>Modelos Sugeridos de Bombas</h3>
                </Col>
              </Row>
              <Row className="mb-5" hidden={!calculandoSugestao}>
                <Col sm="12" className="ml-2">
                  <p>Gerando lista de sugestões de bomba</p>
                </Col>
              </Row>
              <Row
                className="mb-5"
                hidden={calculandoSugestao || mostrarBlocoSugestoes}
              >
                <Col sm="12" className="ml-2">
                  <strong>⚠︎ ATENÇÃO:</strong> Não foram localizadas as curvas
                  para os parâmetros de vazão {vazaoPrevista} m³/h e altura
                  manométrica {pressaoPrevista} mca <br />
                  <br />
                  <strong style={{ color: '#ff0000' }}>
                    Favor consultar a engenharia!
                  </strong>
                  <p>Nenhuma sugestão de bomba disponível</p>
                </Col>
              </Row>
              <Row
                className="mb-5"
                hidden={calculandoSugestao || !mostrarBlocoSugestoes}
              >
                <Col sm="12" className="ml-2">
                  <div id="segDiv" className="div_table_sugestao">
                    <Table
                      id="segTab"
                      size="sm"
                      className="table_sugestao"
                      responsive
                    >
                      <thead>
                        <tr>
                          <th>Modelo</th>
                          <th>Fabric</th>
                          <th>Pot.Absorv</th>
                          <th>NPSH</th>
                          <th>leadTime</th>
                          <th>IndicadorValor</th>
                          <th>Ocupação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sugestoes?.map((seg: any) => {
                          let selectedRow = false;
                          let selectedErrorRow = false;

                          const rowModeloBombaSelecionado =
                            sugestaoSelecionada?.modelo ?? '';

                          if (seg.modelo === rowModeloBombaSelecionado) {
                            if (seg.calcRend !== 0) selectedRow = true;
                            else selectedErrorRow = true;
                          }

                          return (
                            <tr
                              key={seg.seq}
                              title={`${seg.aplicacao}`}
                              className={`${
                                // eslint-disable-next-line no-nested-ternary
                                selectedRow
                                  ? `sugestao_bomba_row_selected`
                                  : selectedErrorRow
                                  ? `sugestao_bomba_error_row_selected`
                                  : `sugestao_bomba_row`
                              }`}
                              onClick={() => {
                                if (seg) {
                                  console.log('Testando', seg);
                                  handleSelecaoSugestao(seg);
                                }
                              }}
                            >
                              <td>{seg.modelo}</td>
                              <td>{seg.fabricante}</td>
                              <td
                                title={
                                  seg.potencia === 0
                                    ? `Rendimento: ${seg.calcRend.toFixed(2)}%`
                                    : `Rendimento: ${seg.calcRend.toFixed(
                                        2,
                                      )}% - Potência: ${seg.potencia}cv`
                                }
                              >
                                {seg.calcPotencia.toFixed(2)} cv
                              </td>
                              <td>{seg.calcNpsh.toFixed(2)}</td>
                              <th>{seg.leadTime}</th>
                              <th
                                title={` ${(seg.valor * 100).toFixed(2)}%`} // - ${seg.vlrUnitario.toFixed(0)}
                              >
                                {` ${renderIcond(
                                  seg.valor,
                                  seg.vlrUnitario,
                                  sugestoes.length,
                                )} `}
                              </th>
                              <td
                                style={{
                                  borderWidth: 2,
                                  background:
                                    seg.calcPercOcupacao > 0.9
                                      ? '#ff0000'
                                      : 'white',
                                  color:
                                    seg.calcPercOcupacao > 0.9
                                      ? '#ffffff'
                                      : '#222222',
                                }}
                              >{`${(seg.calcPercOcupacao * 100).toFixed(
                                2,
                              )}%`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr hidden={!isInfinity && !acimaLimiteCurva}>
                          <td colSpan={5}>
                            <div
                              hidden={!acimaLimiteCurva}
                              style={{ fontSize: 15, color: '#ff0000' }}
                            >
                              *A bomba ideal será a de melhor rendimento com
                              menor NPSH e percentual de ocupação até 90%.
                            </div>

                            <div
                              hidden={!isInfinity}
                              style={{ fontSize: 15, color: '#ff0000' }}
                            >
                              *A bomba selecionada não tem os parâmetros
                              necessários para realizar os cálculos
                              corretamente.
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={5}>
                            <Button
                              disabled={
                                inserindoSugestao || sugestoes.length === 0
                              }
                              hidden
                              className="btn-icon btn-2 float-right"
                              color="warning"
                              type="button"
                              size="sm"
                              onClick={handleConfirmarSugestao}
                            >
                              <FaCheckCircle /> Confirmar Seleção
                            </Button>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                </Col>
                <Col sm="12" xs="12" className="clearfix mt-4">
                  <div
                    id={`bomba_chart_${isBalsa}`}
                    className="mt--4 bomba_chart"
                    style={{
                      marginTop: 0,
                      padding: 0,
                      minHeight: '300px',
                      width: '100%',
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
        <Row>
          <Col className="mt-2 pb-4">
            <ContainerRow>
              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Bomba</InputLabel>
                  <Select disabled value={bomba} label="Bomba" autoWidth>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="Série">Série</MenuItem>
                    <MenuItem value="Paralelo">Paralelo</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <TextField
                  disabled
                  size="small"
                  label="Qtde bomba"
                  type="number"
                  value={quantidadeBomba}
                />
              </Col>

              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Marca bomba</InputLabel>
                  <Select
                    value={marcaBombaSelecionada}
                    autoWidth
                    onChange={event =>
                      setMarcaBombaSelecionada(event.target.value as string)
                    }
                  >
                    {marcasBomba.map((item: any) => (
                      <MenuItem key={item.id} value={item.nome}>
                        {item.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col sm="1">
                <Tooltip title="Ir para site do fabricante">
                  <Button
                    className="btn-icon btn-2 mt-4"
                    size="sm"
                    color="info"
                    disabled={!isUrlFabricante}
                    type="button"
                    onClick={() => {
                      if (isUrlFabricante) {
                        window.open(fabricanteSelecionado, '_blank');
                      }
                    }}
                  >
                    <FaGlobe />
                  </Button>
                </Tooltip>
              </Col>

              <Col sm="3">
                <FormControl fullWidth>
                  <InputLabel>Modelo bomba</InputLabel>
                  <Select
                    value={modeloBombaSelecionado}
                    autoWidth
                    disabled={carregandoModelosBomba}
                    onChange={event => {
                      setLimparDados(true);
                      setModeloBombaSelecionado(event.target.value as string);
                      handleAtualizarValores();
                      // infoSugestaoBombas(event.target.value as string);
                    }}
                  >
                    {marcaBombaSelecionada && modelosBomba.length > 0 ? (
                      modelosBomba
                        .filter(
                          (item: any) =>
                            marcasBomba.find(
                              (item2: any) =>
                                item2.id === item.seqBomba &&
                                item2.nome === marcaBombaSelecionada,
                            ) && item.tipoBomba === tipoBombaFiltrar,
                        )
                        .map((item: any) => (
                          <MenuItem key={item.seq} value={item.nome}>
                            {item.nome}
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem value="">Nenhum modelo disponível</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <TextField
                  disabled
                  size="small"
                  label="Qtde módulos"
                  type="number"
                  value={quantidadeModulos}
                />
              </Col>
            </ContainerRow>
            <ContainerRow className="mt-4">
              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Vazão alternativa"
                  value={vazaoAlternativa}
                  onChange={event => {
                    setVazaoAlternativa(Number(event.target.value) ?? 0);
                    handleAtualizarValores();
                  }}
                />
              </Col>

              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Pressão alternativa"
                  value={pressaoAlternativa}
                  onChange={event => {
                    setPressaoAlternativa(Number(event.target.value) ?? 0);
                    handleAtualizarValores();
                  }}
                />
              </Col>

              <Col sm="2">
                <TextField
                  size="small"
                  type="number"
                  label="Rendimento (%)"
                  value={rendimento}
                  onChange={event => {
                    setRendimento(Number(event.target.value) ?? 0);
                    setAlteradoManualmente(true);
                  }}
                />
              </Col>
              <Col sm="2">
                <FormControl fullWidth>
                  <TextField
                    disabled
                    size="small"
                    type="number"
                    label={`Ocupação Amplitude H[${amplitudeCurva}m] (%)`}
                    value={ocupacaoCurva}
                  />
                </FormControl>
              </Col>
              <Col sm="2">
                <FormControl fullWidth>
                  <TextField
                    disabled={acessoLimitadoEditaNpsh}
                    size="small"
                    type="number"
                    label="NPSH requerido (m)"
                    value={npshRequerido}
                    onChange={event => {
                      setNpshRequerido(Number(event.target.value) ?? 0);
                      setAlteradoManualmente(true);
                    }}
                  />
                </FormControl>
              </Col>
            </ContainerRow>

            <ContainerRow className="mt-4">
              <Col sm="3">
                <TextField
                  disabled
                  size="small"
                  label="Diâmetro Sucção Entrada"
                  type="number"
                  value={diametroSuccaoEntrada}
                />
              </Col>

              <Col sm="3">
                <TextField
                  disabled
                  size="small"
                  label="Diâmetro Recalque Saída"
                  type="number"
                  value={diametroRecalqueSaida}
                />
              </Col>

              <Col sm="3">
                <TextField
                  disabled
                  size="small"
                  label="Tipo Flange"
                  type="text"
                  value={BombaTipoFlange}
                />
              </Col>
              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Diâmetro Estimado dos Rotores"
                  value={diametroEstimadoRotores || 0}
                  onChange={event => {
                    setDiametroEstimadoRotores(Number(event.target.value) ?? 0);

                    setAlteradoManualmente(true);
                  }}
                />
              </Col>
            </ContainerRow>

            <ContainerRow className="mt-4">
              <Col sm="3">
                <FormControl fullWidth>
                  <InputLabel>Energia bombeamento</InputLabel>
                  <Select
                    value={energiaBombeamento}
                    autoWidth
                    /* onChange={event =>
                      setEnergiaBombeamento(event.target.value as string)
                    } */
                    disabled
                  >
                    <MenuItem value="Conjunta">Conjunta</MenuItem>
                    <MenuItem value="Individual">Individual</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm="3">
                <FormControl fullWidth>
                  <InputLabel>Motor</InputLabel>
                  <Select
                    value={motor}
                    autoWidth
                    onChange={event => setMotor(event.target.value as string)}
                  >
                    <MenuItem value="Elétrico">Elétrico</MenuItem>
                    <MenuItem value="Diesel">Diesel</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Potência nominal do grupo gerador"
                  value={potenciaNominal}
                  onChange={event =>
                    setPotenciaNominal(Number(event.target.value) ?? 0)
                  }
                />
              </Col>
              <Col sm="3">
                <TextField
                  size="small"
                  type="number"
                  label="Número de Estágios"
                  value={numeroEstagios || 1}
                  onChange={event =>
                    setNumeroEstagios(Number(event.target.value) ?? 0)
                  }
                />
              </Col>
            </ContainerRow>

            <ContainerButtons className="pr-4 pt--3 mt-3">
              <Col className="ml-2 mb-2 mr--2">
                <Table
                  bordered
                  responsive
                  style={{ width: '50%', margin: 'auto' }}
                >
                  <thead>
                    <tr>
                      <th>Potência Absorvida no Eixo da Bomba</th>
                      <th>Potência nominal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <Td>
                        {!potenciaAbsorvidaEixoBomba ||
                        potenciaAbsorvidaEixoBomba === 0
                          ? ''
                          : `${potenciaAbsorvidaEixoBomba} cv`}
                      </Td>
                      <Td>
                        {!potenciaCasaMaquina || potenciaCasaMaquina === 0
                          ? ''
                          : `${potenciaCasaMaquina} cv`}
                      </Td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col className="ml-2 mb-2 mr--2">
                <Button
                  disabled={loadingCalculoBombeamento || rendimento === 0}
                  className="btn-icon btn-2"
                  color="danger"
                  type="button"
                  onClick={calculaBombeamento}
                  size="sm"
                >
                  {loadingCalculoBombeamento
                    ? 'Calculando bombeamento...'
                    : 'Calcular bombeamento'}
                </Button>
                {necessarioReprocessar && <Reprocessamento />}
              </Col>
            </ContainerButtons>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Bombeamento;

import React, { createContext, useEffect, useState } from 'react';
import {
  Button,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';

import api from 'services/api';

import Orcamento from 'models/Orcamento';

import Pessoa from 'models/Pessoa';
import OrcamentoParte from 'models/OrcamentoParte';
import OpcoesLancesType from 'models/OpcoesLances';
import FichaTecnica from 'models/FichaTecnica';
import OrcamentoResposta from 'models/OrcamentoResposta';

import { OportunidadeCrm } from 'models/OportunidadeCrm';
import {
  FaArrowAltCircleRight,
  FaArrowCircleRight,
  FaEdit,
  FaHistory,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import Parte from 'models/Parte';
import UserSmallHeader from 'components/Headers/UserSmallHeader';
import classnames from 'classnames';
import ModalLog from 'components/ModalLog';
import OrcamentoProduto from 'models/OrcamentoProduto';
import ParametrosModel from 'models/Parametros';
import toast from 'react-hot-toast';
import Usuario from 'models/Usuario';
import AutocompletePessoaERP from 'components/AutcompletePessoaERP';
import { useAuth } from 'hooks/auth';
import PessoaErp from 'models/PessoaErp';
import IndicadoresNegocio from 'models/IndicadoresNegocio';
import Swal from 'sweetalert2';
import triggerValidaRegras from 'services/triggerValidaRegras';
import InterfaceLogs from 'components/InterfaceLogs';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import ModalPessoaGrupo from 'views/components/ModalPessoaGrupo';
import { IdOrcamentoLabel, RowButtons } from './styles';
import OpcoesLances from './components/OpcoesLances';
import VisualizadorGeo from './components/VisualizadorGeo';
import FotovoltaicaCadastroUC from './components/FotovoltaicaCadastroUC';
import FotovoltaicaInfoUsina from './components/FotovoltaicaInfoUsina';
import ParteQuestoesFormulario from './components/ParteQuestoesFormulario';
import AdutoraBombeamento from './components/AdutoraBombeamento';
import Resultado from './components/Resultado';
import ModalAcesso from './components/ModalAcesso';

interface Produto {
  id: number;
  nome: string;
  produtoId?: number;
}

interface LocationState {
  orcamentoVersaoId: number;
  pessoa?: Pessoa;
  orcamento?: Orcamento;
  codigoOportunidade?: number;
  codigoOportunidadeDomsgeAgrupador?: string;
  oportunidadeCrm?: OportunidadeCrm | null;
  goTo: string;
}

interface FotovoltaicaRespostas {
  siglaUf?: string | null;
  municipioIrradiacaoId?: number | null;
  distribuidoraId?: number | null;
}

interface OrcamentoContextType {
  produtoSelecionado: Produto;
  fichaTecnica?: FichaTecnica;
  handleSetOpcaoLance: (opcaoLance: OpcoesLancesType) => void;
  opcaoLanceSelecionada: OpcoesLancesType;
  orcamentoPartes?: OrcamentoParte[];
  etapaAtualId: number;
  orcamentoVersaoId: number;
  codigoOportunidade: number;
  fichaTecnicaId?: number;
  handleSetRespostasAlteradas: (orcamentoResposta: OrcamentoResposta[]) => void;
  opcoesLances: OpcoesLancesType[];
  setFotovoltaicaRespostas: (fotovoltaica: FotovoltaicaRespostas) => void;
  fotovoltaicaRespostas: FotovoltaicaRespostas;
  handleBloquearAbaResultado: () => void;
}

interface iOrcamentoParte {
  id: number;
  nome: string;
  parteId: number;
  parte: Parte;
  parteConcluida: true;
}

export const OrcamentoContext = createContext({} as OrcamentoContextType);

interface ConfiguracaoEtapasContext {
  opcoesLances: OpcoesLancesType[];
  setOpcoesLances: (value: OpcoesLancesType[]) => void;
  lancesManual: any[];
  setLancesManual: (value: any[]) => void;
  orcamentoProduto: any;
}

export const ConfiguracaoEtapasContext = createContext(
  {} as ConfiguracaoEtapasContext,
);

const EtapasOrcamento: React.FC = () => {
  const { usuario }: { usuario: Usuario } = useAuth();
  const location = useLocation<LocationState>();
  const locationStateOrcamento = location.state?.orcamentoVersaoId ?? null;
  const goTo = location.state?.goTo ?? null;

  const locationState = location.state as any;

  const openResultado = locationState?.openResultado || false;

  const orcamentoVersaoId = locationStateOrcamento;

  const [currentTab, setCurrentTab] = useState('');
  const [currentTabContentLoaded, setCurrentTabContentLoaded] = useState(false);

  const [showResultadoButton, setShowResultadoButton] = useState(false);

  const [acessoLimitado, setAcessoLimitado] = useState(true);

  const [triggerStart, setTriggerStart] = useState(false);

  const [fichaTecnicaId, setFichaTecnicaId] = useState<number>();

  const [sessaoId, setSessaoId] = useState<string>();

  const [codigoOportunidade, setCodigoOportunidade] = useState<number>();
  const [codOportunidade, setCodOportunidade] = useState(0);
  const [tipoIndicador, setTipoIndicador] = useState<string>();

  const [ativarBotaoResultado, setAtivarBotaoResultado] = useState(false);
  const [calculoFinalRealizado, setCalculoFinalRealizado] = useState(false);
  const [modalStatusLogVisivel, setModalStatusLogVisivel] = useState(false);

  const [modalManterAcesso, setModalManterAcesso] = useState(false);

  const [clienteOrcamento, setClienteOrcamento] = useState<Pessoa>(
    {} as Pessoa,
  );

  const [clienteOriginalOrcamento, setClienteOriginalOrcamento] =
    useState<Pessoa>({} as Pessoa);

  const [orcamentoPartes, setOrcamentoPartes] = useState(
    [] as iOrcamentoParte[],
  );

  const [orcamento, setOrcamento] = useState({} as Orcamento);

  const [orcamentoProduto, setOrcamentoProduto] = useState(
    [] as OrcamentoProduto[],
  );

  const [categoriaFotovoltaicaId, setCategoriaFotovoltaicaId] = useState(
    {} as ParametrosModel,
  );

  const [mostrarBotaoAcesso, setMostrarBotaoAcesso] = useState(true);

  const [opcoesLances, setOpcoesLances] = useState<OpcoesLancesType[]>([]);

  const [lancesManual, setLancesManual] = useState([] as any[]);

  const [formModalState, setFormModalState] = useState(false);
  const [listaRepresentantes, setListaRepresentantes] = useState<
    IndicadoresNegocio[]
  >([]);
  const [pessoaErpSelecionada, setPessoaErpSelecionada] = useState(
    {} as PessoaErp,
  );
  const [nomeRepresentante, setNomeRepresentante] = useState('');
  const [nomeGerente, setNomeGerente] = useState('');

  const components = [
    {
      id: 'OpcoesLances',
      componente: OpcoesLances,
    },
    {
      id: 'VisualizadorGeo',
      componente: VisualizadorGeo,
    },
    {
      id: 'ParteQuestoesFormulario',
      componente: ParteQuestoesFormulario,
    },
    {
      id: 'FotovoltaicaInfoUsina',
      componente: FotovoltaicaInfoUsina,
    },
    {
      id: 'FotovoltaicaCadastroUC',
      componente: FotovoltaicaCadastroUC,
    },
    {
      id: 'AdutoraBombeamento',
      componente: AdutoraBombeamento,
    },
    {
      id: 'resultado',
      componente: Resultado,
    },
  ];

  async function categoriaFotovoltaica() {
    const response = await api.get('/parametros');
    setCategoriaFotovoltaicaId(response.data);
  }

  useEffect(() => {
    categoriaFotovoltaica();
  }, []);

  async function getAcessoLimitadoPrograma() {
    const response = await api.get('/acesso-programa/acesso-limitado', {
      params: {
        programaId: 19,
      },
    });

    const limitado: boolean = response.data;

    const aux = orcamento as any;
    if (limitado) {
      setMostrarBotaoAcesso(usuario?.id === aux.usuarioId);
    } else {
      setMostrarBotaoAcesso(true);
    }
  }

  async function carregarOrcamento() {
    const response = await api.get(`/orcamento/${orcamentoVersaoId}`);

    setOrcamento(response.data);
  }

  async function getOrcamentoVersao() {
    const response = await api.get(`/orcamento/versao/${orcamentoVersaoId}`, {
      params: { completo: true },
    });

    const orc = response.data;

    const partesFiltrar = orc?.OrcamentoProduto?.[0]?.OrcamentoParte ?? [];
    const produto = orc?.OrcamentoProduto ?? [];

    const partes = partesFiltrar.sort((a: any, b: any) =>
      // eslint-disable-next-line no-nested-ternary
      a.parte.ordem > b.parte.ordem
        ? 1
        : b.parte.ordem > a.parte.ordem
        ? -1
        : 0,
    );

    setOrcamentoPartes(partes as iOrcamentoParte[]);
    setOrcamentoProduto(produto);

    setCodigoOportunidade(orc?.orcamento?.codigoOportunidadeDomsge ?? null);
    setCodOportunidade(orc?.orcamento?.codigoOportunidadeDomsge);

    setClienteOrcamento(orc?.orcamento?.pessoa ?? {});
    setClienteOriginalOrcamento(orc?.orcamento?.pessoaGnf ?? {});

    if (!currentTab) setCurrentTab(String(partes[0]?.parteId ?? ''));
  }

  function handleUpdate(orcamentos: Orcamento) {
    setOrcamento(orcamentos);
    setFormModalState(!formModalState);
  }

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  function isResultadoActive() {
    return false;
  }

  async function getFichaTecnica() {
    const response = await api.get(
      `/ficha-tecnica/orcamento-versao/${orcamentoVersaoId}`,
    );

    const fichaTecnicaResponse: FichaTecnica = response.data;

    if (fichaTecnicaResponse && fichaTecnicaResponse?.calculoFinalRealizado)
      setCalculoFinalRealizado(true);
    else setCalculoFinalRealizado(false);

    setFichaTecnicaId(fichaTecnicaResponse?.id);
    setSessaoId(fichaTecnicaResponse?.sessaoId ?? '');
  }

  useEffect(() => {
    if (orcamento.id) {
      sessionStorage.setItem('NroConfigStorage', String(orcamento.id));
      sessionStorage.setItem('TelaConfig', 'Sim');
    }
  }, [orcamento.id]);

  useEffect(() => {
    if (orcamento.id) {
      getAcessoLimitadoPrograma();
    }
  }, [orcamento]);

  useEffect(() => {
    if (orcamentoVersaoId) {
      carregarOrcamento();
      getOrcamentoVersao();
    }
  }, [orcamentoVersaoId]);

  useEffect(() => {
    if (openResultado && ativarBotaoResultado === true) {
      setCurrentTab('resultado');
    }
  }, [openResultado, ativarBotaoResultado]);

  useEffect(() => {
    if (
      orcamentoPartes.length > 0 &&
      fichaTecnicaId &&
      goTo === 'opcoesLances'
    ) {
      const lowerGoTo = String(goTo).toLowerCase();

      const parteFiltrada = orcamentoPartes.find(
        item => String(item.parte.componente).toLowerCase() === lowerGoTo,
      );

      if (parteFiltrada) {
        setCurrentTab(String(parteFiltrada.parteId));
      }
    }
  }, [goTo, fichaTecnicaId, orcamentoPartes]);

  useEffect(() => {
    let ativar = false;
    if (orcamentoPartes) {
      const preRequisitos = orcamentoPartes.filter(
        item => item.parte.requisitoResultado,
      );

      if (preRequisitos && preRequisitos.length) {
        const pendentes = preRequisitos.filter(item => !item.parteConcluida);
        if (pendentes && !pendentes.length) {
          ativar = true;
        }
      }
    }

    setAtivarBotaoResultado(ativar);
  }, [orcamentoPartes]);

  useEffect(() => {
    if (orcamentoPartes.length) {
      getFichaTecnica();
    }
  }, [orcamentoPartes]);

  async function getListaRepresentantes() {
    const response = await api.get('/integracao/indicadores-negocio');

    setListaRepresentantes(response.data);
    if (orcamento.representante) {
      const representanteFormatado = orcamento.representante
        .substring(0, orcamento.representante.indexOf('/'))
        .trim();
      if (representanteFormatado.length > 8) {
        setNomeGerente(
          String(
            response.data.find(
              (indic: any) =>
                indic.razaoRepresentante === representanteFormatado,
            )?.razaoGerente,
          ),
        );

        setNomeRepresentante(
          String(
            response.data.find(
              (indic: any) =>
                indic.razaoRepresentante === representanteFormatado,
            )?.razaoRepresentante,
          ),
        );
      } else {
        setNomeGerente(
          String(
            response.data.find(
              (indic: any) =>
                indic.codigoRepresentante === Number(representanteFormatado),
            )?.razaoGerente,
          ),
        );

        setNomeRepresentante(
          String(
            response.data.find(
              (indic: any) =>
                indic.codigoRepresentante === Number(representanteFormatado),
            )?.razaoRepresentante,
          ),
        );
      }
    }
  }

  useEffect(() => {
    getListaRepresentantes();
  }, [orcamento]);

  async function handleSaveRepresentante() {
    // eslint-disable-next-line prettier/prettier
    const { representante } = orcamento;

    if (!representante) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }
    const representanteFormatado = representante
      .substring(0, representante.indexOf('/'))
      .trim();

    let tipoGerente = '';
    let tipoRepresentante = '';
    if (representante) {
      if (representanteFormatado.length > 8) {
        tipoGerente = String(
          listaRepresentantes.find(
            indic => indic.razaoRepresentante === representanteFormatado,
          )?.tipoGerente,
        );

        tipoRepresentante = String(
          listaRepresentantes.find(
            indic => indic.razaoRepresentante === representanteFormatado,
          )?.tipoRepresentante,
        );
      } else {
        tipoGerente = String(
          listaRepresentantes.find(
            indic =>
              indic.codigoRepresentante === Number(representanteFormatado),
          )?.tipoGerente,
        );

        tipoRepresentante = String(
          listaRepresentantes.find(
            indic =>
              indic.codigoRepresentante === Number(representanteFormatado),
          )?.tipoRepresentante,
        );
      }
    }

    if (orcamento.id) {
      await api.put(`/orcamento/representante/${orcamento.id}`, {
        representante: orcamento.representante,
        tipoGerente: tipoGerente ?? undefined,
        tipoRepresentante: tipoRepresentante ?? undefined,
      });
    }
    toast.success('Registro concluído');
    carregarOrcamento();
    getListaRepresentantes();
    setFormModalState(false);
  }

  function ConfiguracaoDescricao() {
    return (
      <Row className="mt-2">
        <Col sm="6">
          <IdOrcamentoLabel>
            {`Configuração ${orcamentoVersaoId}`}
            {codigoOportunidade ? ` - GNF ${codigoOportunidade}` : ''}
          </IdOrcamentoLabel>
        </Col>
        <Col sm="6" className="mt-2">
          <Link
            to={{
              pathname: `/admin/interface-logs`,
              state: {
                nomeChave: 'orcamento_id',
                valorChave: orcamentoVersaoId,
                titulo: `Logs da configuração ${orcamentoVersaoId}`,
                modalState: { modalStatusLogVisivel },
                orcamento,
              },
            }}
          >
            <Button
              size="sm"
              className="btn-icon float-right"
              color="default"
              outline
              type="button"
              onClick={() => setModalStatusLogVisivel(true)}
            >
              <FaHistory />
              <span className="btn-inner--text">Logs</span>
            </Button>
          </Link>

          <Button
            size="sm"
            className="btn-icon float-right mr-3"
            color="default"
            outline
            type="button"
            hidden={!mostrarBotaoAcesso}
            onClick={() => setModalManterAcesso(true)}
          >
            <FaUsers />
            <span className="btn-inner--text">Acesso</span>
          </Button>
        </Col>
      </Row>
    );
  }

  function ConfiguracaoDetalhes() {
    return (
      <Row>
        <Col className="order-xl-2">
          <CardHeader className="bg-white border-3">
            <Row>
              <Col sm="6" xs="6">
                {clienteOriginalOrcamento.codigoDomsge && (
                  <h4>
                    {clienteOriginalOrcamento.codigoDomsge} -{' '}
                    {clienteOriginalOrcamento.razaoSocial}
                  </h4>
                )}
                {clienteOrcamento.codigoDomsge !==
                  clienteOriginalOrcamento.codigoDomsge && (
                  <h5>
                    {clienteOrcamento.codigoDomsge} -{' '}
                    {clienteOrcamento.razaoSocial}
                  </h5>
                )}
              </Col>
              <Col sm="6" xs="6">
                <div className="text-right">
                  {orcamentoProduto.length && (
                    <h5>{orcamentoProduto[0].nome}</h5>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="6" xs="6">
                {orcamento.representante && (
                  <h5>
                    Representante / Ger.Negócio: {nomeRepresentante}{' '}
                    {nomeGerente !== null &&
                    nomeGerente !== undefined &&
                    nomeGerente !== 'null'
                      ? ` / ${nomeGerente}`
                      : ''}
                  </h5>
                )}
              </Col>
              <Col sm="6" xs="6" className="text-right">
                <Button
                  size="sm"
                  className="btn-icon"
                  color="warning"
                  type="button"
                  onClick={() => {
                    handleUpdate(orcamento);
                  }}
                >
                  <FaEdit />
                </Button>
              </Col>
            </Row>
          </CardHeader>
        </Col>
      </Row>
    );
  }

  async function handleSave() {
    if (currentTab !== 'resultado') {
      const abaAtual = currentTab;
      const parteFiltrada = orcamentoPartes.filter(
        item => item.parteId === Number(abaAtual),
      );

      if (parteFiltrada && parteFiltrada.length) {
        const proximaAba = parteFiltrada[0].parte.avancarPara ?? 0;
        const validarRegras =
          parteFiltrada[0].parte.executarValidacaoRegras ?? false;

        let continuar = true;
        if (validarRegras) {
          continuar = await triggerValidaRegras({
            fichaTecnicaId: Number(fichaTecnicaId ?? 0),
            atualizarMaterial: true,
            validarAgrupador: false,
            orcamentoVersaoId,
            origem: `Configuração: ${orcamentoVersaoId}`,
            orcamento,
          });
        }

        if (continuar) {
          try {
            setSwal(
              'Executando processos específicos da finalização da parte...',
            );
            await api.post(
              `/configurador-etapas/finalizar-parte/${parteFiltrada[0].id}`,
              {},
            );

            toast.success('Registro salvo');
          } finally {
            setSwal('');
          }

          await getOrcamentoVersao();

          if (proximaAba === 0) {
            setCurrentTab('resultado');
          } else {
            setTriggerStart(true);
            setCurrentTab(String(proximaAba));
          }
        }
      }
    }
  }

  async function handleBloquearPartes() {
    if (currentTab !== 'resultado') {
      const abaAtual = currentTab;
      const parteFiltrada = orcamentoPartes.filter(
        item => item.parteId === Number(abaAtual),
      );

      if (parteFiltrada && parteFiltrada.length) {
        await api.post(
          `/configurador-etapas/finalizar-processo/${parteFiltrada[0].id}`,
          {},
        );

        await getOrcamentoVersao();
      }
    }
  }

  async function handleCarregarVersao() {
    await getOrcamentoVersao();
  }

  async function handleBloquearAbaResultado() {
    const abaAtual = currentTab;
    const parteFiltrada = orcamentoPartes.filter(
      item => item.parteId === Number(abaAtual),
    );

    if (parteFiltrada && parteFiltrada.length) {
      await api.post(
        `/configurador-etapas/parte-andamento/${parteFiltrada[0].id}`,
        {},
      );
    }
    setAtivarBotaoResultado(false);
  }

  function CarregarConteudoParte(parteCarregar: string) {
    if (!parteCarregar || !orcamentoPartes.length) return <></>;

    const isResultado = parteCarregar === 'resultado';
    const parteId = Number(parteCarregar);

    const parte = !isResultado
      ? orcamentoPartes.filter(item => item.parteId === parteId)[0]
      : null;

    let componenteFiltrado = null;

    if (!isResultado)
      componenteFiltrado = parte?.parte?.componente
        ? components.filter(item => item.id === parte.parte.componente)
        : null;
    else
      componenteFiltrado = components.filter(item => item.id === 'resultado');

    const DynamicComponent =
      componenteFiltrado && componenteFiltrado.length
        ? componenteFiltrado[0].componente
        : null;

    return (
      <Row>
        <Col className="order-xl-2">
          <CardHeader className="bg-white border-3">
            <Row style={{ width: '100%' }}>
              {DynamicComponent ? (
                <DynamicComponent
                  onSave={handleSave}
                  onBloquearPartes={handleBloquearPartes}
                  onCarregarVersao={handleCarregarVersao}
                  configuracaoId={orcamentoVersaoId}
                  fichaTecnicaId={fichaTecnicaId}
                  sessaoId={sessaoId}
                  orcamentoParteId={parte?.id ?? 0}
                  parteId={parte?.parteId ?? 0}
                  acessoLimitado={acessoLimitado}
                  triggerStart={false}
                  setFichaTecnicaId={setFichaTecnicaId}
                  setSessaoId={setSessaoId}
                  orcamento={orcamento}
                  fichaTecnicaTemp={{} as FichaTecnica}
                  handleBloquearAbaResultado={handleBloquearAbaResultado}
                />
              ) : (
                <ParteQuestoesFormulario
                  onSave={handleSave}
                  onBloquearPartes={handleBloquearPartes}
                  configuracaoId={orcamentoVersaoId}
                  fichaTecnicaId={fichaTecnicaId}
                  orcamentoParteId={parte?.id ?? 0}
                  parteId={parte?.parteId ?? 0}
                  sessaoId={sessaoId}
                />
              )}
            </Row>
          </CardHeader>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <UserSmallHeader />
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={formModalState}
        toggle={() => setFormModalState(!formModalState)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Representante/Ger. negócios</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <small>Representante / Ger. Negócio</small>
                  <Row>
                    <Col className="order-xl-2">
                      <Input
                        type="select"
                        value={orcamento.representante ?? ''}
                        onChange={event =>
                          setOrcamento({
                            ...orcamento,
                            representante: String(event.target.value),
                          })
                        }
                      >
                        <option value="">Representante...</option>
                        {listaRepresentantes.map(user => (
                          <option
                            key={user.codigoRepresentante}
                            value={`${user.codigoRepresentante} / ${
                              user.codigoGerente !== null
                                ? user.codigoGerente
                                : '--'
                            }`}
                          >
                            {`${user.razaoRepresentante} / ${
                              user.razaoGerente !== null
                                ? user.razaoGerente
                                : '--'
                            }`}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={handleSaveRepresentante}
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {mostrarBotaoAcesso && (
        <ModalAcesso
          configuracaoId={orcamentoVersaoId}
          codigoOportunidade={codOportunidade}
          modalState={modalManterAcesso}
          toggle={() => setModalManterAcesso(false)}
        />
      )}

      <Container fluid>
        <ConfiguracaoDescricao />
        <ConfiguracaoDetalhes />
        <ConfiguracaoEtapasContext.Provider
          value={{
            opcoesLances,
            setOpcoesLances,
            setLancesManual,
            lancesManual,
            orcamentoProduto: { orcamentoProduto },
          }}
        >
          <Row className="mt-3">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
              style={{ width: '100%' }}
            >
              {orcamentoPartes?.map(parte => {
                let isAtivo = true;
                let styleDesativado = {};

                if (parte.parte.requisito) {
                  const requisitoFiltrado = orcamentoPartes.filter(
                    item => item.parteId === parte.parte.requisito,
                  );

                  if (
                    requisitoFiltrado.length &&
                    requisitoFiltrado[0].parteConcluida
                  ) {
                    isAtivo = true;
                  } else {
                    isAtivo = false;
                    styleDesativado = {
                      backgroundColor: '#d1cfcf',
                    };
                  }
                }

                return (
                  <NavItem key={`parte_header_${parte.id}`}>
                    <NavLink
                      disabled={!isAtivo}
                      aria-selected={currentTab === String(parte.parteId)}
                      className={`mb-sm-3 mb-md-0 ${
                        currentTab === String(parte.parteId) ? 'active' : ''
                      }
                    `}
                      onClick={() => {
                        if (currentTab !== String(parte.parteId)) {
                          setTriggerStart(false);
                          setCurrentTab(String(parte.parteId));
                          setCurrentTabContentLoaded(false);
                        }
                      }}
                      style={{ ...styleDesativado }}
                      href="#"
                      role="tab"
                    >
                      {parte.nome}
                    </NavLink>
                  </NavItem>
                );
              })}
              <NavItem key="parte_header_resultado">
                <NavLink
                  disabled={!ativarBotaoResultado}
                  aria-selected={currentTab === 'resultado'}
                  className={`mb-sm-3 mb-md-0 float-right ${
                    currentTab === 'resultado' ? 'active' : ''
                  }`}
                  onClick={() => {
                    if (currentTab !== 'resultado') {
                      setCurrentTab('resultado');
                      setCurrentTabContentLoaded(false);
                    }
                  }}
                  style={{
                    color: 'white',
                    backgroundColor: ativarBotaoResultado
                      ? '#F5365C'
                      : '#736e6f',
                  }}
                  href="#"
                  role="tab"
                >
                  Resultado
                </NavLink>
              </NavItem>
            </Nav>
          </Row>
          <Row>
            <Card className="shadow" style={{ width: '100%' }}>
              <CardBody style={{ width: '100%' }}>
                <TabContent activeTab={`tabs${currentTab}`}>
                  <TabPane tabId={`tabs${currentTab}`}>
                    {CarregarConteudoParte(currentTab)}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </ConfiguracaoEtapasContext.Provider>
      </Container>
    </>
  );
};

export default EtapasOrcamento;

import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import api from 'services/api';
import { FaInfoCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import toast from 'react-hot-toast';
import ModeloSuccaoModel from 'models/ConfiguradorModeloSuccao';
import GenericModal from 'components/GenericModal';
import { TextField, ContainerRow } from './styles';
import { AdutoraBombeamentoNewContext } from '../..';
import Reprocessamento from '../Reprocessamento';

interface FTSuccao {
  metragemCotaA: number | null;
  metragemCotaB: number | null;
  metragemCotaC: number | null;
  succaoQtdeAZ6M: number | null;
  succaoQtdeAZ3M: number | null;
  succaoQtdeAZ2M: number | null;
  succaoQtdeAZ1M: number | null;
  succaoQtdeAZ05M: number | null;
  succaoTamanhoPVC: number | null;
  succaoQtdePVC: number | null;
  comprimentoTotalSuccao: number | null;

  // npsh
  npshDisponivel: number | null;
  diametroSuccaoNpsh: number | null;
}

function Succao(): JSX.Element {
  const {
    orcamentoId,
    fichaTecnicaId,
    fichaTecnica,
    sessaoId,
    necessidadeReprocessamento,
    orcamentoInfoBalsa,
    atualizarFuncoes,
  } = useContext(AdutoraBombeamentoNewContext);

  const [necessarioReprocessar, setIsNecessarioReprocessar] = useState(false);

  const [modelosSuccaoDados, setModelosSuccaoDados] = useState<
    ModeloSuccaoModel[]
  >([]);
  const [camposSuccao, setCamposSuccao] = useState<FTSuccao>();
  const [cotaA, setCotaA] = useState(0);
  const [cotaB, setCotaB] = useState(0);
  const [cotaC, setCotaC] = useState(0);
  const [cotaD, setCotaD] = useState(0);
  const [cotaE, setCotaE] = useState(0);
  const [imagem, setImagem] = useState<string>();
  const [totalSuccao, setTotalSuccao] = useState(0);
  const [tipo, setTipo] = useState('');

  const [loadingCalculoSuccao, setLoadingCalculoSuccao] = useState(false);
  const [mostrarBlocoSuccao, setMostrarBlocoSuccao] = useState(false);

  const [usaCotaA, setUsaCotaA] = useState(false);
  const [usaCotaB, setUsaCotaB] = useState(false);
  const [usaCotaC, setUsaCotaC] = useState(false);
  const [usaCotaD, setUsaCotaD] = useState(false);
  const [usaCotaE, setUsaCotaE] = useState(false);

  const [modalStatusVisivel, setModalStatusVisivel] = useState(false);

  const [modalTitulo, setModalTitulo] = useState('');
  const [rowsModal, setRowsModal] = useState<string[]>([]);

  // npsh
  const [npshDisponivel, setNpshDisponivel] = useState(0);
  const [diametroSuccaoNpsh, setDiametroSuccaoNpsh] = useState(0);

  const [modeloSuccao, setModeloSuccao] = useState('');
  const [alturaSuccao, setAlturaSuccao] = useState(0);

  useEffect(() => {
    if (necessidadeReprocessamento) {
      setIsNecessarioReprocessar(
        necessidadeReprocessamento?.reprocessarSuccao ?? false,
      );
    }
  }, [necessidadeReprocessamento]);

  async function carregaDadosModelosSuccao() {
    const response = await api.get('/integracao/succao/modelo-succao');
    setModelosSuccaoDados(response.data);
  }

  async function carregarCamposSuccao() {
    const response = await api.get(`/ficha-tecnica/${fichaTecnicaId}/succao`);
    setCamposSuccao(response.data);
    setTotalSuccao(response.data?.comprimentoTotalSuccao || 0);
  }

  async function mostrarModal() {
    if (camposSuccao) {
      const {
        succaoQtdeAZ05M: ftSuccaoQtdeAZ05M,
        succaoQtdeAZ1M: ftSuccaoQtdeAZ1M,
        succaoQtdeAZ2M: ftSuccaoQtdeAZ2M,
        succaoQtdeAZ3M: ftSuccaoQtdeAZ3M,
        succaoQtdeAZ6M: ftSuccaoQtdeAZ6M,
        succaoQtdePVC: ftSuccaoQtdePVC,
        succaoTamanhoPVC: ftsuccaoTamanhoPVC,
      } = camposSuccao;

      const rows: any[] = [];

      if (
        ftSuccaoQtdeAZ05M ||
        ftSuccaoQtdeAZ1M ||
        ftSuccaoQtdeAZ2M ||
        ftSuccaoQtdeAZ3M ||
        ftSuccaoQtdeAZ6M ||
        ftSuccaoQtdePVC ||
        ftsuccaoTamanhoPVC
      ) {
        rows.push(`Tamanho tubos PVC: ${ftsuccaoTamanhoPVC || 0}`);
        rows.push(`Quantidade tubos PVC: ${ftSuccaoQtdePVC || 0}`);
        rows.push(`Quantidade tubos AZ 0.5M: ${ftSuccaoQtdeAZ05M || 0}`);
        rows.push(`Quantidade tubos AZ 1M: ${ftSuccaoQtdeAZ1M || 0}`);
        rows.push(`Quantidade tubos AZ 2M: ${ftSuccaoQtdeAZ2M || 0}`);
        rows.push(`Quantidade tubos AZ 3M: ${ftSuccaoQtdeAZ3M || 0}`);
        rows.push(`Quantidade tubos AZ 6M: ${ftSuccaoQtdeAZ6M || 0}`);
      } else {
        rows.push(`Essas informações ainda não foram calculadas`);
      }

      if (rows.length > 0) {
        setRowsModal(rows);
        setModalTitulo(`Tubulação da Sucção`);
        setModalStatusVisivel(true);
      }
    }
  }

  function setSwal(mensagem?: string, type = 'info') {
    if (!mensagem) {
      Swal.close();

      return;
    }

    if (type === 'info') {
      Swal.fire({
        icon: 'info',
        text: mensagem,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      return;
    }

    if (type === 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        html: mensagem,
      });
    }
  }

  useEffect(() => {
    if (fichaTecnica && modelosSuccaoDados.length > 0) {
      const { modeloSuccao: ftModelo, alturaSuccao: ftAltura } = fichaTecnica;

      const modeloDaFicha = modelosSuccaoDados.filter(
        modelo =>
          // modelo.cotaA === 'S' &&
          modelo.nome
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase() ===
          ftModelo
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase(),
      );

      if (modeloDaFicha && modeloDaFicha.length > 0) {
        setMostrarBlocoSuccao(true);

        setUsaCotaA(modeloDaFicha[0].cotaA === 'S');
        setUsaCotaB(modeloDaFicha[0].cotaB === 'S');
        setUsaCotaC(modeloDaFicha[0].cotaC === 'S');
        setUsaCotaD(modeloDaFicha[0].cotaD === 'S');
        setUsaCotaE(modeloDaFicha[0].cotaE === 'S');
        if (modeloDaFicha[0].imagem)
          setImagem(`data:image/jpeg;base64,${modeloDaFicha[0].imagem}`);
        const {
          metragemCotaA: ftMetragemCotaA,
          metragemCotaB: ftMetragemCotaB,
          metragemCotaC: ftMetragemCotaC,
          // npsh
          npshDisponivel: ftNpshDisponivel,
          diametroSuccaoNpsh: ftDiametroSuccaoNpsh,
        } = fichaTecnica;

        setCotaA(ftMetragemCotaA || 0);
        setCotaB(ftMetragemCotaB || 0);
        setCotaC(ftMetragemCotaC || 0);

        // npsh
        setNpshDisponivel(ftNpshDisponivel || 0);
        setDiametroSuccaoNpsh(ftDiametroSuccaoNpsh || 0);
        setTipo(modeloDaFicha[0].nome);

        setModeloSuccao(ftModelo ?? '');
        setAlturaSuccao(ftAltura ?? 0);

        if (modeloDaFicha[0].cotaB !== 'S') {
          setCotaB(0);
        }
        if (modeloDaFicha[0].cotaC !== 'S') {
          setCotaC(0);
        }
        if (modeloDaFicha[0].cotaD !== 'S') {
          setCotaD(0);
        }
        if (modeloDaFicha[0].cotaE !== 'S') {
          setCotaE(0);
        }
      }
    }
  }, [modelosSuccaoDados, fichaTecnica]);

  async function calcularPerdaCarga() {
    setSwal('Calculando Perda de Carga...');
    const response = await api.post(
      '/integracao/bombeamento/perda-carga-bomba/calculo',
      {
        orcamentoId,
        fichaTecnicaId,
      },
    );
    setSwal('');
    return response.data?.necessidadeReprocessamento ?? false;
  }

  async function calculaSuccao() {
    try {
      if (usaCotaA && !cotaA) return;

      if (usaCotaB && !cotaB) return;

      if (usaCotaC && !cotaC) return;

      if (usaCotaD && !cotaD) return;

      if (usaCotaE && !cotaE) return;

      let totalComprimentoSuccao = 0;
      // Fórmulas
      const afogada = cotaA + cotaB + cotaC + cotaD + cotaE;
      const normal = cotaA + cotaB + cotaC + cotaD + cotaE;
      const sifaoNormal = cotaA + cotaB + cotaC + cotaD + cotaE + 2 + 12;
      // eslint-disable-next-line prettier/prettier
      const sifaoAfogada = cotaA + cotaB + cotaD + cotaE + 12 + ( cotaC / Math.sqrt(3) );


      if (
        tipo
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase() === 'SIFAO AFOGADA'
      ) {
        totalComprimentoSuccao = sifaoAfogada;
      } else if (
        tipo
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase() === 'SIFAO NORMAL'
      ) {
        totalComprimentoSuccao = sifaoNormal;
      } else if (
        tipo
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase() === 'NORMAL'
      ) {
        totalComprimentoSuccao = normal;
      } else if (
        tipo
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase() === 'AFOGADA'
      ) {
        totalComprimentoSuccao = afogada;
      } else {
        totalComprimentoSuccao = normal;
      }

      if ((orcamentoInfoBalsa?.isBalsa ?? false) === true) {
        // Condição provisória levantada no bug #3166. Vamos fixar em código o valor 0,5 por enquanto.
        // A possível flexibilização, deixamos para um próximo momento
        totalComprimentoSuccao = 0.5;
      }
      setTotalSuccao(totalComprimentoSuccao);

      setLoadingCalculoSuccao(true);
      await api.post('/integracao/bombeamento/perda-carga-bomba/calculo-npsh', {
        orcamentoId,
        fichaTecnicaId,
        comprimentoTotalSuccao: Number(totalComprimentoSuccao),
      });

      await api.post('/integracao/bombeamento/perda-carga-bomba/succao-balsa', {
        orcamentoId,
        fichaTecnicaId,
      });

      await api.post('/integracao/succao/orcamento/calculo', {
        sessao: sessaoId,
        orcamentoId,
        fichaTecnicaId,
        metragemCotaA: cotaA,
        metragemCotaB: cotaB,
        metragemCotaC: cotaC,
        comprimentoTotalSuccao: Number(totalComprimentoSuccao),
        // npshDisponivel,
        // diametroSuccaoNpsh,
      });

      await carregarCamposSuccao();

      // limpaCotas();
      // Dispara o processo para cálculo da perda de carga
      const reprocessar = await calcularPerdaCarga();

      if (reprocessar === true) {
        Swal.fire({
          icon: 'error',
          text: `O cálculo da perda de carga encontrou um percentual divergente do permitido em relação ao campo Perda de Carga, no bloco de Adutora. Por favor, reprocesse todas as etapas.`,
        });
      }

      toast.success('Cálculo finalizado!', { position: 'top-right' });
      atualizarFuncoes([`SUCCAO`, `${reprocessar ? `ATUALIZAR` : ''}`], false);
    } finally {
      setLoadingCalculoSuccao(false);
    }
  }

  async function carregarDados() {
    await carregaDadosModelosSuccao();
    await carregarCamposSuccao();
  }

  useEffect(() => {
    if (fichaTecnica) {
      carregarDados();
    }
  }, [fichaTecnica]);

  return (
    <>
      <GenericModal
        title={modalTitulo}
        rows={rowsModal}
        modalState={modalStatusVisivel}
        toggle={() => setModalStatusVisivel(false)}
      />
      <Card className="shadow">
        <Row className="mt--3">
          <ColQuestaoTitulo>
            <LabelQuestaoTitulo>Tubos Sucção</LabelQuestaoTitulo>
          </ColQuestaoTitulo>
        </Row>

        <Row hidden={mostrarBlocoSuccao}>
          <Col className="mt-2 pb-4">
            O modelo de sucção selecionado não é uma opção válida para definição
            das cotas neste bloco
          </Col>
        </Row>

        <Row hidden={!mostrarBlocoSuccao}>
          <Col className="mt-2 pb-4">
            {imagem && (
              <img
                className="img-fluid"
                src={`${imagem}`}
                style={{
                  cursor: 'pointer',
                }}
                alt="Cotas"
              />
            )}
          </Col>

          <Col className="mt-2 pb-4">
            <div>
              <Col sm="10" className="mt-3">
                <TextField
                  size="small"
                  label="Valor Cota A"
                  type="number"
                  value={cotaA}
                  hidden={!usaCotaA}
                  onChange={event => {
                    setCotaA(Number(event.target.value) ?? 0);
                  }}
                />
              </Col>

              <Col sm="10" className="mt-3">
                <TextField
                  size="small"
                  label="Valor Cota B"
                  type="number"
                  hidden={!usaCotaB}
                  value={cotaB}
                  onChange={event => setCotaB(Number(event.target.value) ?? 0)}
                />
              </Col>

              <Col sm="10" className="mt-3">
                <TextField
                  size="small"
                  label="Valor Cota C"
                  type="number"
                  hidden={!usaCotaC}
                  value={cotaC}
                  onChange={event => setCotaC(Number(event.target.value) ?? 0)}
                />
              </Col>
              <Col className="mt-3" sm="5" hidden>
                <TextField
                  size="small"
                  label="Elevação da Sucção (m)"
                  type="number"
                  value={0}
                />
              </Col>
              <Col sm="10" className="mt-3">
                <TextField
                  size="small"
                  label="Valor Cota D"
                  type="number"
                  hidden={!usaCotaD}
                  value={cotaD}
                  onChange={event => setCotaD(Number(event.target.value) ?? 0)}
                />
              </Col>
              <Col sm="10" className="mt-3">
                <TextField
                  size="small"
                  label="Valor Cota E"
                  type="number"
                  hidden={!usaCotaE}
                  value={cotaE}
                  onChange={event => setCotaE(Number(event.target.value) ?? 0)}
                />
              </Col>

              <Col sm="7" className="mt-5">
                <Button
                  disabled={loadingCalculoSuccao}
                  className="btn-icon btn-2"
                  color="danger"
                  type="button"
                  onClick={calculaSuccao}
                  size="sm"
                >
                  {loadingCalculoSuccao
                    ? 'Calculando sucção...'
                    : 'Calcular sucção'}
                </Button>
                <Button
                  className="btn-icon btn-2"
                  color="info"
                  type="button"
                  onClick={mostrarModal}
                  size="sm"
                >
                  <FaInfoCircle />
                </Button>
                {necessarioReprocessar && <Reprocessamento />}
              </Col>

              <ContainerRow className="mt-4">
                <Col sm="5" className="mt-3">
                  <TextField
                    disabled
                    size="small"
                    label="Comprimento Total"
                    type="number"
                    value={arredondaDecimais(totalSuccao)}
                  />
                </Col>

                <Col sm="5" className="mt-3">
                  <TextField
                    disabled
                    size="small"
                    label="Altura Sucção"
                    type="number"
                    value={arredondaDecimais(alturaSuccao)}
                  />
                </Col>
              </ContainerRow>
              <ContainerRow className="mt-4">
                <Col className="mt-3" sm="5">
                  <TextField
                    disabled
                    size="small"
                    label="NPSH disponível (m)"
                    type="number"
                    value={Number(npshDisponivel?.toFixed(2))}
                  />
                </Col>
                <Col className="mt-3" sm="5">
                  <TextField
                    disabled
                    size="small"
                    label="Diâmetro Sucção pelo NPSH"
                    type="number"
                    value={diametroSuccaoNpsh}
                  />
                </Col>
              </ContainerRow>
              <br />
              <Row hidden={diametroSuccaoNpsh !== 0}>
                <Col className="mt-2 pb-4">
                  Não foi encontrado resultado do NPSH disponível!
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Succao;

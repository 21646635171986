import FichaTecnica from 'models/FichaTecnica';
import { Card, CardBody, Row } from 'reactstrap';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function DadosBombeamentoBalsa({ fichaTecnica }: Props): JSX.Element {
  const fichaTecnicaBombeamento = fichaTecnica?.FichaTecnicaBombeamento?.filter(
    item => item.isBalsa === true,
  )?.[0];
  const {
    pressaoPrevista,
    vazaoPrevista,
    pressaoAlternativa,
    vazaoAlternativa,
    potenciaAbsorvidaEixoBomba,
  } = fichaTecnicaBombeamento || {};

  return (
    <Card className="shadow">
      <ColQuestaoTitulo>
        <LabelQuestaoTitulo>Bombeamento da Balsa</LabelQuestaoTitulo>
      </ColQuestaoTitulo>

      <CardBody className="p-3">
        <Row>
          <Campo
            sm="12"
            label="Vazão prevista"
            valor={vazaoAlternativa !== 0 ? vazaoAlternativa : vazaoPrevista}
            number
            unidade="m³/h"
          />

          <Campo
            sm="12"
            label="Pressão prevista"
            valor={
              pressaoAlternativa !== 0 ? pressaoAlternativa : pressaoPrevista
            }
            number
            unidade="mca"
          />

          <Campo
            sm="12"
            label="Potência Absorvida no Eixo da Bomba"
            valor={potenciaAbsorvidaEixoBomba}
            number
            unidade="cv"
          />
        </Row>
      </CardBody>
    </Card>
  );
}

export default DadosBombeamentoBalsa;

import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import api from 'services/api';
import TipoBombaData from 'staticData/TipoBombaData';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';

import Swal from 'sweetalert2';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import { toast } from 'react-hot-toast';
import { FaCheckCircle } from 'react-icons/fa';
import ValidarAcessoPrograma from 'models/ValidarAcessoPrograma';
import { AdutoraBombeamentoNewContext } from '../..';

interface TipoBombaStatic {
  tipo: string;
  valoresCombo: number[];
}

function Adutora(): JSX.Element {
  const {
    fichaTecnicaId,
    fichaTecnica,
    orcamentoId,
    necessidadeReprocessamento,
    atualizarFuncoes,
    fichaTecnicaAdutora,
    dadosElevacao,
    respostaFuncaoEntrada,
    acessoProgramas,
    orcamentoInfoBalsa,
  } = useContext(AdutoraBombeamentoNewContext);

  const [tipoCota, setTipoCota] = useState('');
  const [tipoCotaParaTubulacao, setTipoCotaParaTubulacao] = useState('');

  const [perdaCarga, setPerdaCarga] = useState(0);
  const [loadingSaveAdutora, setLoadingSaveAdutora] = useState(false);

  const [bloquearPerdaCarga, setBloquearPerdaCarga] = useState(false);
  const [tipoBombaList, setTipoBombaList] = useState<TipoBombaStatic[]>([]);
  const [qtdeBombaList, setQtdeBombaList] = useState<number[]>([]);
  const [acessoPrograma, setAcessoPrograma] = useState(
    {} as ValidarAcessoPrograma,
  );
  const [atualizarAdutora, setAtualizarAdutora] = useState(false);

  const [bomba, setBomba] = useState('');
  const [quantidadeBomba, setQuantidadeBomba] = useState(0);
  const [quantidadeVentosa, setQuantidadeVentosa] = useState(0);
  const [quantidadeModulos, setQuantidadeModulos] = useState(1);
  const [bombeamentoMestre, setBombeamentoMestre] = useState('');

  const [necessarioReprocessar, setIsNecessarioReprocessar] = useState(false);

  async function alterarBomba(varBomba: string) {
    setBomba(varBomba);

    const tipoFiltrado = tipoBombaList.find(
      tipo => tipo.tipo.toUpperCase() === varBomba.toUpperCase(),
    );
    if (tipoFiltrado && tipoFiltrado?.valoresCombo.length > 0)
      setQtdeBombaList(tipoFiltrado?.valoresCombo);

    setQuantidadeBomba(0);
  }

  useEffect(() => {
    setTipoBombaList(TipoBombaData);
  }, []);

  useEffect(() => {
    if (acessoProgramas && acessoProgramas.length) {
      setAcessoPrograma(
        acessoProgramas?.find(item => item.programa === 77) ?? {
          acessoLimitado: false,
          temAcesso: false,
        },
      );
    }
  }, [acessoProgramas]);

  useEffect(() => {
    if (necessidadeReprocessamento) {
      setIsNecessarioReprocessar(
        necessidadeReprocessamento?.reprocessarAdutora ?? false,
      );
    }
  }, [necessidadeReprocessamento]);

  useEffect(() => {
    if (loadingSaveAdutora) {
      toast.loading('Aguarde...', { position: 'top-right' });
      return;
    }

    toast.dismiss();
  }, [loadingSaveAdutora]);

  async function salvarAdutora() {
    const isCamposPreenchidos =
      tipoCota &&
      bombeamentoMestre &&
      bomba &&
      quantidadeBomba &&
      quantidadeModulos;
    if (!isCamposPreenchidos) {
      Swal.fire({
        icon: 'error',
        text: `Algum campo da adutora não foi respondido!`,
      });
      return;
    }

    let quantidadeCurvas5 = 0;
    let quantidadeCurvas10 = 0;
    let quantidadeCurvas15 = 0;
    let quantidadeCurvas30 = 0;
    let quantidadeCurvas45 = 0;
    let quantidadeCurvas60 = 0;
    let quantidadeCurvas90 = 0;
    const quantidadeCurvas = dadosElevacao?.totalCurvasEncontradas ?? 0;
    if (dadosElevacao?.curvasToSave) {
      const curvas: string[] = dadosElevacao?.curvasToSave
        ?.replaceAll('°', '')
        .split(',');

      quantidadeCurvas5 = curvas.filter(item => item === '5').length || 0;
      quantidadeCurvas10 = curvas.filter(item => item === '10').length || 0;
      quantidadeCurvas15 = curvas.filter(item => item === '15').length || 0;
      quantidadeCurvas30 = curvas.filter(item => item === '30').length || 0;
      quantidadeCurvas45 = curvas.filter(item => item === '45').length || 0;
      quantidadeCurvas60 = curvas.filter(item => item === '60').length || 0;
      quantidadeCurvas90 = curvas.filter(item => item === '90').length || 0;
    }

    try {
      setLoadingSaveAdutora(true);

      await api.post('/integracao/adutora/orcamento/salvar', {
        orcamentoId,
        fichaTecnicaId,
        tipoCota,
        bombeamentoMestre,
        percentualPerdaCarga: perdaCarga,
        bomba,
        quantidadeBomba,
        tipoExecucao: 'T',
        quantidadeVentosa,
        quantidadeCurvas5,
        quantidadeCurvas10,
        quantidadeCurvas15,
        quantidadeCurvas30,
        quantidadeCurvas45,
        quantidadeCurvas60,
        quantidadeCurvas90,
        quantidadeCurvas,
        quantidadeModulos,
        limparCampos: tipoCota !== tipoCotaParaTubulacao,
        isBalsa: false,
      });

      if ((orcamentoInfoBalsa?.isBalsa ?? false) === true) {
        await api.post('/integracao/adutora/orcamento/salvar', {
          orcamentoId,
          fichaTecnicaId,
          tipoCota,
          bombeamentoMestre,
          percentualPerdaCarga: perdaCarga,
          bomba,
          quantidadeBomba,
          tipoExecucao: 'T',
          quantidadeVentosa,
          quantidadeCurvas5,
          quantidadeCurvas10,
          quantidadeCurvas15,
          quantidadeCurvas30,
          quantidadeCurvas45,
          quantidadeCurvas60,
          quantidadeCurvas90,
          quantidadeCurvas,
          quantidadeModulos,
          limparCampos: tipoCota !== tipoCotaParaTubulacao,
          isBalsa: true,
        });
      }
      atualizarFuncoes(['ADUTORA'], false);
    } finally {
      setLoadingSaveAdutora(false);
    }
    setAtualizarAdutora(false);
  }

  async function setaDadosFichaTecnicaAdutora() {
    if (!fichaTecnicaAdutora) {
      const { desnivelCaptacaoCentroPivo } = respostaFuncaoEntrada;

      setPerdaCarga((desnivelCaptacaoCentroPivo ?? 0) > 0 ? 10 : 20);
      return;
    }

    setQuantidadeVentosa(dadosElevacao?.quantidadeVentosa ?? 0);

    const bloquearCampoPerda =
      fichaTecnicaAdutora.bloquearCampoPerdaCarga ?? false;

    let bloquearPerda = false;

    if (bloquearCampoPerda) {
      if (!acessoPrograma || acessoPrograma.temAcesso === false)
        bloquearPerda = true;
    }

    setBloquearPerdaCarga(bloquearPerda);

    setTipoCota(fichaTecnicaAdutora.tipoCota || '');
    setTipoCotaParaTubulacao(fichaTecnicaAdutora.tipoCota || '');
    setBombeamentoMestre(fichaTecnicaAdutora.bombeamentoMestre || '');
    setPerdaCarga(fichaTecnicaAdutora.percentualPerdaCarga || 0);
    const varBomba = fichaTecnicaAdutora.bomba || '';
    setBomba(varBomba);
    const tipoFiltrado = TipoBombaData.find(tipo => tipo.tipo === varBomba);

    if (tipoFiltrado && tipoFiltrado?.valoresCombo.length > 0) {
      setQtdeBombaList(tipoFiltrado?.valoresCombo);

      setQuantidadeBomba(fichaTecnicaAdutora.quantidadeBomba || 0);
    }
  }

  async function carregarDados() {
    await setaDadosFichaTecnicaAdutora();
  }

  useEffect(() => {
    if (fichaTecnica) {
      carregarDados();
    }
  }, [fichaTecnica, fichaTecnicaAdutora]);

  useEffect(() => {
    if (atualizarAdutora === true) {
      setIsNecessarioReprocessar(true);
    }
  }, [atualizarAdutora]);

  return (
    <Card>
      <Row className="mt--3">
        <ColQuestaoTitulo>
          <LabelQuestaoTitulo>Adutora</LabelQuestaoTitulo>
        </ColQuestaoTitulo>
      </Row>

      <Card className="shadow">
        <Row>
          <Col className="mt-2 ml-2 mb-2 mr--2">
            <Row>
              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Tipo cota</InputLabel>
                  <Select
                    value={tipoCota}
                    label="Tipo cota"
                    onChange={event =>
                      setTipoCota(event.target.value as string)
                    }
                  >
                    <MenuItem value="Linear" disabled>
                      Linear
                    </MenuItem>
                    <MenuItem value="Específica">Específica</MenuItem>
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Bombeamento mestre</InputLabel>
                  <Select
                    value={bombeamentoMestre}
                    label="Bombeamento mestre"
                    onChange={event =>
                      setBombeamentoMestre(event.target.value as string)
                    }
                  >
                    <MenuItem value="Não" selected>
                      Não
                    </MenuItem>
                    {/* <MenuItem value="Sim">Sim</MenuItem> */}
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Bomba</InputLabel>
                  <Select
                    value={bomba}
                    label="Bomba"
                    autoWidth
                    onChange={event => {
                      alterarBomba(event.target.value as string);
                      setAtualizarAdutora(true);
                    }}
                  >
                    {tipoBombaList.map(tipo => (
                      <MenuItem key={tipo.tipo} value={tipo.tipo}>
                        {tipo.tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col sm="2">
                <TextField
                  size="small"
                  type="number"
                  InputProps={{
                    style: { fontSize: 14 },
                    inputProps: { max: 100 },
                  }}
                  disabled={bloquearPerdaCarga}
                  label="Perda carga (%)"
                  value={perdaCarga}
                  onChange={event => {
                    setPerdaCarga(Number(event.target.value) ?? 0);
                    setAtualizarAdutora(true);
                  }}
                />
              </Col>

              <Col sm="2">
                <FormControl fullWidth>
                  <InputLabel>Qtde bomba</InputLabel>
                  <Select
                    value={quantidadeBomba}
                    label="Qtde bomba"
                    autoWidth
                    onChange={event => {
                      setQuantidadeBomba(event.target.value as number);
                      setAtualizarAdutora(true);
                    }}
                  >
                    {qtdeBombaList.map(tipo => (
                      <MenuItem key={tipo} value={tipo}>
                        {tipo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

              <Col sm="1">
                <TextField
                  size="small"
                  label="Qtde módulos"
                  type="number"
                  value={quantidadeModulos}
                  onChange={event => {
                    setQuantidadeModulos(Number(event.target.value) ?? 0);
                    setAtualizarAdutora(true);
                  }}
                />
              </Col>
              <Col sm="1">
                <div className="pull-right float-right mr-4 mt-4">
                  <Button
                    disabled={loadingSaveAdutora}
                    className="btn-iconmr-0 btn-4"
                    color={necessarioReprocessar ? 'warning' : 'primary'}
                    type="button"
                    size="sm"
                    title="Atualizar dados"
                    onClick={() => salvarAdutora()}
                  >
                    <FaCheckCircle />
                    {necessarioReprocessar ? ' Reprocessar' : ''}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Card>
  );
}

export default Adutora;

import ReactDOM from 'react-dom';
import { Card, CardBody, Col, Form, Row } from 'reactstrap';
import './style.css';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import api from 'services/api';
import toast from 'react-hot-toast';
import { PropostaVersao } from 'models/PropostaVersao';

interface ICompras {
  propostaVersao: PropostaVersao;
  statusIdAtual: number;
  carregaPropostaVersao: () => void;
}

interface Dados {
  aprovacaoNecessaria: boolean;
}

const STATUS_GERADO_ID = 1;
const STATUS_CONCLUIDO_PELA_ENGENHARIA_ID = 7;

export default function Compras({
  propostaVersao,
  statusIdAtual,
  carregaPropostaVersao,
}: ICompras): JSX.Element {
  const [pvcPorContaMarcado, setPvcPorContaMarcado] = useState(
    propostaVersao.pvcPorContaCliente,
  );

  // Necessário pois inicialmente vem como undefined
  useEffect(() => {
    setPvcPorContaMarcado(propostaVersao?.pvcPorContaCliente);
  }, [propostaVersao]);

  async function updateStatus() {
    try {
      Swal.fire({
        icon: 'info',
        text: `Trocando para PVC por conta do cliente...`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      // Enviando a alteração para status 9 - Escopo Concluído
      const retorno = await api.put(
        `proposta/versao/${propostaVersao.id}/status/9`,
        {},
      );

      const dados: Dados = retorno.data;

      if (dados.aprovacaoNecessaria) {
        Swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: `A validação encontrou uma necessidade de aprovação, para concluir essa operação de exclusão.`,
          confirmButtonText: `Ok`,
        });

        setPvcPorContaMarcado(false);
      } else {
        toast.success(`Status alterado para escopo concluído.`, {
          position: 'top-right',
        });
        Swal.close();
      }

      // Atualizar a tela
      await carregaPropostaVersao();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: error?.response?.data?.message,
      });
      setPvcPorContaMarcado(false);
    }
  }

  function handleChange() {
    setPvcPorContaMarcado(true);
    async function openModal() {
      const wrapper = document.createElement('div');

      Swal.fire({
        title: `Esta operação não pode ser desfeita.`,
        html: wrapper,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, prosseguir`,
        confirmButtonColor: '#d33',
        cancelButtonText: `Não`,
      }).then(result => {
        if (result.isConfirmed) {
          updateStatus();
        } else if (result.dismiss) {
          setPvcPorContaMarcado(false);
        }
      });

      ReactDOM.render(
        <div>
          <strong>
            Ao marcar essa seleção a configurações associadas a esta proposta
            serão bloqueadas para alteração. Deseja prosseguir?
          </strong>
        </div>,
        wrapper,
      );
    }

    openModal();
  }

  return (
    <div>
      <Card className="bg-secondary shadow mt-1 mb-1">
        <CardBody>
          <Form>
            <Row className="mb-4">
              <Col className="d-flex align-items-center">
                <strong className="mr-4">PVC por conta do cliente</strong>
                <label className="custom-toggle ml-2">
                  <input
                    type="checkbox"
                    onChange={handleChange}
                    checked={pvcPorContaMarcado}
                    disabled={
                      !(
                        statusIdAtual === STATUS_GERADO_ID ||
                        statusIdAtual === STATUS_CONCLUIDO_PELA_ENGENHARIA_ID
                      )
                    }
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    placeholder="teste"
                  />
                </label>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

import { useContext } from 'react';
import { Button } from 'reactstrap';
import { useQueryClient } from 'react-query';
import { FaFileExcel } from 'react-icons/fa';

import { PropostaMaterial } from 'models/PropostaMaterial';
import { PropostaMaterialAvulso } from 'models/PropostaMaterialAvulso';

import exportToSheet from 'utils/exportToSheet';

import { PropostaContext } from 'views/pages/Proposta';
import { PropostaConfiguracao } from 'models/PropostaConfiguracao';
import formatCurrency from 'utils/formatCurrency';
import { MateriaisContext } from '../..';

interface Props {
  propostaConfiguracoes: PropostaConfiguracao[];
  className?: string;
  acessoLimitado: boolean;
}

interface ConfiguracaoMaterialRegistro {
  atividade: number;
  materiais: PropostaMaterial[];
  total: number;
}

interface ConfiguracaoMaterial {
  registros: ConfiguracaoMaterialRegistro[];
  total: number;
}

interface Planilha {
  Atividade: number | string | null;
  Item: string;
  Quantidade?: number | null;
  'Valor unitário'?: string;
  'Valor total'?: string;
}

export function DownloadPlanilha({
  propostaConfiguracoes,
  className,
  acessoLimitado,
}: Props): JSX.Element {
  const queryClient = useQueryClient();
  const { propostaVersao } = useContext(PropostaContext);
  const { loadingMateriais } = useContext(MateriaisContext);
  const dadosPlanilha: Planilha[] = [];

  function insereCabecalhoConfiguracao(descricao: string | null) {
    const quebraLinha = {
      Atividade: '',
      Item: '',
    };
    const cabecalho = {
      Atividade: '',
      Item: `${descricao}`,
    };
    dadosPlanilha.push(quebraLinha);
    dadosPlanilha.push(cabecalho);
  }

  function insereItem(
    propostaMaterial: PropostaMaterial | PropostaMaterialAvulso,
  ) {
    const { atividade, material, quantidade, valor, valorTotal } =
      propostaMaterial;
    const { diteCodigo, descricao } = material;
    const item = {
      Atividade: atividade,
      Item: `${diteCodigo} - ${descricao}`,
      Quantidade: quantidade,
      'Valor unitário': !acessoLimitado ? formatCurrency(valor ?? 0, 2) : '',
      'Valor total': !acessoLimitado ? formatCurrency(valorTotal ?? 0, 2) : '',
    };
    dadosPlanilha.push(item);
  }

  function insereAtividadeTotal(
    configuracaoMaterialRegistro: ConfiguracaoMaterialRegistro,
  ) {
    const { atividade, total } = configuracaoMaterialRegistro;
    const atividadeTotal = {
      Atividade: '',
      Item: '',
      Quantidade: null,
      'Valor unitário': !acessoLimitado
        ? `Total atividade ${atividade || ''}`
        : '',
      'Valor total': !acessoLimitado ? formatCurrency(total, 2) : '',
    };
    dadosPlanilha.push(atividadeTotal);
  }

  function insereMateriais(configuracaoMaterial?: ConfiguracaoMaterial) {
    configuracaoMaterial?.registros.forEach(configuracaoMaterialRegistro => {
      const { materiais } = configuracaoMaterialRegistro;
      materiais.forEach(propostaMaterial => {
        insereItem(propostaMaterial);
      });
      insereAtividadeTotal(configuracaoMaterialRegistro);
    });
  }

  function insereTotalGeral(total?: number) {
    const totalGeral = {
      Atividade: '',
      Item: '',
      Quantidade: null,
      'Valor unitário': !acessoLimitado ? 'Total geral' : '',
      'Valor total': !acessoLimitado ? formatCurrency(total, 2) : '',
    };
    dadosPlanilha.push(totalGeral);
  }

  function insereConfiguracaoPropostaDadosPlanilha() {
    propostaConfiguracoes.forEach(propostaConfiguracao => {
      const configuracaoMaterial: ConfiguracaoMaterial | undefined =
        queryClient.getQueryData(
          `proposta-configuracao-materiais-${propostaConfiguracao.id}`,
        );

      insereCabecalhoConfiguracao(
        `Pivô ${propostaConfiguracao.fichaTecnica?.pivoCentral}`,
      );
      insereMateriais(configuracaoMaterial);
      insereTotalGeral(configuracaoMaterial?.total);
    });
  }

  function insereMateriaisAvulsosDadosPlanilha() {
    const queryAvulsos: PropostaMaterialAvulso[] | undefined =
      queryClient.getQueryData([
        'proposta-configuracao-materiais-avulsos',
        propostaVersao.id,
      ]);
    if (!queryAvulsos) {
      return;
    }

    insereCabecalhoConfiguracao('Materiais avulsos da proposta');
    queryAvulsos.forEach(avulso => insereItem(avulso));
    const valorTotalAvulsos = queryAvulsos.reduce(
      (total, item) => total + Number(item.valorTotal || 0),
      0,
    );
    insereTotalGeral(valorTotalAvulsos);
  }

  function getTipoHeader(tipo: any) {
    if (String(tipo.tipoImportacao).toUpperCase() === 'OUTROS') {
      return tipo.finalidade ?? '';
    }
    if (String(tipo.tipoImportacao).toUpperCase() === 'BM') {
      return 'Bombeamento Mestre';
    }

    return tipo.tipo;
  }

  function insereMateriaisImportadosDadosPlanilha() {
    const queryImportados: any[] | undefined = queryClient.getQueryData([
      'proposta-configuracao-materiais-importados',
      propostaVersao.id,
    ]);
    console.log(queryImportados);

    if (!queryImportados) {
      return;
    }

    queryImportados.forEach((importado: any) => {
      insereCabecalhoConfiguracao(
        `${getTipoHeader(importado)} | ${importado.titulo} | ${importado.data}`,
      );

      if (
        importado.PropostaMaterialAvulso &&
        importado.PropostaMaterialAvulso.length
      ) {
        importado.PropostaMaterialAvulso.forEach((item: any) =>
          insereItem(item),
        );

        insereTotalGeral(importado?.valorTotal);
      }
    });
  }

  async function exportarPlanilha() {
    // limpa o array
    dadosPlanilha.splice(0, dadosPlanilha.length);

    insereConfiguracaoPropostaDadosPlanilha();
    insereMateriaisImportadosDadosPlanilha();
    insereMateriaisAvulsosDadosPlanilha();
    const nomeArquivo = `Materiais - Proposta ${propostaVersao.propostaId} (v${propostaVersao.versao})`;
    exportToSheet(dadosPlanilha, nomeArquivo);
  }

  return (
    <div className={className}>
      <Button
        disabled={loadingMateriais}
        size="sm"
        className="btn-icon btn-2"
        style={{ backgroundColor: '#1a8c5b' }}
        color="success"
        type="button"
        onClick={exportarPlanilha}
      >
        <FaFileExcel />
        <span className="btn-inner--text">Exportar materiais</span>
      </Button>
    </div>
  );
}

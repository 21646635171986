import { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';
import FichaTecnica from 'models/FichaTecnica';
import { TextField } from '@material-ui/core';
import { FichaTecnicaBombeamento } from 'models/FichaTecnicaBombeamento';
import { AdutoraBombeamentoNewContext } from '../..';

function EletricaBalsa(): JSX.Element {
  const { fichaTecnica, necessidadeReprocessamento } = useContext(
    AdutoraBombeamentoNewContext,
  );

  const [mostrarConteudo, setMostrarConteudo] = useState(false);
  const [ficha, setFicha] = useState<FichaTecnica>();
  const [fichaBombeamento, setFichaBombeamento] =
    useState<FichaTecnicaBombeamento>();

  useEffect(() => {
    if (fichaTecnica) {
      const fichaBombeamentoFiltro =
        fichaTecnica?.FichaTecnicaBombeamento?.filter(
          (item: any) => item.isBalsa === true,
        );

      if (fichaBombeamentoFiltro) {
        setFichaBombeamento(fichaBombeamentoFiltro[0]);
      }

      setFicha(fichaTecnica);
    }
  }, [fichaTecnica]);

  useEffect(() => {
    if (fichaTecnica && necessidadeReprocessamento) {
      let mostrar = false;

      const reprocessar =
        necessidadeReprocessamento?.reprocessarBombeamentoBalsa ?? false;

      if (!reprocessar) {
        if (
          fichaTecnica.comprimentoCaboFase &&
          fichaTecnica.comprimentoCaboNeutro
        )
          mostrar = true;
      }

      setMostrarConteudo(mostrar);
    }
  }, [fichaTecnica, necessidadeReprocessamento]);

  return (
    <>
      <Card className="shadow">
        <Row className="mt--3">
          <ColQuestaoTitulo>
            <LabelQuestaoTitulo>Elétrica da Balsa</LabelQuestaoTitulo>
          </ColQuestaoTitulo>
        </Row>

        <Row hidden={mostrarConteudo}>
          <Col className="mt-2 pb-4">
            As informações serão mostradas após a execução do cálculo do
            bombeamento da balsa.
          </Col>
        </Row>

        <Row hidden={!mostrarConteudo}>
          <Col sm="3" className="mt-2 mb-2 ml-1">
            <TextField
              disabled
              fullWidth
              label="Diâmetro Cabo"
              value={`${ficha?.diametroCabo ?? 0}`}
            />
          </Col>
          <Col sm="3" className="mt-2 mb-2 ml-1">
            <TextField
              disabled
              fullWidth
              label="Comprimento Cabo Fase"
              value={`${ficha?.comprimentoCaboFase ?? 0} m`}
            />
          </Col>
          <Col sm="3" className="mt-2 mb-2 ml-1">
            <TextField
              disabled
              fullWidth
              label="Comprimento Cabo Neutro"
              value={`${ficha?.comprimentoCaboNeutro ?? 0} m`}
            />
          </Col>
          <Col sm="2" className="mt-2 mb-2">
            <TextField
              disabled
              hidden={ficha?.mesmaCasaMaquinas ?? true}
              size="small"
              label="Potência nominal trafo"
              value={`${
                fichaBombeamento?.potenciaCaboTrafoTransformador ?? ''
              } kVA`}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default EletricaBalsa;

import { useState, useEffect } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { arredondaDecimais } from 'utils/arredondaDecimais';
import api from 'services/api';
import { AssinaturaCol } from './styles';
import './style.css';
import LogoImg from '../../assets/img/logo_fck.png';

import AspersorModel from '../../../../../models/ConfiguradorAspersor';
import { PropostaLocalObra } from '../../../../../models/PropostaLocalObra';
// eslint-disable-next-line import/order
import axios from 'axios';

interface Props {
  configuracaoId: number;
}

interface OrcamentoPontosMapaPontos {
  latitude?: number | undefined;
  longitude?: number | undefined;
  elevacao?: number | undefined;
  distancia?: number | undefined;
  ordem?: number | undefined;
  angulo?: number | undefined;
  grausCalculado?: string[];
}

export default function ImpressaoBalsa(props: Props): JSX.Element {
  const { configuracaoId } = props;

  const [dadosImpressao, setDadosImpressao] = useState<any>({});
  const [mostrarDados, setMostrarDados] = useState<boolean>(false);
  const [aspersores, setAspersores] = useState([] as any[]);
  const [localizacao, setLocalizacao] = useState('');

  const [mostrarAdutora, setMostrarAdutora] = useState<boolean>(false);
  const [mostrarSuccao, setMostrarSuccao] = useState<boolean>(false);
  const [mostrarBombeamento, setMostrarBombeamento] = useState<boolean>(false);
  const [propostaLocalObra, setPropostaLocalObra] = useState(
    {} as PropostaLocalObra,
  );
  const propostaVersaoId =
    dadosImpressao?.PropostaConfiguracao?.[0]?.propostaVersaoId;
  const [localSede, setLocalSede] = useState<OrcamentoPontosMapaPontos[]>([]);
  const [pais, setPais] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [isSubmersa, setIsSubmersa] = useState<boolean>(false);
  const [isBalsa, setIsBalsa] = useState<boolean>(false);
  const [isBalsaAuxiliar, setIsBalsaAuxiliar] = useState<boolean>(false);

  const defaultStyle = {
    border: '1px solid black',
    marginTop: '5px',
    marginBottom: 0,
    padding: '5px',
    lineHeight: '25px',
  };

  const styleInProgress = {
    color: 'red',
    fontSize: 18,
  };

  let defaultBreak = 'div-print-style';

  async function buscarDadosImpressao() {
    const response = await api.get(
      `/configuracao/impressao/irrigacao/${configuracaoId}`,
    );

    if (response.data.orcamentoId) {
      setMostrarDados(true);
    }

    setDadosImpressao(response.data);
  }

  async function buscarTiposSuccao() {
    const response = await api.get(
      `/orcamento/versao/${configuracaoId}/informacao-succao`,
    );
    setIsSubmersa(response.data.isSubmersa);
    setIsBalsa(response.data.isBalsa);
    setIsBalsaAuxiliar(response.data.isBalsaAuxiliar);
  }

  async function buscarListaAspersores() {
    const response = await api.get('/integracao/aspersores');

    const lista: AspersorModel[] = response.data;

    setAspersores(lista);
  }

  async function carregaLocalObra() {
    if (propostaVersaoId) {
      const response = await api.get(
        `/proposta/${propostaVersaoId}/local-obra`,
      );
      setPropostaLocalObra(response.data);
    }
  }

  async function buscarLocalizacao() {
    const response = await api.get(`/orcamento/${configuracaoId}`);

    if (response.data.caminhoKml) {
      const kmlResponse = await axios.get(response.data.caminhoKml);
      const sede = kmlResponse.data?.elements?.find(
        (item: any) => item.nome === 'sede',
      );

      if (kmlResponse.data.length > 100) {
        kmlResponse.data = undefined;
      }
      if (kmlResponse.data !== undefined) {
        const latlng = new google.maps.LatLng(
          sede.geometry?.lat,
          sede.geometry?.lng,
        );
        if (latlng !== null && latlng !== undefined) {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ location: latlng }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results && results[0]) {
                const add = results[0].address_components;
                const value = add;
                const count = value.length;
                setEstado(
                  value.find(item =>
                    item.types.includes('administrative_area_level_1'),
                  )?.short_name ?? '',
                );
                setCidade(
                  value.find(item =>
                    item.types.includes('administrative_area_level_2'),
                  )?.short_name ?? '',
                );
              }
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    buscarListaAspersores();
    buscarTiposSuccao();
  }, []);

  useEffect(() => {
    carregaLocalObra();
  }, [propostaVersaoId]);

  useEffect(() => {
    if (aspersores.length) {
      buscarDadosImpressao();
    }
  }, [aspersores]);

  useEffect(() => {
    if (dadosImpressao) {
      const ficha = dadosImpressao?.fichaTecnica ?? null;
      if (ficha) {
        const ftAdutora = ficha.FichaTecnicaAdutora;

        // const ftAdutoraTrechos = ficha?.FichaTecnicaAdutoraTubulacao ?? null;
        const ftAdutoraTrechos =
          ficha?.FichaTecnicaAdutoraTubulacao.filter(
            (item: any) => item.tubulacaoSuccao === true,
          ) ?? null;

        const ftBombeamento = ficha?.FichaTecnicaBombeamento.filter(
          (item: any) => item.isBalsa === true,
        );
        const { modeloSuccao, comprimentoTotalSuccao } = ficha;

        setMostrarSuccao(!!modeloSuccao && !!comprimentoTotalSuccao);
        setMostrarAdutora(!!ftAdutora && ftAdutoraTrechos.length > 0);

        const { marcaBomba } =
          (ftBombeamento.length && ftBombeamento[0]) ?? null;

        setMostrarBombeamento(!!marcaBomba);
      }
    }
  }, [dadosImpressao]);

  useEffect(() => {
    buscarLocalizacao();
    // latLngToLocal();
  }, []);

  function renderCabecalho() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const style1 = {};

    const respostas = dadosImpressao?.respostas ?? null;

    if (!fichaTecnica || !respostas) return '';

    const { modeloPivo, createdAt } = fichaTecnica ?? null;

    const { numeroPivo, posicaoCentroPivo } = respostas ?? null;

    const data = createdAt;
    return (
      <>
        <Row>
          <Col sm="6">
            <strong>Cliente:</strong>
            {` ${dadosImpressao?.pessoa?.codigoDomsge} - ${dadosImpressao?.pessoa?.razaoSocial}`}
          </Col>
          <Col sm="3">
            <strong>Nº da Configuração:</strong>
            {` ${configuracaoId}`}
          </Col>
          <Col sm="3">
            <strong>Pivô Nº:</strong>
            {` ${numeroPivo || ''}`}
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <strong>Cidade:</strong>
            {` ${cidade ?? ''}`}
          </Col>
          <Col sm="3">
            <strong>UF:</strong>
            {` ${estado ?? ''}`}
          </Col>
          <Col sm="3">
            <strong>Data:</strong>
            {` ${
              new Intl.DateTimeFormat('pt-BR').format(new Date(data)) || ''
            }`}
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <strong>Coordenadas:</strong>
            {` ${posicaoCentroPivo || ''}`}
          </Col>
          <Col sm="6">
            <strong>Pivô {modeloPivo || ''}</strong>
          </Col>
        </Row>
      </>
    );
  }

  function renderTabelaLances() {
    const fichaTecnicaLances =
      dadosImpressao?.fichaTecnica?.FichaTecnicaLance ?? null;

    if (!fichaTecnicaLances) return '';

    const isMoreThanLimit = fichaTecnicaLances.length < 5;

    if (isMoreThanLimit) {
      return (
        <>
          {fichaTecnicaLances.map((lance: any) => {
            return (
              <tr className="table-minimum-padding">
                <td className="table-minimum-padding">{lance.quantidade}</td>
                <td className="table-minimum-padding">
                  Lance {lance.tipoLance}
                </td>
                <td className="table-minimum-padding">
                  {lance.diametroExterno}
                </td>
                <td className="table-minimum-padding">{lance.comprimento} M</td>
                <td className="table-minimum-padding">{lance.total} M</td>
              </tr>
            );
          })}
          <tr
            className="table-minimum-padding"
            hidden={fichaTecnicaLances.length + 1 > 5}
          >
            <td colSpan={5} className="table-minimum-padding">
              <span>-</span>
            </td>
          </tr>
          <tr
            className="table-minimum-padding"
            hidden={fichaTecnicaLances.length + 2 > 5}
          >
            <td colSpan={5} className="table-minimum-padding">
              <span>-</span>
            </td>
          </tr>
          <tr
            className="table-minimum-padding"
            hidden={fichaTecnicaLances.length + 3 > 5}
          >
            <td colSpan={5} className="table-minimum-padding">
              <span>-</span>
            </td>
          </tr>
          <tr
            className="table-minimum-padding"
            hidden={fichaTecnicaLances.length + 4 > 5}
          >
            <td colSpan={5} className="table-minimum-padding">
              <span>-</span>
            </td>
          </tr>
        </>
      );
    }
    return (
      <>
        {fichaTecnicaLances.map((lance: any) => {
          return (
            <tr className="table-minimum-padding">
              <td className="table-minimum-padding">{lance.quantidade}</td>
              <td className="table-minimum-padding">Lance {lance.tipoLance}</td>
              <td className="table-minimum-padding">{lance.diametroExterno}</td>
              <td className="table-minimum-padding">{lance.comprimento} M</td>
              <td className="table-minimum-padding">{lance.total} M</td>
            </tr>
          );
        })}
      </>
    );
  }

  function renderComposicaoPivo() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const respostas = dadosImpressao?.respostas ?? null;

    if (!fichaTecnica || !respostas) return '';

    const {
      alturaLivreEntreTorres,
      balanco,
      comprimentoAteUltimaTorre,
      comprimentoTotalTubos,
      areaTotalIrrigadaCAF,
      areaEfetivaIrrigadaSAF,
      aspersor1Raio,
      aspersor2Raio,
      areaCircularIrrigada360,
      incluirAspersorFinal,
    } = fichaTecnica ?? null;

    const usaAspersorFinal: boolean =
      String(incluirAspersorFinal).toUpperCase() === 'SIM' ||
      String(incluirAspersorFinal).toUpperCase() === 'S';

    const valorArea = usaAspersorFinal
      ? Number(areaTotalIrrigadaCAF ?? 0)
      : Number(areaEfetivaIrrigadaSAF ?? 0);

    const raio1 = Number(aspersor1Raio ?? 0);
    const raio2 = Number(aspersor2Raio ?? 0);

    const { anguloRotacaoIrrigado, numeroPosicoes } = respostas ?? null;

    return (
      <>
        <Row>
          <Col sm="12">
            <span className="header-topic">Composição do Pivô:</span>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            Altura Livre Entre as Torres: {alturaLivreEntreTorres ?? ''} M
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Table bordered>{renderTabelaLances()}</Table>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            Balanço
            <span className="float-right">{balanco || ''} M</span>
          </Col>
          <Col sm="12">
            Comprimento Até a Última Torre:
            <span className="float-right">
              {comprimentoAteUltimaTorre || ''} M
            </span>
          </Col>
          <Col sm="12">
            Comprimento Total dos Tubos:
            <span className="float-right">
              {arredondaDecimais(comprimentoTotalTubos) || ''} M
            </span>
          </Col>
          <Col sm="12">
            <strong>Área Irrigada</strong>
          </Col>
          <Col sm="12">
            Alcance Efetivo do Aspersor Final:
            <span className="float-right">
              {raio1 > raio2 ? raio1 : raio2} M
            </span>
          </Col>
          <Col sm="12">
            Raio Efetivo da Área irrigada:
            <span className="float-right">
              {arredondaDecimais(
                Number(comprimentoTotalTubos ?? 0) +
                  (raio1 > raio2 ? raio1 : raio2),
              )}{' '}
              M
            </span>
          </Col>
          <Col sm="12">
            Área Circular Irrigada (360º):
            <span className="float-right">
              {areaCircularIrrigada360 || ''} HA
            </span>
          </Col>
          <Col sm="12">
            Número de Posições:
            <span className="float-right">{numeroPosicoes || ''}</span>
          </Col>
          <Col sm="12">
            Área Total Irrigada ({anguloRotacaoIrrigado || ''}º):
            <span className="float-right">{valorArea || ''} HA</span>
          </Col>
        </Row>
      </>
    );
  }

  function renderCaracteristicasTecnicas() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const fichaTecnicaAdutora = fichaTecnica?.FichaTecnicaAdutora ?? null;
    const style1 = {};
    const style2 = { fontSize: 14 };

    if (!fichaTecnica) return '';

    const {
      pressaoTotalBalsa,
      pressaoDescargaBalsa,
      alturaSuccaoBalsa,
      perdaCargaAdutoraBalsa,
      perdaCargaLocalizadaBalsa,
      desnivelEspelhoBalsa,
      alturaManometricaTotalBalsa,
    } = fichaTecnicaAdutora ?? null;

    return (
      <>
        <Row>
          <Col sm="12" style={{ textAlign: 'center' }}>
            <span className="header-topic">Bomba Auxiliar em Balsa:</span>
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            Pressão de Descarga:
            <span className="float-right">
              {` ${pressaoDescargaBalsa || ''}`} MCA
            </span>
          </Col>
          <Col sm="8">
            Desnível do espelho da Água Até a Bomba Principal:
            <span className="float-right">
              {` ${desnivelEspelhoBalsa || ''}`} M
            </span>
          </Col>
          <Col sm="8">
            Altura da Sucção
            <span className="float-right">
              {` ${alturaSuccaoBalsa || ''}`} M
            </span>
          </Col>
          <Col sm="8">
            Perda de Carga na Adutora
            <span className="float-right">
              {` ${perdaCargaAdutoraBalsa || ''}`} MCA
            </span>
          </Col>
          <Col sm="8">
            Perda de Carga Localizada
            <span className="float-right">
              {` ${perdaCargaLocalizadaBalsa || ''}`} MCA
            </span>
          </Col>
          <Col sm="8">
            Altura Manométrica Total
            <span className="float-right">
              {` ${alturaManometricaTotalBalsa || ''}`} MCA
            </span>
          </Col>
        </Row>
      </>
    );
  }

  function renderInformacoesComplementares() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const style1 = {};
    const style2 = { fontSize: 14 };

    if (!fichaTecnica) return '';

    const {
      alturaBaseAspersorAteSolo,
      quantidadeSaidasSimples,
      quantidadeSaidasDuplas,
      aspersorModelo,
    } = fichaTecnica ?? null;

    const quantidadeTotalSaidaAspersores =
      Number(quantidadeSaidasSimples ?? 0) +
      Number(quantidadeSaidasDuplas ?? 0);

    const aspersorFiltrado = aspersores.filter(
      item =>
        String(item.nome).toUpperCase() ===
        String(aspersorModelo).toUpperCase(),
    );

    const tipoPendural = aspersorFiltrado.length
      ? aspersorFiltrado[0].pendural
      : '';

    return (
      <>
        <Row>
          <Col sm="12">
            <span className="header-topic">Informações Complementares:</span>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            Tipo de Pendural
            <span className="float-right">{tipoPendural}</span>
          </Col>
          <Col sm="12">
            Altura da Base do Aspersor até o Solo:
            <span className="float-right">
              {alturaBaseAspersorAteSolo || ''} M
            </span>
          </Col>
          <Col sm="12">
            Quantidade de Saídas Para Aspersores:
            <span className="float-right">
              {quantidadeTotalSaidaAspersores || ''} PÇ
            </span>
          </Col>
          <Col sm="6">Motoredutor:</Col>
          <Col sm="6">
            <span className="float-right">1,50 CV</span>
          </Col>
        </Row>
      </>
    );
  }

  function renderComprimentoTotalTrechos() {
    /*
    const fichaTecnicaTrechos =
      dadosImpressao?.fichaTecnica?.FichaTecnicaAdutoraTubulacao ?? null;
      */
    const fichaTecnicaTrechos =
      dadosImpressao?.fichaTecnica?.FichaTecnicaAdutoraTubulacao.filter(
        (item: any) => item.tubulacaoSuccao === true,
      ) ?? null;
    const style1 = {};
    const style2 = { fontSize: 14 };

    if (!fichaTecnicaTrechos) return '';

    const comprimentoTotalAdutora = fichaTecnicaTrechos.reduce(
      (total: any, item: any) => total + (item?.comprimento || 0),
      0,
    );

    return comprimentoTotalAdutora;
  }

  function renderTabelaTrechos() {
    /*
    const fichaTecnicaTrechos =
      dadosImpressao?.fichaTecnica?.FichaTecnicaAdutoraTubulacao ?? null;
      */
    const fichaTecnicaTrechos =
      dadosImpressao?.fichaTecnica?.FichaTecnicaAdutoraTubulacao.filter(
        (item: any) => item.tubulacaoSuccao === true,
      ) ?? null;
    const style1 = {};
    const style2 = { fontSize: 14 };
    if (!fichaTecnicaTrechos) return '';

    const isMoreThanLimit = fichaTecnicaTrechos.length < 5;
    defaultBreak =
      fichaTecnicaTrechos.length > 5
        ? `div-print-style break-print`
        : 'div-print-style';

    let counter = 0;

    if (isMoreThanLimit) {
      return (
        <>
          {fichaTecnicaTrechos.map((item: any) => {
            return (
              <tr className="table-minimum-padding">
                <td className="table-minimum-padding">{item.ordem}º</td>
                <td className="table-minimum-padding">{item.comprimento}</td>
                <td className="table-minimum-padding">{item.diametro}</td>
                <td className="table-minimum-padding">{item.material}</td>
                <td className="table-minimum-padding">{item.coeficiente}</td>
                <td className="table-minimum-padding">{item.vazao}</td>
                <td className="table-minimum-padding">{item.hfTotal}</td>
                <td className="table-minimum-padding">{item.velocidade}</td>
              </tr>
            );
          })}
          <tr
            className="table-minimum-padding"
            hidden={fichaTecnicaTrechos.length + 1 > 5}
          >
            <td colSpan={8} className="table-minimum-padding">
              <span>-</span>
            </td>
          </tr>
          <tr
            className="table-minimum-padding"
            hidden={fichaTecnicaTrechos.length + 2 > 5}
          >
            <td colSpan={8} className="table-minimum-padding">
              <span>-</span>
            </td>
          </tr>
          <tr
            className="table-minimum-padding"
            hidden={fichaTecnicaTrechos.length + 3 > 5}
          >
            <td colSpan={8} className="table-minimum-padding">
              <span>-</span>
            </td>
          </tr>
          <tr
            className="table-minimum-padding"
            hidden={fichaTecnicaTrechos.length + 4 > 5}
          >
            <td colSpan={8} className="table-minimum-padding">
              <span>-</span>
            </td>
          </tr>
        </>
      );
    }

    return (
      <>
        {fichaTecnicaTrechos.map((item: any) => {
          counter += 1;

          /* if (isMoreThanLimit && counter >= 5)
            return (
              <tr>
                <td colSpan={8}>Alguns registros não estão sendo mostrados</td>
              </tr>
            ); */
          return (
            <tr className="table-minimum-padding">
              <td className="table-minimum-padding">{item.ordem}º</td>
              <td className="table-minimum-padding">{item.comprimento}</td>
              <td className="table-minimum-padding">{item.diametro}</td>
              <td className="table-minimum-padding">{item.material}</td>
              <td className="table-minimum-padding">{item.coeficiente}</td>
              <td className="table-minimum-padding">{item.vazao}</td>
              <td className="table-minimum-padding">{item.hfTotal}</td>
              <td className="table-minimum-padding">{item.velocidade}</td>
            </tr>
          );
        })}
      </>
    );
  }

  function renderTubulacaoAdutora() {
    const style1 = {};
    const style2 = { fontSize: 14 };
    return (
      <>
        <Row>
          <Col sm="12" style={{ textAlign: 'center' }}>
            <span className="header-topic">Tubulação Adutora:</span>
          </Col>
        </Row>
        <Col sm="12">
          <strong>Tubulação Adutora:</strong>
          <span style={{ marginLeft: '20px' }}>
            {renderComprimentoTotalTrechos()} M
          </span>
        </Col>
        <Row>
          <Col sm="12">
            <Table
              bordered
              className="table-minimum-padding"
              style={{ width: '99%' }}
            >
              <tr className="table-minimum-padding">
                <th className="table-minimum-padding">Trecho</th>
                <th className="table-minimum-padding">Comprimento</th>
                <th className="table-minimum-padding">Diam. (MM)</th>
                <th className="table-minimum-padding">Material</th>
                <th className="table-minimum-padding">Coef.</th>
                <th className="table-minimum-padding">Vazão M³/H</th>
                <th className="table-minimum-padding">HF total (MCA)</th>
                <th className="table-minimum-padding">Veloc. (M/S)</th>
              </tr>
              {renderTabelaTrechos()}
            </Table>
          </Col>
        </Row>
      </>
    );
  }

  function renderRodape() {
    const cliente = dadosImpressao?.pessoa ?? null;
    const usuario = dadosImpressao?.usuario ?? null;
    const style1 = {};

    const ultimoLog = dadosImpressao?.ultimoLog ?? null;

    const ultimoLogMensagem = ultimoLog
      ? `Última alteração em ${new Intl.DateTimeFormat('pt-BR').format(
          new Date(ultimoLog?.dataGeracao),
        )} por ${
          ultimoLog?.usuario?.nome ? ultimoLog?.usuario?.nome : 'Sistema'
        }`
      : ``;

    return (
      <>
        <Row style={{ marginBottom: '30px' }}>
          <Col sm="4">
            <Col className="text-center div-assinatura">
              <h3>Responsável pelo Projeto:</h3>
              <span>{usuario?.nome ?? ''}</span>
            </Col>
          </Col>
          <Col sm="4">
            <Col className="text-center div-assinatura">
              <h3>Representante /Ger. de Negócios Irrigação:</h3>
              <span>{dadosImpressao?.representante ?? ''}</span>
            </Col>
          </Col>
          <Col sm="4">
            <Col className="text-center div-assinatura">
              <h3>Cliente:</h3>
              <span>{cliente?.razaoSocial ?? ''}</span>
            </Col>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px', padding: '10px' }}>
          <Col sm="12">{ultimoLogMensagem}</Col>
        </Row>
      </>
    );
  }

  function renderTubulacaoSuccao() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const style1 = {};
    const style2 = { fontSize: 14 };

    if (!fichaTecnica) return '';

    const { tipoSuccao, diametroTubulacaoBalsa } =
      (fichaTecnica?.FichaTecnicaTubulacao.length &&
        fichaTecnica?.FichaTecnicaTubulacao?.[0]) ??
      null;

    const { modeloSuccao, comprimentoTotalSuccao } = fichaTecnica ?? null;

    return (
      <>
        <Row>
          <Col sm="12">
            <span className="header-topic">Tubulação de Sucção:</span>
            <span className="float-right">{modeloSuccao ?? ''}</span>
          </Col>
        </Row>
        <Row>
          <Col sm="4">Diâmetro:</Col>
          <Col sm="4">
            <span className="float-right">{tipoSuccao || ''}</span>
          </Col>
          <Col sm="4">
            <span className="float-right">
              {` ${diametroTubulacaoBalsa || ''}`} MM
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm="8">Comprimento:</Col>
          <Col sm="4">
            <span className="float-right">
              {arredondaDecimais(comprimentoTotalSuccao) || '0'} M
            </span>
          </Col>
        </Row>
      </>
    );
  }

  function renderAspersor() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;

    if (!fichaTecnica) return '';

    const {
      aspersor1SiglaBocal,
      aspersor2SiglaBocal,
      aspersor1Quantidade,
      aspersor2Quantidade,
      aspersor1Bocal,
      aspersor1Vazao,
      aspersor2Bocal,
      aspersor2Saida,
      aspersor2Vazao,
      aspersor1Raio,
      aspersor2Raio,
    } = fichaTecnica ?? null;

    if (!aspersor1SiglaBocal && !aspersor2SiglaBocal) return '';

    const qTotal = Number(aspersor1Vazao ?? 0) + Number(aspersor2Vazao ?? 0);

    const laminaAF1 = aspersor1Quantidade ? `AF1: ${aspersor1Quantidade}` : ``;
    const laminaAF2 = aspersor2Quantidade ? `AF2: ${aspersor2Quantidade}` : ``;

    const style1 = {};
    const style2 = { padding: '0px', color: '#000' };

    return (
      <>
        <Row>
          <Col sm="12">
            <Table borderless style={{ width: '99%' }}>
              <tr>
                <td style={style2}>
                  <strong>Aspersor Final 1</strong>
                </td>
                <td style={style2}>{aspersor1SiglaBocal}</td>
                <td style={style2}>Bocal: {aspersor1Bocal || ''}</td>
                <td style={style2}>Raio: {aspersor1Raio || ''} M</td>
              </tr>

              {aspersor2Saida > 0 && (
                <tr>
                  <td style={style2}>
                    <strong>Aspersor Final 2</strong>
                  </td>
                  <td style={style2}>{aspersor2SiglaBocal}</td>
                  <td style={style2}>Bocal: {aspersor2Bocal || ''}</td>
                  <td style={style2}>Raio: {aspersor2Raio || ''} M</td>
                </tr>
              )}
              <tr>
                <td style={style2}>Lâmina Estimada (mm/21):</td>
                <td style={style2}>{laminaAF1}</td>
                <td style={style2}>{laminaAF2}</td>
                <td style={style2}>Q= {qTotal} M³/H</td>
              </tr>
            </Table>
          </Col>
        </Row>
      </>
    );
  }

  function renderBombaCentrifuga() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const ftBombeamento = fichaTecnica?.FichaTecnicaBombeamento.filter(
      (item: any) => item.isBalsa === true,
    );

    if (!fichaTecnica) return '';

    const {
      quantidadeModulos,
      bomba,
      quantidadeBomba,
      marcaBomba,
      modeloBomba,
      pressaoPrevista,
      vazaoPrevista,
      pressaoAlternativa,
      vazaoAlternativa,
      potenciaAbsorvidaEixoBomba,
      rendimento,
      motor,
      numeroEstagios,
      diametroEstimadoRotores,
    } = (ftBombeamento.length && ftBombeamento[0]) ?? null;

    // =SE(P44="Elétrico";J54*X44*0,736*J45;J54*X44*J45*0,2/0,853)

    // J54 = potenciaAbsorvidaEixoBomba
    // X44 = quantidadeBomba
    // J45 = quantidadeModulos

    const consumoMedio =
      motor === 'Elétrico'
        ? Number(potenciaAbsorvidaEixoBomba ?? 0) *
          Number(quantidadeBomba ?? 0) *
          0.736 *
          Number(quantidadeModulos ?? 0)
        : (Number(potenciaAbsorvidaEixoBomba ?? 0) *
            Number(quantidadeBomba ?? 0) *
            Number(quantidadeModulos ?? 0) *
            0.2) /
          0.853;

    return (
      <>
        <Row>
          <Col sm="12">
            <span className="header-topic">Bomba Centrífuga:</span>
            <span className="float-right">{bomba || ''}</span>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            Quantidade:
            <span className="float-right">{quantidadeBomba || ''}</span>
          </Col>
          <Col sm="12">
            Quantidade de Módulos de Conjunto MotoBomba:
            <span className="float-right">{quantidadeModulos || ''}</span>
          </Col>
          <Col sm="12">
            Marca:
            <span className="float-right">{marcaBomba || ''}</span>
          </Col>
          <Col sm="12">
            Modelo:
            <span className="float-right">{modeloBomba || ''}</span>
          </Col>
          <Col sm="12">
            Nº Estágios:
            <span className="float-right">{numeroEstagios || ''}</span>
          </Col>
          <Col sm="12">
            Diâmetro Estimado dos Rotores:
            <span className="float-right">
              {diametroEstimadoRotores || ''}MM
            </span>
          </Col>
          <Col sm="12">
            Vazão Prevista:
            <span className="float-right">
              {vazaoAlternativa !== 0 ? vazaoAlternativa : vazaoPrevista || ''}{' '}
              M³/H
            </span>
          </Col>
          <Col sm="12">
            Pressão Prevista:
            <span className="float-right">
              {pressaoAlternativa !== 0
                ? pressaoAlternativa
                : pressaoPrevista || ''}{' '}
              MCA
            </span>
          </Col>
          <Col sm="12">
            Rendimento:
            <span className="float-right">{rendimento || ''} %</span>
          </Col>
          <Col sm="12">
            Rotação:
            <span className="float-right">1.750RPM</span>
          </Col>
          <Col sm="12">
            Potência Absorvida no Eixo da Bomba:
            <span className="float-right">
              {potenciaAbsorvidaEixoBomba || ''} CV
            </span>
          </Col>
          <Col sm="12">
            Consumo Med. de Energia Eixo por Hora:
            <span className="float-right">
              {arredondaDecimais(consumoMedio, 2)} KWH
            </span>
          </Col>
        </Row>
      </>
    );
  }

  function renderMotor() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const ftBombeamento = fichaTecnica?.FichaTecnicaBombeamento.filter(
      (item: any) => item.isBalsa === true,
    );
    const style1 = {};
    const style2 = { fontSize: 14 };

    const respostas = dadosImpressao?.respostas ?? null;

    if (!fichaTecnica || !respostas) return '';

    const { tensao, frequencia, quadroComandoBalsa, redeEletricaPivo } =
      respostas ?? null;

    const { motor, potenciaCasaMaquina, quantidadeBomba } =
      (ftBombeamento.length && ftBombeamento[0]) ?? null;

    return (
      <>
        <Row>
          <Col sm="12">
            <span className="header-topic">Motor:</span>
            <span className="float-right">{motor}</span>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            Quantidade:
            <span className="float-right">{quantidadeBomba || ''}</span>
          </Col>
          <Col sm="12">
            Quadro de Comando:
            <span className="float-right">{quadroComandoBalsa || ''}</span>
          </Col>
          <Col sm="12">
            Modelo do Motor:
            <span className="float-right">
              {motor === 'Elétrico' ? `IP55` : ``}
            </span>
          </Col>
          <Col sm="12">
            Potência Nominal:
            <span className="float-right">
              {` ${potenciaCasaMaquina || ''}`} CV
            </span>
          </Col>
          <Col sm="12">
            <strong>
              Nº de Fases:
              <span className="float-right">{redeEletricaPivo || ''}</span>
            </strong>
          </Col>
          <Col sm="12">
            <strong>
              Nº de Pólos:
              <span className="float-right">4 pólos</span>
            </strong>
          </Col>
          <Col sm="12">
            Rotação:
            <span className="float-right">1.750 RPM</span>
          </Col>
          <Col sm="12">
            Tensão:
            <span className="float-right">{` ${tensao || ''}`} V</span>
          </Col>
          <Col sm="12">
            Frequência:
            <span className="float-right">{frequencia || ''} HZ</span>
          </Col>
        </Row>
      </>
    );
  }

  function renderFonteAlimentacao() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const ftBombeamento = fichaTecnica?.FichaTecnicaBombeamento.filter(
      (item: any) => item.isBalsa === true,
    );
    const style1 = {};
    const style2 = { fontSize: 14 };

    if (!fichaTecnica) return '';

    const { tensaoTrifasica } = fichaTecnica ?? null;

    const { potenciaCaboTrafoTransformador } =
      (ftBombeamento.length && ftBombeamento[0]) ?? null;

    return (
      <>
        <Row>
          <Col sm="12">
            <span className="header-topic">Fonte de Alimentação:</span>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <strong>Transformador da Unidade de Bombeamento e Pivô</strong>
          </Col>
          <Col sm="12">
            Potência:
            <span className="float-right">
              {potenciaCaboTrafoTransformador || ''} KVA
            </span>
          </Col>
          <Col sm="12">
            Tensão:
            <span className="float-right">{tensaoTrifasica || ''} V</span>
          </Col>
        </Row>
      </>
    );
  }

  function renderFonteEnergiaPivo() {
    const fichaTecnica = dadosImpressao?.fichaTecnica ?? null;
    const ftBombeamento = fichaTecnica?.FichaTecnicaBombeamento.filter(
      (item: any) => item.isBalsa === true,
    );
    const style1 = {};
    // const style2 = { fontSize: 15 };

    if (!fichaTecnica) return '';

    const { potenciaATTSElevador, modeloPivo } = fichaTecnica ?? null;

    const { tensaoNominal } =
      (ftBombeamento.length && ftBombeamento[0]) ?? null;

    const valorAtts =
      Number(tensaoNominal ?? 0) > 510 || modeloPivo === 'Supremo' ? '510' : '';

    return (
      <>
        <Row>
          <Col sm="12">
            <span className="header-topic">Fonte de Energia do Pivô:</span>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <strong>Auto Trafo</strong>
            <Row>
              <Col sm="12">
                Potência:
                <span className="float-right">
                  {potenciaATTSElevador || ''} KVA
                </span>
              </Col>
              <Col sm="12">
                1º ATTS:
                <span className="float-right">{tensaoNominal || ''} V</span>
              </Col>
              <Col sm="12" hidden={!valorAtts}>
                2º ATTS:
                <span className="float-right">{valorAtts || ''} V</span>
              </Col>
            </Row>
          </Col>
          <Col sm="6" hidden>
            <strong>Grupo Gerador</strong>
            <Row>
              <Col sm="12" style={styleInProgress}>
                Potência Consumo:
                <span className="float-right"> KVA</span>
              </Col>
              <Col sm="12" style={styleInProgress}>
                Potência Nominal:
                <span className="float-right"> KVA</span>
              </Col>
              <Col sm="12" style={styleInProgress}>
                Tensão:
                <span className="float-right"> V</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      {mostrarDados && (
        <>
          <Row className="show-on-print">
            <Col sm="12" className="text-center">
              <img src={LogoImg} alt="Logo" className="logo-print" />
            </Col>
          </Row>

          <div style={defaultStyle}>{renderCabecalho()}</div>

          <div className="div-print-style" style={defaultStyle}>
            <Row>
              <Col sm="12">{renderCaracteristicasTecnicas()}</Col>
            </Row>
          </div>
          <div
            className="div-print-style"
            style={defaultStyle}
            hidden={!mostrarAdutora}
          >
            <Row>
              <Col sm="12">{renderTubulacaoAdutora()}</Col>
            </Row>
          </div>
          <div
            style={defaultStyle}
            className={defaultBreak}
            hidden={!mostrarBombeamento}
          >
            <Row>
              <Col sm="6">{renderBombaCentrifuga()}</Col>
              <Col sm="6" hidden={!mostrarBombeamento}>
                {renderMotor()}
              </Col>
            </Row>
          </div>
          <div className="div-print-style" style={defaultStyle}>
            <Row>
              <Col sm="12">{renderRodape()}</Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  CardBody,
  Form,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';

import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import api from 'services/api';

import { Mentions } from 'antd';
import Select from 'react-select';
import RegraItemColuna from 'models/RegraItemColuna';
import ProdutoModel from 'models/Produto';
import AgrupadorModel from '../../../models/ConfiguradorAgrupador';
import AgrupadorProdutoModel from '../../../models/AgrupadorProduto';
import AgrupadorTagModel from '../../../models/AgrupadorTag';
import TagModel from '../../../models/Tag';

interface iRegistros extends AgrupadorModel {
  AgrupadorProduto?: AgrupadorProdutoModel[];
  agrupadorTag?: AgrupadorTagModel[];
}

const { Option } = Mentions;
const ConfiguradorAgrupadores: React.FC = () => {
  const [agrupadores, setAgrupadores] = useState([] as iRegistros[]);
  const [agrupadoresProdutos, setAgrupadoresProdutos] = useState(
    [] as AgrupadorProdutoModel[],
  );
  // eslint-disable-next-line prettier/prettier
  const [agrupadorEditing, setAgrupadorEditing] = useState({} as iRegistros);
  const [agrupadorProdutoEditing, setAgrupadorProdutoEditing] = useState(
    {} as AgrupadorProdutoModel,
  );

  const [formModalState, setFormModalState] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [produtoFiltrar, setProdutoFiltrar] = useState(0);
  const [filterChanged, setFilterChanged] = useState(true);
  const [produtos, setProdutos] = useState([] as ProdutoModel[]);
  const [tagsRegistros, setTagsRegistros] = useState([] as any[]);
  const [registroEditing, setRegistroEditing] = useState({} as any);
  const [tags, setTags] = useState([] as TagModel[]);
  const [tagAgrupadorRegistros, setTagAgrupadorRegistros] = useState(
    [] as any[],
  );
  const [tipoIdFiltrar, setTipoIdFiltrar] = useState(0);
  const [exibeRespostas, setExibeRespostas] = useState(false);

  const [regraItemColunas, setRegraItemColunas] = useState<RegraItemColuna[]>(
    [],
  );

  async function carregaRegrasItemColuna() {
    const response = await api.get(`/regra-item-coluna`);

    setRegraItemColunas(response.data);
  }

  async function listaAgrupadores() {
    const response = await api.get('/regra-agrupador');
    setAgrupadores(response.data);
  }

  async function listaAgrupadoresFiltrados() {
    if (tipoIdFiltrar > 0) {
      const response = await api.get(
        `/regra-agrupador/lista-por-produto/${tipoIdFiltrar}`,
      );
      setAgrupadores(response.data);
    } else {
      const response = await api.get('/regra-agrupador');
      setAgrupadores(response.data);
    }
  }

  useEffect(() => {
    listaAgrupadores();
  }, []);

  async function listaProdutos() {
    const response = await api.get('/produto');
    setProdutos(response.data);
  }

  async function listaTags() {
    const response = await api.get('/tag');
    setTags(response.data);
  }

  async function listaTagAgrupadores() {
    const response = await api.get('/tag-agrupador');
    setTagAgrupadorRegistros(response.data);
  }

  useEffect(() => {
    sessionStorage.setItem('TelaConfig', '');
  }, []);

  useEffect(() => {
    carregaRegrasItemColuna();
  }, []);

  useEffect(() => {
    listaProdutos();
  }, []);

  useEffect(() => {
    listaTags();
  }, []);

  useEffect(() => {
    listaTagAgrupadores();
  }, []);

  useEffect(() => {
    if (tipoIdFiltrar) {
      listaAgrupadoresFiltrados();
    }
  }, [tipoIdFiltrar]);

  const prefixColumns = '#';
  const prefixCount = 'CONT[';
  const [prefix, setPrefix] = useState(prefixColumns);

  const listaSugestoesQuestao = [] as string[];
  const listaSugestoesCont = [] as string[];

  regraItemColunas.forEach((coluna: any) => {
    const isTipoColuna = coluna.tipo === 'coluna';
    const isTipoContador = coluna.tipo === 'contador';

    if (isTipoColuna) {
      listaSugestoesQuestao.push(`${coluna.nome}#`);
      return;
    }

    if (isTipoContador) {
      listaSugestoesCont.push(`${coluna.nome}`);
    }
  });

  const listaSugestoes = {
    [prefixColumns]: listaSugestoesQuestao,
    [prefixCount]: listaSugestoesCont,
  } as any;

  function renderListaSugestoes() {
    return (listaSugestoes[prefix] || []).map((value: string) => (
      <Option key={value} value={value}>
        {value}
      </Option>
    ));
  }

  function onSearch(_: any, newPrefix: string) {
    setPrefix(newPrefix);
  }

  async function handleDelete(agrupadorId: number) {
    Swal.fire({
      title: `Deseja excluir o agrupador?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      confirmButtonColor: '#d33',
      cancelButtonText: `Não`,
    }).then(async result => {
      if (result.isConfirmed) {
        await api.delete(`/regra-agrupador/${agrupadorId}`, {});
        Swal.close();
        toast.success('Registro excluído');
        if (tipoIdFiltrar > 0) {
          listaAgrupadoresFiltrados();
        } else {
          listaAgrupadores();
        }
        listaTags();
        listaTagAgrupadores();
      }
    });
  }
  async function handleInsert() {
    setAgrupadorEditing({} as AgrupadorModel);
    setRegistroEditing({});
    setAgrupadorProdutoEditing({} as AgrupadorProdutoModel);
    setFormModalState(!formModalState);
  }

  function handleUpdate(
    agrupador: iRegistros,
    produtoAgrupador: number,
    registro: any,
  ) {
    setRegistroEditing({
      ...agrupador,
      tags: agrupador.agrupadorTag?.map(item => {
        return {
          label: item.tag.descricao,
          value: item.tag.id,
        };
      }),
    });

    setAgrupadorEditing(agrupador);
    setAgrupadorProdutoEditing({
      ...agrupadorProdutoEditing,
      id: produtoAgrupador,
    });

    setFormModalState(!formModalState);
  }

  /*
  async function incluirProduto() {
    // eslint-disable-next-line no-useless-catch
    try {
      if (agrupadorProdutoEditing.id) {
        await api.put(`/agrupador-produto/${agrupadorProdutoEditing.id}`, {
          id: agrupadorProdutoEditing.id,
          regraAgrupadorId: agrupadorEditing.id,
          produtoId: agrupadorProdutoEditing.produtoId,
        });
      } else {
        await api.post(`/agrupador-produto/`, {
          regraAgrupadorId: agrupadorEditing.id,
          produtoId: agrupadorProdutoEditing.produtoId,
        });
      }
    } catch (error) {
      setIsSaving(false);
      throw error;
    }
  }
    */
  /*
  async function incluirTags() {
    // eslint-disable-next-line no-useless-catch
    try {
      if (registroEditing.id) {
        await api.put(`/tag-agrupador/${registroEditing.id}`, {
          id: registroEditing.id,
          regraAgrupadorId: agrupadorEditing.id,
          tags: tagAgrupadorRegistros
            .filter(item => item.regraAgrupadorId === agrupadorEditing.id)
            // eslint-disable-next-line array-callback-return, consistent-return
            ?.map((item: any) => {
              const prodReturn = tags.find(pr => pr.id === item.tagId);

              if (prodReturn) return { tagId: prodReturn.id };
            }),
        });
      } else {
        await api.post(`/tag-agrupador/`, {
          regraAgrupadorId: agrupadorEditing.id,
          tags: registroEditing.tags,
        });
      }
    } catch (error) {
      setIsSaving(false);
      throw error;
    }
  }
    */

  async function handleSaveagrupador() {
    // eslint-disable-next-line prettier/prettier
    const { nome } = agrupadorEditing;
    if (!nome) {
      toast.error(
        'Todos os campos marcados com * são de preenchimento obrigatório!',
      );
      return;
    }

    setIsSaving(true);

    // await incluirProduto();
    // await incluirTags();

    try {
      if (agrupadorEditing.id) {
        await api.put(`/regra-agrupador/${agrupadorEditing.id}`, {
          id: agrupadorEditing.id,
          nome: agrupadorEditing.nome,
          descricao: agrupadorEditing.descricao,
          bloquear: agrupadorEditing.bloquear ?? false,
          alertar: agrupadorEditing.alertar ?? false,
          naoConsiderarPerdaCarga:
            agrupadorEditing.naoConsiderarPerdaCarga ?? false,
          regraValidacao: agrupadorEditing.regraValidacao ?? '',
          fatorK:
            // eslint-disable-next-line no-self-compare
            agrupadorEditing.fatorK ?? 0 > 0 ? agrupadorEditing.fatorK : null,
          produtos: agrupadorEditing.AgrupadorProduto,
          produtoId:
            agrupadorProdutoEditing.produtoId ??
            agrupadorEditing.AgrupadorProduto?.[0]?.produto.id,
          tags: registroEditing.tags,
          ocultarPvcPorContaCliente: agrupadorEditing.ocultarPvcPorContaCliente,
        });
      } else {
        await api.post(`/regra-agrupador/`, {
          nome: agrupadorEditing.nome,
          descricao: agrupadorEditing.descricao,
          bloquear: agrupadorEditing.bloquear ?? false,
          alertar: agrupadorEditing.alertar ?? false,
          naoConsiderarPerdaCarga:
            agrupadorEditing.naoConsiderarPerdaCarga ?? false,
          regraValidacao: agrupadorEditing.regraValidacao ?? '',
          fatorK:
            // eslint-disable-next-line no-self-compare
            agrupadorEditing.fatorK ?? 0 > 0 ? agrupadorEditing.fatorK : null,
          produtoId: agrupadorProdutoEditing.produtoId,
          tags: registroEditing.tags,
          ocultarPvcPorContaCliente: agrupadorEditing.ocultarPvcPorContaCliente, // Include this line
        });
      }

      toast.success('Registro concluído');
      setFormModalState(false);
      setAgrupadorEditing({} as AgrupadorModel);
      if (tipoIdFiltrar > 0) {
        listaAgrupadoresFiltrados();
      } else {
        listaAgrupadores();
      }
      listaTags();
      listaTagAgrupadores();
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <>
      <Header showCards={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <FormGroup className="mb-4">
              <InputGroup>
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  type="button"
                  onClick={handleInsert}
                >
                  <FaPlusCircle />
                </Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {/* Table */}
        <Row>
          <div className="col">
            {/* Modal edição */}
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={formModalState}
              toggle={() => setFormModalState(!formModalState)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>{agrupadorEditing.nome}</small>
                    </div>
                    <Form role="form">
                      <Row>
                        <Col sm="12">
                          <FormGroup className="mb-3">
                            <small>Agrupador </small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Nome"
                                value={agrupadorEditing.nome}
                                type="text"
                                onChange={text =>
                                  setAgrupadorEditing({
                                    ...agrupadorEditing,
                                    nome: text.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <FormGroup className="mb-3">
                            <small>Descrição </small>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Descrição"
                                value={agrupadorEditing.descricao}
                                type="text"
                                onChange={text =>
                                  setAgrupadorEditing({
                                    ...agrupadorEditing,
                                    descricao: text.target.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <FormGroup className="mb-3">
                            <span>Produto</span>
                            <InputGroup className="input-group-alternative input-group">
                              <Input
                                placeholder="Produto"
                                type="select"
                                value={
                                  agrupadorEditing.AgrupadorProduto?.[0]
                                    ?.produto.id
                                }
                                onChange={event => {
                                  setFilterChanged(true);
                                  setAgrupadorProdutoEditing({
                                    ...agrupadorProdutoEditing,
                                    produtoId: Number(event.target.value),
                                  });
                                }}
                              >
                                <option value="">Selecione o Produto...</option>
                                {produtos.map(produto => (
                                  <option value={produto.id}>
                                    {produto.nome}
                                  </option>
                                ))}
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <FormGroup className="col-sm-12">
                          <small>Aplicação:</small>
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Selecione..."
                            isMulti
                            options={tags.map((item: TagModel) => {
                              return { label: item.descricao, value: item.id };
                            })}
                            value={registroEditing.tags}
                            onChange={(e: any) => {
                              setRegistroEditing({
                                ...registroEditing,
                                tags: [...e],
                              });
                            }}
                          />
                        </FormGroup>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <span>
                              Fator K{' '}
                              <small>
                                <i>(zero quando não-aplicável)</i>
                              </small>
                            </span>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Fator K - zero quando não-aplicável"
                                value={agrupadorEditing.fatorK ?? ''}
                                type="number"
                                onChange={text =>
                                  setAgrupadorEditing({
                                    ...agrupadorEditing,
                                    fatorK: Number(text.target.value),
                                  })
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <span>
                              Ignorar na Perda de Carga?<i>*</i>
                            </span>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Ignorar na Perda de Carga?"
                                type="select"
                                value={
                                  agrupadorEditing.naoConsiderarPerdaCarga
                                    ? 'S'
                                    : 'N'
                                }
                                onChange={text =>
                                  setAgrupadorEditing({
                                    ...agrupadorEditing,
                                    naoConsiderarPerdaCarga: Boolean(
                                      text.target.value === 'S' ?? false,
                                    ),
                                  })
                                }
                              >
                                <option value="S">Sim</option>
                                <option value="N" selected>
                                  Não
                                </option>
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <span>
                              Alertar?<i>**</i>
                            </span>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Alertar?"
                                type="select"
                                value={agrupadorEditing.alertar ? 'S' : 'N'}
                                onChange={text =>
                                  setAgrupadorEditing({
                                    ...agrupadorEditing,
                                    alertar: Boolean(
                                      text.target.value === 'S' ?? false,
                                    ),
                                  })
                                }
                              >
                                <option value="S">Sim</option>
                                <option value="N" selected>
                                  Não
                                </option>
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <span>
                              Bloquear?<i>**</i>
                            </span>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Bloquear?"
                                type="select"
                                value={agrupadorEditing.bloquear ? 'S' : 'N'}
                                onChange={text =>
                                  setAgrupadorEditing({
                                    ...agrupadorEditing,
                                    bloquear: Boolean(
                                      text.target.value === 'S' ?? false,
                                    ),
                                  })
                                }
                              >
                                <option value="S">Sim</option>
                                <option value="N" selected>
                                  Não
                                </option>
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <FormGroup className="mb-3">
                            <span>Ocultar PVC por Conta do Cliente?</span>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-tag" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Bloquear?"
                                type="select"
                                value={
                                  agrupadorEditing.ocultarPvcPorContaCliente
                                    ? 'S'
                                    : 'N'
                                }
                                onChange={text =>
                                  setAgrupadorEditing({
                                    ...agrupadorEditing,
                                    ocultarPvcPorContaCliente: Boolean(
                                      text.target.value === 'S' ?? false,
                                    ),
                                  })
                                }
                              >
                                <option value="S">Sim</option>
                                <option value="N" selected>
                                  Não
                                </option>
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <FormGroup className="mb-3">
                            <small>Validar apenas se:</small>
                            <Mentions
                              style={{ width: '100%', height: '100px' }}
                              placeholder="Validar apenas se:"
                              prefix={[prefixColumns, prefixCount]}
                              onSearch={onSearch}
                              value={agrupadorEditing.regraValidacao}
                              onChange={text =>
                                setAgrupadorEditing({
                                  ...agrupadorEditing,
                                  regraValidacao: text,
                                })
                              }
                            >
                              {renderListaSugestoes()}
                            </Mentions>
                          </FormGroup>
                        </Col>
                      </Row>

                      <small>
                        <i>
                          * Não irá considerar itens gerados para esse agrupador
                          na perda de carga
                        </i>
                      </small>
                      <br />
                      <small>
                        <i>
                          ** Caso Validar ou Alertar esteja marcado como Sim, a
                          regra estará ativa.
                        </i>
                      </small>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          disabled={isSaving}
                          onClick={handleSaveagrupador}
                        >
                          Salvar
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

            <Card
              className="shadow"
              style={{ maxHeight: 860, maxWidth: '100%', overflowX: 'scroll' }}
            >
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista de agrupadores</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <small>
                      <strong>Filtro por produto:</strong>{' '}
                    </small>
                    <Input
                      placeholder="Filtro por produto:"
                      type="select"
                      value={tipoIdFiltrar}
                      onChange={text => {
                        setTipoIdFiltrar(Number(text.target.value));
                        setExibeRespostas(true);
                      }}
                    >
                      <option value="">Selecione...</option>
                      <option value={-1}>Todos os produtos</option>
                      {produtos.map(item => {
                        return <option value={item.id}>{item.nome}</option>;
                      })}
                    </Input>
                  </Col>
                </Row>
                {exibeRespostas ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Nome</th>
                        <th>Produto</th>
                        <th>Descrição</th>
                        <th>Fator K</th>
                        <th>Aplicação</th>
                        <th>Alertar?</th>
                        <th>Bloquear?</th>
                        <th title="Não considerar na perda de carga">
                          Ignorar Perda de Carga?
                        </th>
                        <th title="Ocultar PVC por Conta do Cliente">
                          Ocultar PVC por Conta do Cliente
                        </th>
                        <th style={{ width: '300px' }}>Validar apenas se</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agrupadores.map(agrupador => {
                        let descricaoProduto = '';
                        const produto = agrupador.AgrupadorProduto?.map(
                          (_agrupador: any) => {
                            return _agrupador.produto.nome;
                          },
                        );
                        const produtoAgrupador =
                          agrupador.AgrupadorProduto?.map((_agrupador: any) => {
                            return _agrupador.produto.id;
                          });
                        if (produto) {
                          descricaoProduto = String(produto);
                        }
                        const descricaoTag = agrupador.agrupadorTag
                          ? agrupador.agrupadorTag
                              ?.map((_agrupador: any) => {
                                return _agrupador.tag.descricao;
                              })
                              ?.join(',')
                          : '';
                        return (
                          <tr key={agrupador.id}>
                            <td className="py-2">{agrupador.nome}</td>
                            <td className="py-2">{descricaoProduto}</td>
                            <td
                              className="py-2"
                              style={{
                                whiteSpace: 'pre',
                                wordWrap: 'break-word',
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  wordBreak: 'keep-all',
                                }}
                              >
                                {agrupador.descricao}
                              </div>
                            </td>
                            <td className="py-2">{agrupador.fatorK ?? null}</td>
                            <td
                              className="py-2"
                              style={{
                                whiteSpace: 'pre',
                                wordWrap: 'break-word',
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  wordBreak: 'keep-all',
                                  fontSize: '11px',
                                }}
                              >
                                {descricaoTag}
                              </div>
                            </td>
                            <td className="py-2">
                              {agrupador.alertar ? 'Sim' : 'Não'}
                            </td>
                            <td className="py-2">
                              {agrupador.bloquear ? 'Sim' : 'Não'}
                            </td>
                            <td className="py-2">
                              {agrupador.naoConsiderarPerdaCarga
                                ? 'Sim'
                                : 'Não'}
                            </td>
                            <td className="py-2">
                              {agrupador.ocultarPvcPorContaCliente
                                ? 'Sim'
                                : 'Não'}
                            </td>
                            <td
                              className="py-2"
                              style={{
                                whiteSpace: 'pre',
                                wordWrap: 'break-word',
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  wordBreak: 'keep-all',
                                }}
                              >
                                {agrupador.regraValidacao}
                              </div>
                            </td>
                            <td className="py-2">
                              <div className="float-right">
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="warning"
                                  type="button"
                                  onClick={() => {
                                    handleUpdate(
                                      agrupador,
                                      Number(produtoAgrupador),
                                      tagAgrupadorRegistros,
                                    );
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                                <Button
                                  size="sm"
                                  className="btn-icon"
                                  color="danger"
                                  type="button"
                                  onClick={() => {
                                    handleDelete(agrupador.id);
                                  }}
                                >
                                  <FaTimesCircle />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : null}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfiguradorAgrupadores;

import FichaTecnica from 'models/FichaTecnica';
import { Card, CardBody, Row } from 'reactstrap';
import Campo from 'views/pages/OrcamentoResultado/components/atoms/Campo';
import {
  ColQuestaoTitulo,
  LabelQuestaoTitulo,
} from 'views/pages/OrcamentoResultado/styles';

interface Props {
  fichaTecnica: FichaTecnica;
}

function DadosAdutoraBalsa({ fichaTecnica }: Props): JSX.Element {
  const fichaTecnicaAdutora = fichaTecnica.FichaTecnicaAdutora;
  const fichaTecnicaTubulacao = fichaTecnica.FichaTecnicaTubulacao;
  const {
    pressaoDescargaBalsa,
    alturaSuccaoBalsa,
    perdaCargaAdutoraBalsa,
    perdaCargaLocalizadaBalsa,
    desnivelEspelhoBalsa,
    alturaManometricaTotalBalsa,
  } = fichaTecnicaAdutora || {};

  const { diametroTubulacaoBalsa } = fichaTecnicaTubulacao?.[0] || {};

  return (
    <Card className="shadow">
      <ColQuestaoTitulo>
        <LabelQuestaoTitulo>Balsa</LabelQuestaoTitulo>
      </ColQuestaoTitulo>

      <CardBody className="p-3">
        <Row>
          <Campo
            sm="12"
            label="Pressão de Descarga"
            valor={pressaoDescargaBalsa}
            number
            unidade="mca"
          />

          <Campo
            sm="12"
            label="Desnível do espelho da Água Até a Bomba Principal"
            valor={desnivelEspelhoBalsa}
            number
            unidade="m"
          />

          <Campo
            sm="12"
            label="Altura de Sucção"
            valor={alturaSuccaoBalsa}
            number
            unidade="m"
          />

          <Campo
            sm="12"
            label="Perda de Carga na Adutora"
            valor={perdaCargaAdutoraBalsa}
            number
            unidade="mca"
          />

          <Campo
            sm="12"
            label="Perda de Carga Localizada"
            valor={perdaCargaLocalizadaBalsa}
            number
            unidade="mca"
          />

          <Campo
            sm="12"
            label="Altura Manométrica Total"
            valor={alturaManometricaTotalBalsa}
            number
            unidade="mca"
          />

          <Campo
            sm="12"
            label="Diâmetro da Tubulação de Sucção"
            valor={diametroTubulacaoBalsa}
            number
            unidade="mm"
          />
        </Row>
      </CardBody>
    </Card>
  );
}

export default DadosAdutoraBalsa;
